<div class="row animated fadeIn fast">
    <div *ngIf="!disabledFeature" class="col-12">
        <div class="card">
            <div class="card-body">
                <form 
                    #formBusqueda="ngForm"
                >

                    <div class="input-group">
                        <input 
                            type="text" 
                            [(ngModel)]="terminoBusqueda"
                            name="terminoBusqueda"
                            class="form-control" 
                            placeholder="Escribe folio para buscar..."
                            autocomplete="off"
                        >
                        <span class="input-group-btn">
                            <button 
                                class="btn btn-info" 
                                type="submit"
                                (click)="buscar(formBusqueda.value)"
                            >
                                Buscar
                            </button>
                        </span>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="cargando"
>
    <div class="col-12">
        

        <div class="alert alert-info text-center" role="alert">
            <h4>Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0"></p>
        </div>
       
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="!cargando"
>
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        
                        <h4 class="card-title">Asistencias registradas</h4>
                        <h6 class="card-subtitle">Total asistencias: {{asistenciasPorDia}}</h6>
                    </div>

                    <div class="col-md-12 text-left">
                        desde
                        &nbsp;    
                        <input [(ngModel)]="rangoFechas.fechaInicio" (change)="dateStart($event.target.value)" class="input-pick-date" type="date">
                        &nbsp;
                        hasta
                        &nbsp;
                        <input [(ngModel)]="rangoFechas.fechaFin" (change)="dateEnd($event.target.value)" class="input-pick-date" type="date">
                        &nbsp;
                        &nbsp;
                        <button type="button" *ngIf="enabledPdf" (click)="downloadPDF()" class="m-l-10 btn btn-outline-success"><i class="fa fa-download"></i> PDF</button>
                    </div>
                    

                </div>
                <div class="table-responsive top-10 m-t-30">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Socio</th>
                                <th class="text-center">Check</th>
                                <th *ngIf="enabledPdf" class="text-nowrap w100">Acciones</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="asistenciasData.length>0">
                           <tr *ngFor="let asistencia of asistenciasData">

                            <td
                                routerLink="/dashboard/socio/{{asistencia.id}}"
                                class="cursorpointer table-success"
                            >
                              {{asistencia.nombres}} {{asistencia.apellidoPaterno}} {{asistencia.apellidoMaterno}}
                            </td>
                            <th class="text-center"> {{asistencia.fecha | date: 'dd/MM/yyyy'}} {{asistencia.hora}} </th>
                            <td *ngIf="enabledPdf" class="text-center">
                                <button (click)="eliminarAsistencia(asistencia.id)" type="button" class="btn btn-danger btn-circle"><i class="fa fa-trash"></i> </button>
                            </td>
                            </tr>
                            
                        </tbody>

                        <tbody *ngIf="asistenciasData.length<=0">
                            <tr>
                                <td colspan="8">
                            <div class="p-20 text-center">
                                No hay resultados de la busqueda
                            </div>

                            </td>

                            </tr>
                        </tbody>

                    </table>
                </div>

                <div class="clearfix"></div>
                <div class="top-20 col-lg-12 col-md-12">                          
                                
                    <mi-paginador
                        *ngIf="!buscandoPorNombre" 
                        (cargaData)="seleccionaItemsPorPagina($event)"
                        (numeroDePaginaEmit)="seleccionaPagina($event)"
                        [totalPaginasArray]="totalPaginasArray || 0"    
                        [itemsPorPaginaInput]="itemsPorPagina"    
                        [numeroDePaginaInput]="numeroDePagina || 0"    
                        [desdeInput]="desde || 0"    
                        [hastaInput]="hasta || 5"    
                        >
                    </mi-paginador>
                
                </div>                

            </div>
        </div>
    </div>
</div>

