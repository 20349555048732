<div class="card-body wizard-content">
    <!-- <h4 class="card-title">Step wizard</h4> -->
    <!-- <h6 class="card-subtitle">You can find the <a href="http://www.jquery-steps.com" target="_blank">offical website</a></h6> -->
    <form 
        [formGroup]="creaVentaForm"
        (ngSubmit)="crearVenta()" 
        class="tab-wizard wizard-circle wizard clearfix" 
        role="application"
    >
        
    <div *ngIf="!modoEditar" class="steps clearfix">
                
        <ul role="tablist">
            <li 
                role="tab" 
                class="first done" 
                aria-disabled="false" 
                aria-selected="true"
                >
                <a id="steps-uid-0-t-0" aria-controls="steps-uid-0-p-0">
                    <!-- <span class="current-info audible">current step: </span> -->
                    <span class="step">
                        1
                    </span> Información personal
                </a>
            </li>

            <li 
            role="tab" 
            class="last current" 
            aria-disabled="false" aria-selected="false"
            >
            <a id="steps-uid-0-t-1" aria-controls="steps-uid-0-p-1">
                <span class="step">
                    2
                </span> Membresía
            </a>
        </li>
            
        </ul>

    </div>
        
    <div class="content clearfix">


        <!-- Step 2 -->
        <section 
            id="steps-uid-0-p-2" 
            role="tabpanel" 
            aria-labelledby="steps-uid-0-h-2" 
            class="body" 
            aria-hidden="true" 
            >
            <div class="row">
                <div class="col-md-6">

                    <div class="form-group">
                        <label for="participants1">*Membresía</label>
                        <select 
                            class="custom-select form-control" 
                            (change)="obtenerPeriodo($event.target.value)"
                            >
                            <option value="">Selecciona membresia ...</option>
                            <option *ngFor="let membresia of membresiasData" value="{{membresia.id}}">{{membresia.nombreMembresia | lowercase}}</option>
                        </select>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="participants1">*Tipo de membresía</label>
                                <select 
                                    class="custom-select form-control" 
                                    *ngIf="periodosData.length<=0"
                                    disabled
                                    >
                                    <option value=""></option>

                                </select>
                                <select 
                                    class="custom-select form-control" 
                                    formControlName="idPeriodoMembrecia"
                                    *ngIf="periodosData.length>0"
                                    (change)="obtenerPrecio($event)"  
                                    >
                                    <option value="">Elige ...</option>
                                    <option 
                                        *ngFor="let periodo of periodosData" 
                                        value="{{periodo.id}}"
                                    >
                                        Por {{periodo.key | lowercase}}
                                    </option>

                                </select>
                                <div 
                                *ngIf="idPeriodoMembrecia.invalid && (idPeriodoMembrecia.dirty || idPeriodoMembrecia.touched)" 
                                class="myalert" 
                                role="alert"
                                >
                                <span>*Es necesario el campo tipo de membresía</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                            <div class="form-group">

                                <label for="participants1">*¿Número de {{nombrePeriodo}}?</label>
                                <input *ngIf="idPeriodoMembrecia.value.length<=0" disabled type="number" class="form-control">
                                <input 
                                    *ngIf="idPeriodoMembrecia.value.length>0" 
                                    formControlName="cantidad" 
                                    type="number" 
                                    class="form-control"
                                    (ngModelChange)="calculaSubtotal($event)"
                                    >

                                <div 
                                    *ngIf="cantidad.invalid && (cantidad.dirty || cantidad.touched)" 
                                    class="myalert" 
                                    role="alert"
                                >

                                <span *ngIf="creaVentaForm.controls['cantidad'].hasError('required')">*El campo cantidad es requerido </span>
                                <span *ngIf="creaVentaForm.controls['cantidad'].hasError('min')">*La cantidad minima es 1 </span>

                            </div>
                            </div>


                        </div>
                    </div>
                    <div class="form-group">
                        <label for="fecha-inicio">*Fecha de inicio</label>
                        <input formControlName="fechaInicio" type="date" class="form-control">
                        <div 
                            *ngIf="fechaInicio.invalid && (fechaInicio.dirty || fechaInicio.touched)" 
                            class="myalert" 
                            role="alert"
                        >
                        <span>*Es necesaria la fecha de inicio</span>
                    </div>
                    </div>
                </div>
               
               
                <div class="col-md-6">
                    
                    <div class="form-group">
                        <label for="fecha-inicio">Descuento</label>
                        
                        <div class="input-group">
                            <span class="input-group-addon">$</span>

                            
                            <input 
                                type="number" 
                                *ngIf="idPeriodoMembrecia.value.length<=0" 
                                disabled
                                class="form-control"
                                >
                            <input
                                *ngIf="idPeriodoMembrecia.value.length>0"
                                id="idDescuento" 
                                type="number" 
                                class="form-control"
                                formControlName="descuento"
                                (ngModelChange)="calculaDescuento($event)"
                                autocomplete="off"
                                >
                                
                            </div>
                            
                            <div 
                                *ngIf="descuento.invalid && (descuento.dirty || descuento.touched)" 
                                class="myalert" 
                                role="alert"
                            >

                            <span *ngIf="creaVentaForm.controls['descuento'].hasError('required')">*El campo descuento es requerido </span>
                            <span *ngIf="creaVentaForm.controls['descuento'].hasError('min')">*El descuento no puede ser menor a cero</span>

                        </div>
                        
                    </div>

                    <table class="table v-middle no-border">
                        <tbody>
                            <tr>
                                <td>
                                    <input 
                                    type="checkbox"
                                    id="idKeyInscripcion" 
                                    formControlName="inscripcionStatus"
                                    class="filled-in chk-col-light-blue"
                                    (ngModelChange)="estableceEstatusInscripcion()"
                                    />
                                    <label for="idKeyInscripcion">Inscripción</label>
                                </td>

                                <td align="right">
                                    <span class="label label-light-info">
                                        {{ inscripcionStatusLogic ? '$' +precioInscripcion : 'No hay'}}
                                    </span>
                                </td>
                                                            
                            </tr>
                            <tr>
                                <td>Membresía</td>
                                <td align="right">
                                    <span class="label label-light-info">
                                        {{ precio ? '$' +precio : 'No hay'}}
                                    </span>
                                    <br>
                                    <span *ngIf="cantidadSeleccionada > 1" class="label label-light-inverse">
                                        {{ 'x'+cantidadSeleccionada }}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>Subtotal</td>
                                <td align="right">
                                    <span class="label label-light-info">
                                    ${{subtotal}}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>Descuento</td>
                                <td align="right">
                                    <span *ngIf="!descuentoValido" class="label label-light-success">
                                        $0
                                    </span>
                                    <span *ngIf="descuentoValido" class="label label-light-success">
                                        ${{descuento.value}}
                                    </span>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>

                    <div class="my-bg-blue">
                        <h6 class="text-white">Total a pagar</h6>
                        <h3 class="text-white m-b-0">{{totalAPagar | currency:'USD':'symbol':'1.2-2'}}</h3>
                    </div>
                    <br><br>
                    
                </div>


            </div>
        </section>
    </div>
    
    <div class="actions clearfix">

        <ul role="menu" aria-label="Pagination">

            
            <li *ngIf="modoEditar" aria-hidden="true">
                <button 
                    class="btn btn-inverse" 
                    type="button" 
                    role="menuitem"
                    (click)="cerrarModal('cancelar')"
                    >
                    Cancelar 
                    
                </button>
            </li>
            
            <li *ngIf="!modoEditar" aria-disabled="true">
                <button 
                    class="btn btn-info" 
                    type="button" 
                    (click)="anterior()" 
                    role="menuitem">
                    Anterior
                </button>

            </li>
            
            <li aria-hidden="true">
                <button 
                    *ngIf="!loading"
                    class="btn btn-success" 
                    type="submit" 
                    role="menuitem"
                    [disabled]="creaVentaForm.invalid"
                    >
                    Comprar membresía
                </button>
                <button
                    *ngIf="loading" 
                    class="btn btn-success" 
                    type="button" 
                    role="menuitem"
                    disabled
                    >
                    <i class="fa fa-spin fa-refresh fa-1x"></i> Esperando ... 
                </button>
            </li>
            <li *ngIf="!modoEditar" aria-hidden="true">
                <button 
                    class="btn btn-secondary" 
                    type="button" 
                    role="menuitem"
                    (click)="cerrarModal('omitir')"
                    >
                    Omitir 
                    <i class="fa fa-angle-double-right"></i>
                </button>
            </li>
        </ul>
        
    </div>

</form>
</div>