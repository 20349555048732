import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { UsuarioService } from 'src/app/services/usuario.service';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls:['./register.component.css']
})
export class RegisterComponent implements OnInit {
  public loading = false;
  public formSubmitted = false;
  public correosOk = true;
  public registerForm = this.formBuilder.group({

    nombre:['', Validators.required],
    email:['', [Validators.required, Validators.email]],
    password:['', Validators.required],
    password2:['', Validators.required],
    terminos:[false, Validators.requiredTrue]

  }, {validators: this.passwordIguales('password', 'password2')})


  constructor(
              private formBuilder:FormBuilder,
              private usuarioService:UsuarioService,
              private router:Router,
              private mensajesSwalService:MensajesSwalService
              ) { }

  crearUsuario(){
    this.loading = true;
    this.formSubmitted=true;


    if (this.registerForm.invalid ||  !this.correosOk) {
      return;
    }

    // se realiza el posteo
    this.usuarioService.crearCliente(this.registerForm.value).subscribe(res=>{
      
      this.mensajesSwalService.registradoConExito();
      this.loading = false;

    }, (error)=>{
      
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.error.msg,
      });

      this.loading = false;
      
    });
    
  }

  ngOnInit(): void {
  }

  campoNoValido(campo:string):boolean{

    if (this.registerForm.get(campo).invalid && this.formSubmitted) {
      return true; 
    }else{
      return false;
    }

  }

  aceptaTerminos():boolean{
    return !this.registerForm.get('terminos').value && this.formSubmitted
  }

  contrasenaValidas(e){
    
    const passTmp1 = this.registerForm.get('password').value;
    const passTmp2 = this.registerForm.get('password2').value;
  
    if (passTmp1!=passTmp2) {
      this.correosOk = false;
    }else{
      this.correosOk = true;
    }
  }

  passwordIguales(passName1:string, passName2:string){

    return (formGroup:FormGroup)=>{
      const passControl1 = formGroup.get(passName1);
      const passControl2 = formGroup.get(passName2);

      if (passControl1.value == passControl2.value) {
        passControl2.setErrors(null);
      }else{
        passControl2.setErrors({noEsIgual:true});
      }
    }

  }

}
