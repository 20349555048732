import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
  export class UtilsService {
  
    constructor() {}

    verificaCorreo(correo) {
        if (correo.includes('@unifit.mx')) {
            return true; 
        } else {
            return false; 
        }
    }

    obtenerFechaActual() {
        const fecha = new Date();
        
        const anio = fecha.getFullYear();
        const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        const dia = fecha.getDate().toString().padStart(2, '0');
        
        const fechaFormateada = `${anio}-${mes}-${dia}`;
        
        return fechaFormateada;
      }

  }