import { Component, OnInit, HostListener  } from '@angular/core';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import { SociosService } from 'src/app/services/socios.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-check-asistencias',
  templateUrl: './check-asistencias.component.html',
  styleUrls: ['./check-asistencias.component.css']
})
export class CheckAsistenciasComponent implements OnInit {



  public loadingConfirm:boolean = false;
  public step2:boolean = false;
  public loadingAsistencia:boolean = false;
  public dataSocio:any = {
    urlImgThumbnail:null
  }
  public diasRestantes:number = 0;
  public socioId:number = null;


  constructor(
    private mensajesSwalService:MensajesSwalService,
    private sociosService:SociosService,

  ) { }

  ngOnInit(): void {

    this.registraAsistencia();
  }


  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.step2) {
      this.confirmarAsistencia();
    }
  }
  
  async registraAsistencia(){    
    try {

      await Swal.fire({
        title:'Escribe tu número de socio',
        input: 'text',
        inputAutoTrim:true,
        inputValue: '',
        inputPlaceholder: 'Escribe tu número de socio ...',
        inputValidator: (value) => {
          // this.socioId = parseInt(value);
          if (!value) {
            this.socioId = null;
            return 'El campo es obligatorio'
          }
          // if (typeof +value === 'number' && !isNaN(+value)) {
            this.obtenerSocio(value);
          // } else {
          //   this.socioId = null;
          //   return 'El dato ingresado debe ser un número'
          // }

      } 
      })       
    } catch (error) {
      console.error(error);
      this.socioId = null;
      this.loadingAsistencia = false;
    }
  }

  reset(){
    this.socioId = null;
    this.dataSocio = {
      urlImgThumbnail:null
    }
    this.loadingAsistencia = false;
    this.loadingConfirm = false;
    this.step2 = false;
    this.registraAsistencia();
  }


  async obtenerSocio(id){

      this.loadingAsistencia = true;


        await this.sociosService.obtenerSocioPorFolio(id).subscribe(({socio})=>{
          this.socioId = this.dataSocio.folio;
          this.dataSocio = socio;
          this.diasRestantes = socio.dias; 
          this.loadingAsistencia = false;
          if (this.dataSocio.fechaInicioMembrecia!=null && this.dataSocio.estatus=='ACTIVO' && !this.loadingConfirm) {
            this.step2 = true;
          }
        }, ()=>{
          this.socioId = null;
          this.loadingAsistencia = false;
          let timerInterval;
          Swal.fire({
            icon: 'error',
            title: "No se encontró socio!",
            text: 'Intenta nuevamente',
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const timer = Swal.getPopup().querySelector("b");
              timerInterval = setInterval(() => {
                timer.textContent = `${Swal.getTimerLeft()}`;
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
              this.reset();
            }
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              this.reset();
            }
          });
        })
  }

  async confirmarAsistencia(){

    if (this.diasRestantes <=0 ) {
      this.mensajesSwalService.huboUnError('Su membresía a vencido');
      
      setTimeout(() => {
        this.reset();
      }, 2000);
      return;
    } 

    this.loadingConfirm = true;

    try {
      await this.sociosService.registrarAsistencia(this.dataSocio.folio).subscribe((res:any)=>{
  
            this.loadingConfirm = false;
            this.mensajesSwalService.registrarAsistencia();
            if (res.data == true) {
              setTimeout(() => {
                this.reset();
              }, 2000);
            }
          });
      
    } catch (error) {
        setTimeout(() => {
          this.reset();
        }, 2000);
      
    }
  }

}
