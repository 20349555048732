import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Servicio } from '../interfaces/servicio.interface';

const base_url = environment.base_url;


@Injectable({
  providedIn: 'root'
})
export class ServiciosGymService {

  constructor(
    private http:HttpClient,

  ) { }


  get token():string{
    return localStorage.getItem('token') || '';
  }

  get headers(){
    return{
            headers:{
              "Authorization": `Bearer ${this.token}`
            }
         } 
   }


   obtenerServicios(desde:number=0){
    const url = `${base_url}/api_servicios/0/15`;
    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const servicios = res.data
            
            return {
              servicios
            };
        
      })
    )

  }

  async obtenerServiciosPromise(){
    const url = `${base_url}/api_servicios/0/25`;
    try {
      const res = await fetch(url, 
            {
              method:'GET',
              headers:{
                "Authorization": `Bearer ${this.token}`
              },
            });
          
          const data = await res.json();
          return data;

    } catch (error) {
      return false;
    }
  }

  crearServicio(params){
    const url = `${base_url}/api_servicios/crear`;
    return this.http.post(url, params, this.headers);
  }


  eliminarServicio(id:number){
    const url = `${base_url}/api_servicios/${id}`;
    return this.http.delete(url, this.headers).pipe(
      map((res:any)=>{
            return res;
      }),catchError(this.manejarError)
      
    )
  }



  manejarError(error:HttpErrorResponse){
    console.log(error);
    return throwError('Hubo un error, contacte al admin')
    
  }


  editarServicio(id, formData){
    const url = `${base_url}/api_servicios/${id}`;
    
    return this.http.put(url, formData, this.headers).pipe(
      map((res:any)=>{
            return res;
      }),catchError(this.manejarError)
      
    )

  }


}
