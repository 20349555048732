import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RegistroMembresia } from '../interfaces/membresia-register.interface';
import { RegistroVenta } from '../interfaces/venta-registro.interface';


const base_url = environment.base_url+'/api_membrecias';


@Injectable({
  providedIn: 'root'
})
export class MembreciasService {

  constructor(
    private http:HttpClient
  ) { }


  get token():string{
    return localStorage.getItem('token') || '';
  }

  get headers(){
    return{
            headers:{
              "Authorization": `Bearer ${this.token}`
            }
         } 
   }


  editarMembresia(params:RegistroMembresia, idMembresia:number){
    const url = `${base_url}/${idMembresia}`;
      return this.http.put(url, params, this.headers);
    }

  crearMembresia(params:RegistroMembresia){
    const url = `${base_url}/nuevo`;
      return this.http.post(url, params, this.headers);
    }

    obtenerticket(folio:String){

      const url = `${base_url}/venta/reimprimir/ticket/${folio}`;
      return this.http.get(url, {
        responseType: 'blob',
        observe: 'response',
        headers: new HttpHeaders(
          { 
            "Authorization": `Bearer ${this.token}`,
            "Accept" : 'application/pdf',
            'Content-Type':  'application/json'
        })
      });
  
      
    }

  obtenerMembresia(id){
    const url = `${base_url}/${id}`;
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{
        const membresia = res.data            
            return {
              membresia
            };
      })
    )
  }
  
  obtenerMembresias(desde:number=0){
    const url = `${base_url}/0/200`;
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{
        const membresias = res.data            
            return {
              membresias
            };
      })
    )
  }

  obtenerUltimaMembresia(id:number){
    const url = `${base_url}/venta/ultimo?socio=${id}`;    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{
        const ultimaMembresia = res.data            
            return {
              ultimaMembresia
            };
      })
    )
  }

  obtenerMembresiasCompradas(id:number){
    const url = `${base_url}/venta/${id}/0/55`;
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{
        const data = res.data            
            return {
              data
            };
      })
    )
  }
  
  confirmarVenta(params:RegistroVenta){
      const url = `${base_url}/venta/nuevo/ticket`;
      return this.http.post(url, params,{
        responseType: 'blob',
        observe: 'response',
        headers: new HttpHeaders(
          { 
            "Authorization": `Bearer ${this.token}`,
            "Accept" : 'application/pdf',
            'Content-Type':  'application/json'
        })
      });
  }


  eliminarVentaMembresia(id:number){
    const url = `${base_url}/venta/${id}`;
    return this.http.delete(url, this.headers).pipe(
      map((res:any)=>{
            return res;
      })
      
    )
  }
  eliminarMembresia(id:number){
    const url = `${base_url}/${id}`;
    return this.http.delete(url, this.headers).pipe(
      map((res:any)=>{
            return res;
      })
      
    )
  }

  obtenerVentaMembresias(numeroDePagina:number, itemsPorPagina:number, params){
    const url = `${base_url}/venta/corte/${numeroDePagina}/${itemsPorPagina}?fechaInicio=${params.fechaInicio}&&fechaFin=${params.fechaFin}`;
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const ventaMembresias = res.data
        const infoPaginacion = res.pageInfo
        
            return {
              ventaMembresias,
              infoPaginacion,
              res
            };
        
      })
    )
  }


  descargarCorteMembresias(params){
    const url = `${base_url}/venta/corte/pdf?fechaInicio=${params.fechaInicio}&&fechaFin=${params.fechaFin}`;
    return this.http.get(url, {
      responseType: 'blob',
      observe: 'response',
      headers: new HttpHeaders(
        { 
          "Authorization": `Bearer ${this.token}`,
          "Accept" : 'application/pdf',
          'Content-Type':  'application/json'
      })
    })
  }


}
