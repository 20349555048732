import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProductosService } from 'src/app/services/productos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'modal-stock',
  templateUrl: './modal-stock.component.html',
  styleUrls: ['./modal-stock.component.css']
})
export class ModalStockComponent implements OnInit, OnChanges {

  // public modoEditar:boolean = false;
  @Input() productoInput:any;
  @Output() refreshData = new EventEmitter<string>();
  
  public date:any;
  public loading: boolean = false;
  public cambiarStockForm:any;
  public stockFinal:number=0;
  public stockInicial:number=0;
  public idProducto:number=0;
  public nombreProducto:string='';
  public formData:any;

  constructor(
    public productoService:ProductosService,
    private formBuilder:FormBuilder,


  ) {
    this.cambiarStockForm =  this.formBuilder.group({
      cantidadAagregar:[0, Validators.required],
      descripcion:['', Validators.required],
      operacion:true
    });
   }



  ngOnChanges(): void {

    if (this.productoInput!=null) {
      this.idProducto = this.productoInput.id
      this.stockInicial = this.productoInput.cantidad;
      this.stockFinal = this.productoInput.cantidad;
      this.nombreProducto = this.productoInput.nombre;
    }
  }

  ngOnInit(): void {
    this.date = new Date();
  }


  cerrarModal(){

    Swal.fire({
      title: '¿Cancelar?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#E5B700',
      cancelButtonColor: '#717171',
      cancelButtonText:'No',
      confirmButtonText: 'Si'
    }).then((result) => {

        if (result.isConfirmed) {
      
            this.limpiaData();
            this.productoService.cerrarModalStock();

        }        
     })
    
  }

  limpiaData(){

    this.cambiarStockForm.patchValue({
      cantidadAagregar:0,
      descripcion:'',
      operacion:true
    });
    this.formData=null;
  }


  cambiarStock(){
    this.loading = true;
    this.formData = {

      cantidad: this.stockFinal,
      descripcion:this.descripcion.value

    }


    Swal.fire({
      title: '¿Cambiar stock?',
      text:'Todo cambio en el stock quedará registrado',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#E5B700',
      cancelButtonColor: '#717171',
      cancelButtonText:'No',
      confirmButtonText: 'Si'
    }).then((result) => {

        if (result.isConfirmed) {
      
          this.productoService.cambiaStock(this.idProducto, this.formData).subscribe(res=>{

            this.limpiaData();
            this.loading = false;
            this.productoService.cerrarModalStock();
            this.refreshData.emit();
            
          })

        }        
     })



    
    
    
  }

  calculaOperacion(){

    if (this.cantidadAagregar.value == null || this.cantidadAagregar.value == undefined) {
      this.cambiarStockForm.patchValue({
        cantidadAagregar:0,
      });
    }

    if (this.operacion.value) {
      this.stockFinal = this.stockInicial + this.cantidadAagregar.value;
    }else{
      this.stockFinal = this.stockInicial - this.cantidadAagregar.value;
    }

    
  }


  get cantidadAagregar() {return this.cambiarStockForm.get('cantidadAagregar')}
  get operacion() {return this.cambiarStockForm.get('operacion')}
  get descripcion() {return this.cambiarStockForm.get('descripcion')}


}
