import Swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { Servicio } from 'src/app/interfaces/servicio.interface';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import { ServiciosGymService } from 'src/app/services/servicios-gym.service';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styles: [
  ]
})
export class ServiciosComponent implements OnInit {

  public servicioTxt:string
  public cargando:boolean=true;
  public desde:number=0;
  public serviciosData:Servicio[]=[];
  public backUpserviciosData:Servicio[]=[];
  public nombre:string
  public descripcion:string="Ejercicio"
  public servicio:any = {
    nombre: '',
    descripcion:'Ejercicio'
  }


  constructor(
    private serviciosGymService:ServiciosGymService,
    public mensajesSwalService:MensajesSwalService,

  ) { }

  ngOnInit(){
  
    this.cargarServicios()
  
  }

  buscarServicio(servicio){
    if (servicio=='') {
      // this.cargarServicios();
      this.serviciosData = this.backUpserviciosData
      this.cargando = false;

    }else{
        this.serviciosData = this.backUpserviciosData.filter(function (item:any) {          
          return item.nombre.toLowerCase().includes(servicio.toLowerCase());
        });
        this.cargando = false;
    }
    
    
  }
  async agregar(){

    try {
      await Swal.fire({
        title:'Escribe nombre para servicio',
        input: 'text',
        inputAutoTrim:true,
        inputValue: '',
        inputPlaceholder: 'Escribe nombre ...',
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'El campo es obligatorio'
          }

        const data = {
          nombre:value,
          descripcion:"Ejercicio"
        }

        this.serviciosGymService.crearServicio(data).subscribe(res=>{
          this.servicio.nombre = '';
          this.mensajesSwalService.agregadoConExito('Servicio','o')
          this.cargarServicios();      
          
          })
          
          }
      })
      
    } catch (error) {
      console.error(error);
    }

      


  }

  cargarServicios(){
    this.cargando=true;

    this.serviciosGymService.obtenerServicios(this.desde)
    .subscribe(({servicios})=>{
      this.serviciosData = servicios;
      this.backUpserviciosData = servicios;
      this.cargando=false;
      
    })
  }

  eliminarServicio(id){

    Swal.fire({
      title: '¿Eliminar servicio?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#E5B700',
      cancelButtonColor: '#717171',
      cancelButtonText:'No',
      confirmButtonText: 'Si'
    }).then((result) => {
        if (result.isConfirmed) {
            this.serviciosGymService.eliminarServicio(id).subscribe((res:any)=>{

              const isDeleted = res.data;
                if (isDeleted) {
                  this.mensajesSwalService.eliminadoConExito();
                  this.cargarServicios();
                } else {
                  this.mensajesSwalService.servicioAsignado();
                }              
            },(err)=>{
              this.mensajesSwalService.error500(err);
            })
        }        
     })
    
  }


  async editarServicio(servicio){


    const { value='' } = await Swal.fire({
       title:'Escribe el nuevo nombre para "' +servicio.nombre + '"',
       input: 'text',
       inputAutoTrim:true,
       inputValue: servicio.nombre,
       inputPlaceholder: 'Escribe nombre ...',
       showCancelButton: true,
       inputValidator: (value) => {
         if (!value) {
           return 'Debes escribir un nombre para "' +servicio.nombre + '"'
         }

        const data= {
          nombre:value,
          descripcion:"none"
        }

        this.serviciosGymService.editarServicio(servicio.id, data).subscribe(res=>{

          this.mensajesSwalService.modificadoConExito('Servicio','o');
          this.cargarServicios();

        },(error)=>{
          this.mensajesSwalService.error500(error);
        })
        
       }
     })
 
 
   }


}
