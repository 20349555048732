import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  public base_url= environment.base_url;

  constructor(private http: HttpClient) { }

  // actualizarFoto(
  async actualizarFoto(
    archivo:File,
    tipo:'usuarios' | 'hospitales' | 'medicos',
    id:string 
  ){

    
    const url = `${this.base_url}/upload/${tipo}/${id}`;
    const formData = new FormData();
    formData.append('img', archivo)
    
    try {
    
        const res = await fetch(url, 
              {
                method:'PUT',
                headers:{
                  
                  'x-token': localStorage.getItem('token') || ''
                },
                body: formData
              });
            
            const data = await res.json();
            
            if (data.ok) {
                return data.nombreArchivo;
            }else{
              
              return false
            }

    } catch (error) {
          
      console.log(error);
      return false;
    }

  }
}
