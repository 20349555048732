import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import { ProductosService } from 'src/app/services/productos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.css']
})
export class VentasComponent implements OnInit {

  public cargando:boolean=true;
  public desde:number=0;
  public hasta:number=5;
  public nombre:string
  public productosData:any[]=[];
  public productosSeleccionados:any[] =[];
  public numeroDePagina:number=0;
  public itemsPorPagina:number=5;
  public terminoBusqueda:string=''
  public total:number=0
  public totalItems:number=0;
  public infoPaginacion:any;
  public totalPaginasInt:number=1;
  public totalPaginasArray:number[]=[];
  public itemsTop:number=5
  public typeView:string='todos'
  public buscandoPorNombre:boolean=false;
  public ventaProductoForm:any;
  public cantidadInicial:number=1;
  public listoParaVenta: boolean = false;
  public isSubmit: boolean = false;

  constructor(
    private productosService:ProductosService,
    public mensajesSwalService:MensajesSwalService,
    private formBuilder: FormBuilder,
  ) {
    this.ventaProductoForm = this.formBuilder.group({
      idSocio:[0, Validators.required],
      productos: this.formBuilder.array([])
    });
   }

  ngOnInit(){
    this.cambiarTipoDeVista('todos');
  }

  get productos(){
    return this.ventaProductoForm.controls['productos'] as FormArray;
  }

  agregarProducto(producto){

    
    

    const productosFormGroup = this.formBuilder.group({
      idProducto:[producto.id, Validators.required],
      nombre:[producto.nombre, Validators.required],
      precio:[producto.precio, Validators.required],
      stock:[producto.cantidad, Validators.required],
      cantidad:[1, Validators.required]
    })

    var productoEncontrado = this.productos.value
      .find(prod => prod.idProducto === producto.id);
      
    if (!productoEncontrado) {
      
        this.productos.push(productosFormGroup);
        this.calculaTotal();
    }else{
      this.mensajesSwalService.productoYaExiste();
    }

  }
  quitarProducto(index:number){
    this.productos.removeAt(index);
    this.calculaTotal();
    if (this.productos.length <= 0) {
      this.listoParaVenta = false;    
    }
  }

  limpiarData() {
    this.ventaProductoForm = this.formBuilder.group({
      idSocio:[0, Validators.required],
      productos: this.formBuilder.array([])
    });
    this.total=0;
    this.listoParaVenta = false;
  }

  agregaProductoACarrito(cantidad, stock){
    
    if (cantidad == null) {
      this.listoParaVenta = false;
      return;
    } else if(cantidad <= stock) {
        this.calculaTotal();
    } else {
      this.listoParaVenta = false;
      this.mensajesSwalService.alertStock('tu producto');
      return;
    }

  }

  calculaTotal(){

    if (this.productos.value.length <= 0) {
      this.listoParaVenta = false;
      return;
    }
    
    this.total = 0;
    for (let i = 0; i < this.productos.value.length; i++) {      
      this.listoParaVenta = true;
      this.total = this.total + (this.productos.value[i].precio * this.productos.value[i].cantidad);
    }
  }

  buscar(busqueda):void{
    const terminoTxt = busqueda.terminoBusqueda;
    this.cargando=true;
    this.buscandoPorNombre=true

    if (terminoTxt=='') {
      this.cargarProductos();
      this.buscandoPorNombre=false;
    }else{
        this.productosService.obtenerProductoPorNombre(terminoTxt).subscribe(socios=>{
          this.productosData = socios;
          this.cargando=false;
        },()=>{
          this.cargando=false
        })
    }
  }

  cargarProductos(){
    this.cargando=true;
      this.productosService.obtenerProductos(this.numeroDePagina, this.itemsPorPagina)
      .subscribe(({productos, infoPaginacion})=>{
        this.productosData = productos;

        this.infoPaginacion = infoPaginacion
        this.totalPaginasInt = infoPaginacion.totalPages
        this.totalPaginasArray=[]
        this.totalItems= infoPaginacion.totalElements
  
        
        for (let i = 0; i < this.totalPaginasInt; i++) {
            this.totalPaginasArray.push(i);
          }


       this.cargando=false;

      })
  }

  seleccionaItemsPorPagina(e){
    this.itemsPorPagina = e;
    this.cargarProductos();
  }

  seleccionaPagina(data){
    this.numeroDePagina =  data.paginaSeleccionada;
    this.desde =  data.desde;
    this.hasta =  data.hasta;
    this.cargarProductos();
  }

  obtenerProductosTop(){
    this.cargando=true;
      this.productosService.obtenerProductosTop(this.itemsTop)
      .subscribe((res)=>{
        this.productosData = res;         
        this.cargando=false;
      })
  }

  cambiarTipoDeVista(e){
    this.typeView = e;
    if (e == 'todos') {
      this.cargarProductos();
    } else {
      this.obtenerProductosTop();
    }
    
  }



  confirmarVenta(){

    for (let i = 0; i < this.productos.value.length; i++) { 
      if (this.productos.value[i].cantidad > this.productos.value[i].stock) {
          this.mensajesSwalService.alertStock(this.productos.value[i].nombre);
          return ;
        }  
    }

      const params = {
        idSocio: this.ventaProductoForm.value.idSocio,
        productos: this.ventaProductoForm.value.productos  
      }      

    Swal.fire({
      title: '¿Confirmar venta?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#07d79c',
      cancelButtonColor: '#717171',
      cancelButtonText:'Cancelar',
      confirmButtonText: 'Confirmar'
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          this.isSubmit = true;
            this.productosService.ventaProductos(params).subscribe((res) => {
              if (res) {
                this.isSubmit = false;
              }
              this.abrirPDFOtraVentana(res);
              this.limpiarData();

              Swal.fire(
                'Venta realizada con exito!',
                'La venta ha sido registrada',
                'success'
              )
            })
        } catch (error) {
          this.isSubmit = false;
        }
      }
    })

  }

  abrirPDFOtraVentana(res) {
    
    const blob = new Blob([res.body], { type: 'application/pdf' }),
      url = window.URL.createObjectURL(blob);
      window.open(url);
  }


}
