<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">
                    {{ isEdit ? 'Editar membresía' : 'Crear membresía' }}                    
                </h4>
                <h6 class="card-subtitle">
                    {{ isEdit ? 'Toda acción de editar, quedará registrada' : 'Personaliza tu membresía' }}                    
                </h6>

                <br>
                <form 
                    #creaMembresiaForm="ngForm"
                    class="tab-wizard wizard-circle wizard clearfix" 
                >

                <div class="col-xs-12">
                    
                    <div class="form-group">
                        <label class="control-label">Nombre de la membresía</label>
                        <input 
                            type="text" 
                            class="form-control"
                            name="nombreMembresia"
                            autocomplete="off"
                            [(ngModel)] = membresia.nombreMembresia
                            #nombreMembresia="ngModel"
                            (ngModelChange)="validaAll()" 
                            >
                    </div>
                    <div 
                        *ngIf="campoNombreAlert && (nombreMembresia.dirty || nombreMembresia.touched)" 
                        class="myalert" 
                        role="alert"
                        >
                        <span>*Es necesario el campo nombre de la membresia</span>
                    </div>

                <br>
                <div class="row">



                    <div class="col-md-6 mis-servicios">
                        
                        <h5 class="box-title">Servicios que ofreces</h5>

                        <div class="row animated fadeIn fast" *ngIf="cargando">
                            <div class="col-12">
                                
                        
                                <div class="alert alert-info text-center" role="alert">
                                    <h4>Cargando</h4>
                                    <i class="fa fa-spin fa-refresh fa-2x"></i>
                                    <p class="mb-0"></p>
                                </div>
                               
                            </div>
                        </div>
                        
                        
                        <div class="h350 row animated fadeIn fast" *ngIf="!cargando">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                           
                                        <div class="table-responsive">
                                            <table class="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Nombre</th>
                                                   
                                                        <th class="text-nowrap w100">Agregar</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let servicio of serviciosData">
                                                   
                                                       <td 
                                                            (click)="seleccionarServicio(servicio)" 
                                                            class="cursorpointer">{{servicio.nombre}}
                                                        </td>
                                                       <td 
                                                            (click)="seleccionarServicio(servicio)" 
                                                            class="cursorpointer text-nowrap text-center">
                                                            <i class="color-blue fa fa-chevron-right m-r-10"></i>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>


                    <!-- ================================================================================================================================= -->
                    <!-- ================================================================================================================================= -->
                    

                    <div class="col-md-6 mis-servicios-seleccionados">
                        
                        <h5 class="box-title">Servicios seleccionados</h5>
                        
                        <div class="row animated fadeIn fast">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                                        
                                        <div class="table-responsive">
                                            <table class="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th class="text-nowrap w100">Quitar</th>
                                                        
                                                        <th>Nombre</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="!dataSelected">
                                                    <tr>
                                                        <td colspan="2">
                                                            <div class="alert alert-info">
                                                                <h5 class="text-info">
                                                                    <i class="fa fa-exclamation-circle"></i> No hay servicios seleccionados
                                                                </h5> <h6>Selecciona al menos un servicio</h6> 
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    

                                                </tbody>
                                                <tbody *ngIf="dataSelected">
                                                    
                                                    <tr *ngFor="let servicio of serviciosDataSeleccionados">
                                                   
                                                       <td (click)="deseleccionarServicio(servicio)" class="cursorpointer text-nowrap text-center">
                                                             <i class="color-blue fa fa-chevron-left m-r-10"></i>
                                                        </td>

                                                       <td (click)="deseleccionarServicio(servicio)" class="cursorpointer">{{servicio.nombre}}</td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    
                    <!-- ================================================================================================================================= -->
                    <!-- ================================================================================================================================= -->

                    <div class="top-50 col-md-6 configuracion-precios">
                        
                        <h5 class="box-title">Configuración de precios</h5>

                        
                        <div class="row animated fadeIn fast">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                           
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td>Inscripción</td>
                                                        <td class="text-nowrap text-center">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">$</span>
                                                                <input
                                                                    type="number" 
                                                                    class="form-control"
                                                                    name="precioInscripcion"
                                                                    [(ngModel)] = membresia.precioInscripcion
                                                                    #inscripcion="ngModel"
                                                                    (ngModelChange)="validaAll()"
                                                                    >
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="campoInscripcionAlert">
                                                        <td colspan="2">
                                                            <div  
                                                                class="myalert" 
                                                                role="alert"
                                                                >
                                                                <span>*La inscripción no tiene precio asignado</span>
                                                            </div>
                                                        </td>
                                                    </tr>                                                   

                                                </tbody>
                                            </table>

                                            

                                        </div>
                                        <div class="table-responsive">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Periodo</th>
                                                        <th class="text-nowrap w150">Precio</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        
                                                        <td>
                                                            <input 
                                                                type="checkbox"
                                                                id="idKeyYear" 
                                                                name="keyYear"
                                                                [(ngModel)] = membresia.periodos[0].activo
                                                                #keyYear="ngModel"
                                                                class="filled-in chk-col-light-blue"
                                                                (ngModelChange)="validaAll()" 
                                                            />
                                                            <label for="idKeyYear">Anualidad</label>
                                                        </td>
                                                        
                                                        <td *ngIf="membresia.periodos[0].activo" class="text-nowrap text-center">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">$</span>
                                                                <input
                                                                    type="number" 
                                                                    class="form-control"
                                                                    name="precioYear"
                                                                    [(ngModel)] = membresia.periodos[0].precio
                                                                    #precioYear="ngModel"
                                                                    (ngModelChange)="validaCamposPrecio('year')" 
                                                                    >
                                                            </div>
                                                        </td>
                                                        <td *ngIf="!membresia.periodos[0].activo" class="text-nowrap text-center">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">$</span>
                                                                <input
                                                                    type="number" 
                                                                    class="form-control"
                                                                    name="precioYear"
                                                                    [(ngModel)] = membresia.periodos[0].precio
                                                                    #precioYear="ngModel"
                                                                    readonly
                                                                    >
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td>
                                                            <input 
                                                                type="checkbox"
                                                                id="idKeyMonth" 
                                                                name="keyMonth"
                                                                [(ngModel)] = membresia.periodos[1].activo
                                                                #keyMonth="ngModel"
                                                                class="filled-in chk-col-light-blue"
                                                                (ngModelChange)="validaAll()" 

                                                            />
                                                            <label for="idKeyMonth">Mensualidad</label>
                                                        </td>
                                                        
                                                        <td *ngIf="membresia.periodos[1].activo" class="text-nowrap text-center">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">$</span>

                                                                <input
                                                                    id="idprecioMonth" 
                                                                    type="number" 
                                                                    class="form-control"
                                                                    name="precioMonth"
                                                                    [(ngModel)] = membresia.periodos[1].precio
                                                                    #precioMonth="ngModel"
                                                                    (ngModelChange)="validaCamposPrecio('month')" 
                                                                    >
                                                            </div>
                                                        </td>
                                                        <td *ngIf="!membresia.periodos[1].activo" class="text-nowrap text-center">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">$</span>

                                                                <input
                                                                    id="idprecioMonth" 
                                                                    type="number" 
                                                                    class="form-control"
                                                                    name="precioMonth"
                                                                    [(ngModel)] = membresia.periodos[1].precio
                                                                    #precioMonth="ngModel"
                                                                    readonly
                                                                    >
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        
                                                        <td>
                                                            <input 
                                                                type="checkbox"
                                                                id="idKeyQuincena" 
                                                                name="keyQuincena"
                                                                [(ngModel)] =membresia.periodos[2].activo
                                                                #keyQuincena="ngModel"
                                                                class="filled-in chk-col-light-blue"
                                                                (ngModelChange)="validaAll()" 

                                                            />
                                                            <label for="idKeyQuincena">Quincena</label>
                                                        </td>
                                                        
                                                        
                                                        <td *ngIf="membresia.periodos[2].activo" class="text-nowrap text-center">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">$</span>

                                                                <input
                                                                    id="idprecioQuincena" 
                                                                    type="number" 
                                                                    class="form-control"
                                                                    name="precioQuincena"
                                                                    [(ngModel)] = membresia.periodos[2].precio
                                                                    #precioQuincena="ngModel"
                                                                    (ngModelChange)="validaCamposPrecio('quincena')" 
                                                                    >
                                                            </div>
                                                        </td>

                                                        <td *ngIf="!membresia.periodos[2].activo" class="text-nowrap text-center">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">$</span>

                                                                <input
                                                                    id="idprecioQuincena" 
                                                                    type="number" 
                                                                    class="form-control"
                                                                    name="precioQuincena"
                                                                    [(ngModel)] = membresia.periodos[2].precio
                                                                    #precioQuincena="ngModel"
                                                                    readonly
                                                                    >
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        
                                                        <td>
                                                            <input 
                                                                type="checkbox"
                                                                id="idKeySemana" 
                                                                name="keySemana"
                                                                [(ngModel)] = membresia.periodos[3].activo
                                                                #keySemana="ngModel"
                                                                class="filled-in chk-col-light-blue"
                                                                (ngModelChange)="validaAll()" 

                                                            />
                                                            <label for="idKeySemana">Semana</label>
                                                        </td>
                                                        
                                                        <td *ngIf="membresia.periodos[3].activo" class="text-nowrap text-center">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">$</span>

                                                                <input
                                                                    id="idprecioSemana" 
                                                                    type="number" 
                                                                    class="form-control"
                                                                    name="precioSemana"
                                                                    [(ngModel)] = membresia.periodos[3].precio
                                                                    #precioSemana="ngModel"
                                                                    (ngModelChange)="validaCamposPrecio('semana')" 
                                                                    >
                                                            </div>
                                                        </td>
                                                        <td *ngIf="!membresia.periodos[3].activo" class="text-nowrap text-center">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">$</span>

                                                                <input
                                                                    id="idprecioSemana" 
                                                                    type="number" 
                                                                    class="form-control"
                                                                    name="precioSemana"
                                                                    [(ngModel)] = membresia.periodos[3].precio
                                                                    #precioSemana="ngModel"
                                                                    readonly
                                                                    >
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    
                                                    <tr>
                                                        
                                                        <td>
                                                            <input 
                                                                type="checkbox"
                                                                id="idKeyDia" 
                                                                name="keyDia"
                                                                [(ngModel)] = membresia.periodos[4].activo
                                                                #keyDia="ngModel"
                                                                class="filled-in chk-col-light-blue"
                                                                (ngModelChange)="validaAll()" 

                                                            />
                                                            <label for="idKeyDia">Dia</label>
                                                        </td>
                                                        
                                                        <td *ngIf="membresia.periodos[4].activo" class="text-nowrap text-center">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">$</span>

                                                                <input
                                                                    id="idprecioDia" 
                                                                    type="number" 
                                                                    class="form-control"
                                                                    name="precioDia"
                                                                    [(ngModel)] = membresia.periodos[4].precio
                                                                    #precioDia="ngModel"
                                                                    (ngModelChange)="validaCamposPrecio('dia')" 
                                                                    >
                                                            </div>
                                                        </td>
                                                        <td *ngIf="!membresia.periodos[4].activo" class="text-nowrap text-center">
                                                            <div class="input-group">
                                                                <span class="input-group-addon">$</span>

                                                                <input
                                                                    id="idprecioDia" 
                                                                    type="number" 
                                                                    class="form-control"
                                                                    name="precioDia"
                                                                    [(ngModel)] = membresia.periodos[4].precio
                                                                    #precioDia="ngModel"
                                                                    readonly
                                                                    >
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    

                                                </tbody>
                                            </table>

                                            <div 
                                             *ngIf="nombrePrecioNull" 
                                             class="myalert" 
                                             role="alert"
                                             >
                                             <span>*Un campo seleccionado no tiene precio asignado</span>
                                         </div>

                                        </div>
                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>

    <!-- ================================================================================================================================= -->
    <!-- ================================================================================================================================= -->

                    <br>
                    <div class="clearfix"></div>
                    <div *ngIf="!loading" class="col-md-12 text-center">
                        <button routerLink="/dashboard/membresias" type="button" class="btn btn-inverse">Cancelar</button>
                        &nbsp;

                        <button 
                            type="submit" 
                            (click)="crearMembresia(creaMembresiaForm.value)" 
                            class="btn btn-success" 
                            [disabled]="isDisabled"
                            >
                            Guardar cambios
                        </button>
                    
                    </div>
                    <div *ngIf="loading" class="col-md-12 text-center">
                        <button disabled type="button" class="btn btn-inverse">Cancelar</button>
                        &nbsp;

                        <button 
                            class="btn btn-success animated fadeIn fast disabledBtn" 
                            disabled
                            >
                            <i class="fa fa-spin fa-refresh fa-1x"></i>
                        </button>
                    
                    </div>
                    <br>


    <!-- ================================================================================================================================= -->
    <!-- ================================================================================================================================= -->



                </div>
            </div>
                </form>
        </div>
    </div>
</div>



<!-- <div class="row animated fadeIn fast">
    <div class="col-12">
        

        <div class="alert alert-info text-center" role="alert">
            <h4>Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0"></p>
        </div>
       
    </div>
</div> -->

