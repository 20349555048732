<div class="row animated fadeIn fast">
    <div *ngIf="!disabledFeature" class="col-12">
        <div class="card">
            <div class="card-body">
                <form 
                    #formBusqueda="ngForm"
                >

                    <div class="input-group">
                        <input 
                            type="text" 
                            [(ngModel)]="terminoBusqueda"
                            name="terminoBusqueda"
                            class="form-control" 
                            placeholder="Escribe folio para buscar..."
                            autocomplete="off"
                        >
                        <span class="input-group-btn">
                            <button 
                                class="btn btn-info" 
                                type="submit"
                                (click)="buscar(formBusqueda.value)"
                            >
                                Buscar
                            </button>
                        </span>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="cargando"
>
    <div class="col-12">
        

        <div class="alert alert-info text-center" role="alert">
            <h4>Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0"></p>
        </div>
       
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="!cargando"
>
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        
                        <h4 class="card-title">Corte de caja de {{labelProductos}}</h4>
                        <h6 class="card-subtitle">Tickets: {{totalItems}}</h6>
                    </div>

                    <div class="col-md-12 text-left">
                        desde
                        &nbsp;    
                        <input [(ngModel)]="rangoFechas.fechaInicio" (change)="dateStart($event.target.value)" class="input-pick-date" type="date">
                        &nbsp;
                        hasta
                        &nbsp;
                        <input [(ngModel)]="rangoFechas.fechaFin" (change)="dateEnd($event.target.value)" class="input-pick-date" type="date">
                        &nbsp;
                        &nbsp;
                        <button type="button" (click)="downloadPDF()" class="m-l-10 btn btn-outline-success"><i class="fa fa-download"></i> PDF</button>
                    </div>
                    

                    <div class="col-md-12 m-t-15">
                        <div class="switch">            
                            <label> Modo resumen
                                <input 
                                    type="checkbox"
                                    [(ngModel)]="resumeCorte" 
                                    (change)="changeMode()"
                                >
                                <span class="lever switch-col-light-blue"></span>
                            </label>
                        </div>
                    </div>
                    

                </div>
                <div class="table-responsive top-10 m-t-30">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="w100">Foto</th>
                                <th *ngIf="!resumeCorte">Folio</th>
                                <th>Producto</th>
                                <!-- <th>Descripción</th> -->
                                <th class="text-center">Precio</th>
                                <th class="text-center">Cantidad</th>
                                <th class="text-center">Subtotal</th>

                                <th class="text-nowrap w100">Acciones</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="productosData.length>0">
                           <tr *ngFor="let producto of productosData">
                            <td class="text-center">
                                <img 
                                    *ngIf="producto.urlImgThumbnail!=null" 
                                    [src]="producto.urlImgThumbnail" 
                                    alt="Imagen de perfil" 
                                    class="img-user"
                                 >
                                <img
                                    *ngIf="producto.urlImgThumbnail==null" 
                                    src="../../../assets/images/foto-not-found-black.jpg" 
                                    alt="Imagen de perfil" 
                                    class="img-user"
                                 >
                            </td>

                            <td *ngIf="!resumeCorte" class="">{{producto.folio}}</td>
                            <!-- <td class="">{{producto.descripcion}}</td> -->
                            <td class="">{{producto.nombreProducto}}</td>
                            <td class="text-right">{{producto.precioVenta | currency:'USD':'symbol':'1.2-2'}}</td>
                            <td class="text-center">{{producto.cantidad}}</td>
                            <td class="text-right">{{producto.cantidad * producto.precioVenta | currency:'USD':'symbol':'1.2-2'}}</td>
                            <td class="text-nowrap text-center">
                                <button (click)="eliminarVentaProducto(producto)" type="button" class="btn btn-danger btn-circle"><i class="fa fa-trash"></i> </button>

                            </td>
                            </tr>

                            <tr>
                                <th *ngIf="!resumeCorte"></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th class="table-info text-right"> {{totalPorPagina | currency:'USD':'symbol':'1.2-2'}}</th>
                            </tr>

                        </tbody>

                        <tbody *ngIf="productosData.length<=0">
                            <tr>
                                <td colspan="8">
                            <div class="p-20 text-center">
                                No hay resultados de la busqueda
                            </div>

                            </td>

                            </tr>
                        </tbody>

                    </table>
                </div>

                <div class="clearfix"></div>
                <div class="top-20 col-lg-12 col-md-12">                          
                                
                    <mi-paginador
                        *ngIf="!buscandoPorNombre" 
                        (cargaData)="seleccionaItemsPorPagina($event)"
                        (numeroDePaginaEmit)="seleccionaPagina($event)"
                        [totalPaginasArray]="totalPaginasArray || 0"    
                        [itemsPorPaginaInput]="itemsPorPagina"    
                        [numeroDePaginaInput]="numeroDePagina || 0"    
                        [desdeInput]="desde || 0"    
                        [hastaInput]="hasta || 5"    
                        >
                    </mi-paginador>
                
                </div>
                <div class="clearfix"></div>
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-0 col-xs-0 top-30"></div>
                    <div class="col-lg-3 col-md-3 col-sm-0 col-xs-0 top-30"></div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 top-30">
                        <div class="card bg-info text-white">
                            <div class="card-body">
                                <div class="d-flex">

                                    <div class="stats text-right">
                                        <h1 class="text-white">Total: {{ totalGeneral | currency:'USD':'symbol':'1.2-2'}}</h1>
                                        <h6 class="text-white">Entre: <strong>{{rangoFechas.fechaInicio | date: 'dd/MM/yyyy'}}</strong> y <strong>{{rangoFechas.fechaFin | date: 'dd/MM/yyyy'}}</strong></h6>
                                    </div>
                                    <div class="stats-icon text-left ml-auto"><i class="fa fa-dollar display-5 op-3 text-dark"></i></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<modal-producto 
    [idProductoInput]="idProductoInput || null"
    (refreshData)="cargarProductos()"
    >
</modal-producto>

<modal-stock
    [productoInput]="productoInput || null"
    (refreshData)="cargarProductos()"
>

</modal-stock>

