import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RegistroSocio } from '../interfaces/socio-registro';
import { Socio } from '../models/socio.model';
import { Router } from '@angular/router';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class SociosService {

  
  private _ocultarModalAgregar:boolean=true;

  constructor(
    private http:HttpClient,
    private router:Router
  ) { }

  get token():string{
    return localStorage.getItem('token') || '';
  }

  get headers(){
    return{
            headers:{
              "Authorization": `Bearer ${this.token}`
            }
         } 
   }

  get ocultarModalAgregar(){
    return this._ocultarModalAgregar;
  }

  abrirModalAgregarSocio(){
     this._ocultarModalAgregar = false;
  }
  cerrarModalAgregarSocio(){
    this._ocultarModalAgregar = true;
  }


  crearSocio(params:RegistroSocio){

      const url = `${base_url}/api_socios/crear`;

        return this.http.post(url, params, this.headers).pipe(
          map((res:any)=>{
            
            const idSocio:number = res.data

                return {
                  idSocio
                };
            
          })
        )
  }

  obtenerqr(id:Number){


    const url = `${base_url}/api_socios/asistencias/qr?socio=${id}`;
    return this.http.get(url, {
      responseType: 'blob',
      observe: 'response',
      headers: new HttpHeaders(
        { 
          "Authorization": `Bearer ${this.token}`,
          "Accept" : 'application/pdf',
          'Content-Type':  'application/json'
      })
    });

    
  }

  obtenerSocios(numeroDePagina:number ,itemsPorPagina:number){
    const url = `${base_url}/api_socios/${numeroDePagina}/${itemsPorPagina}`;
    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const socios = res.data
        const infoPaginacion = res.pageInfo
            
            return {
              socios,
              infoPaginacion
            };
        
      })
    )

  }
  obtenerSociosVencidos(numeroDePagina:number ,itemsPorPagina:number){
    const url = `${base_url}/api_socios/membrecias/vencidas/${numeroDePagina+1}/${itemsPorPagina}`;
    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const socios = res.data
        const infoPaginacion = res.pageInfo
            
            return {
              socios,
              infoPaginacion
            };
        
      })
    )

  }
  obtenerSociosActivos(numeroDePagina:number ,itemsPorPagina:number){
    const url = `${base_url}/api_socios/membrecias/activas/${numeroDePagina+1}/${itemsPorPagina}`;
    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const socios = res.data
        const infoPaginacion = res.pageInfo
            
            return {
              socios,
              infoPaginacion
            };
        
      })
    )

  }
  obtenerSociosPorVencer(numeroDePagina:number ,itemsPorPagina:number, dias:number){
    const url = `${base_url}/api_socios/membrecias/proximas-vencidas/${numeroDePagina+1}/${itemsPorPagina}?dias=${dias}`;
    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const socios = res.data
        const infoPaginacion = res.pageInfo
            
            return {
              socios,
              infoPaginacion
            };
        
      })
    )

  }

  obtenerSociosSinRenovar(numeroDePagina:number ,itemsPorPagina:number, meses:number){

    const url = `${base_url}/api_socios/membrecias/vencidas/${meses}/${numeroDePagina+1}/${itemsPorPagina}`;
    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const socios = res.data
        const infoPaginacion = res.pageInfo
            
            return {
              socios,
              infoPaginacion
            };
        
      })
    )

  }
  obtenerSociosSinRegistrar(numeroDePagina:number ,itemsPorPagina:number){

    const url = `${base_url}/api_socios/sin/membrecias/${numeroDePagina+1}/${itemsPorPagina}`;
    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const socios = res.data
        const infoPaginacion = res.pageInfo
            
            return {
              socios,
              infoPaginacion
            };
        
      })
    )

  }

  obtenerSocio(id){
    
    const url = `${base_url}/api_socios/${id}`;
    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{
        const socio = res
            
            return {
              socio
            };
        
      })
    )

  }
  obtenerSocioPorFolio(id){
    const url = `${base_url}/api_socios/folio/${id}`;
    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{
        const socio = res
            
            return {
              socio
            };
        
      }), catchError(this.manejarErrorNoEncontroSocio)
    )

  }

  obtenerSocioPorNombre(nombre){
    
    const url = `${base_url}/api_socios/search?text=${nombre}`;
    
    return this.http.get(url, this.headers)
    .pipe(
      map((res:any)=>{
        const socio:Socio[] = res
            
            return socio;
        
      }),catchError(this.manejarError)
      
    )

  }

  manejarErrorNoEncontroSocio(error:HttpErrorResponse){
    console.log(error);

    return throwError('NO SE ENCONTRO SOCIO');
    
  }


  manejarError(error:HttpErrorResponse){
    console.log(error);

    return throwError('Hubo un error en la peticion')
    
  }


  manejarError500(error:HttpErrorResponse){
    console.log(error);

    return throwError('Hubo un error, contacte al admin')
    
  }

  modificarSocio(id, formData){
    const url = `${base_url}/api_socios/${id}`;
    
    return this.http.put(url, formData, this.headers);

  }

  eliminaSocio(id:number){
    const url = `${base_url}/api_socios/${id}`;
    return this.http.delete(url, this.headers).pipe(
      map((res:any)=>{
            return res;
      }),catchError(this.manejarError500)
      
    )
  }

  fotoSocioUploadService(params){

    const url = `${base_url}/api_multimedia/temporal/api/file/upload`;

        return this.http.post(url, params,{
            reportProgress: true,
            observe: 'events',
            headers: new HttpHeaders(
              { "Authorization": `Bearer ${this.token}` })
          }).pipe(
            map((res:any)=>{
              const respuesta = res.body
              const cargaBytes = res.loaded
              const cargaTotal = res.total
      
                  
                  return {
                    respuesta,
                    cargaBytes,
                    cargaTotal
                  };
              
            }))

  }

  registrarAsistencia(id:any){
    
    const url = `${base_url}/api_socios/asistencias/folio/${id}`;
    const params=null
    return this.http.post(url, params, this.headers);
  }

  obtenerAsistencias(id:number, year, month){
    const url = `${base_url}/api_socios/asistencias/${id}/month?year=${year}&month=${month}`;
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const asistenciasData = res.data
                    
            return asistenciasData;
        
      })
      )


  }
  
  obtenerRegistroAsistencias(numeroDePagina:number ,itemsPorPagina:number, rangoFechas){
    const url = `${base_url}/api_socios/asistencias/corte/${numeroDePagina}/${itemsPorPagina}?fechaInicio=${rangoFechas.fechaInicio}&fechaFin=${rangoFechas.fechaFin}`;
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const asistencias = res.data
        const infoPaginacion = res.pageInfo
                    
            return {
                asistencias,
                infoPaginacion
              };
        
      })
      )


  }


}
