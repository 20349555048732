<div class="row">
    <div class="col-12">
        <div *ngIf="step1" class="card">
            <div class="card-body">
                <div class="row pricing-plan">
                    <div class="col-md-4 col-xs-12 col-sm-6 no-padding">
                        <div class="pricing-box">
                            <div class="pricing-body b-l">
                                <div class="pricing-header">
                                    <h4 class="text-center">Mensual</h4>
                                    <h2 class="text-center"><span class="price-sign">$</span>429</h2>
                                    <p class="uppercase">Suscripción por mes</p>
                                </div>
                                <div class="price-table-content">
                                    <div class="price-row"><i class="fa fa-check"></i> &nbsp; Todas las funcionalidades</div>
                                    <div class="price-row"><i class="fa fa-check"></i> &nbsp; Soporte 27/7</div>
                                    <div class="price-row"><i class="fa fa-check"></i> &nbsp; Actualizaciones</div>
                                    <div class="price-row">
                                        <button (click)="seleccionaSuscripcion('MENSUAL')" class="btn btn-success waves-effect waves-light m-t-20">Seleccionar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-xs-12 col-sm-6 no-padding">
                        <div class="pricing-box featured-plan">
                            <div class="pricing-body">
                                <div class="pricing-header">
                                    <h4 class="price-lable text-white bg-warning"> Popular</h4>
                                    <h4 class="text-center">Semestral</h4>
                                    <h2 class="text-center"><span class="price-sign">$</span>2349</h2>
                                    <p class="uppercase">Suscripción por 6 meses</p>
                                </div>
                                <div class="price-table-content">
                                    <div class="price-row"><i class="fa fa-check"></i> &nbsp; Todas las funcionalidades</div>
                                    <div class="price-row"><i class="fa fa-check"></i> &nbsp; Soporte 27/7</div>
                                    <div class="price-row"><i class="fa fa-check"></i> &nbsp; Actualizaciones</div>
                                    <div class="price-row">
                                        <button (click)="seleccionaSuscripcion('SEMESTRAL')" class="btn btn-success waves-effect waves-light m-t-20">Seleccionar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-xs-12 col-sm-6 no-padding">
                        <div class="pricing-box">
                            <div class="pricing-body b-l">
                                <div class="pricing-header">
                                    <h4 class="text-center">Anual</h4>
                                    <h2 class="text-center"><span class="price-sign">$</span>4548</h2>
                                    <p class="uppercase">Suscripción por año</p>
                                </div>
                                <div class="price-table-content">
                                    <div class="price-row"><i class="fa fa-check"></i> &nbsp; Todas las funcionalidades</div>
                                    <div class="price-row"><i class="fa fa-check"></i> &nbsp; Soporte 27/7</div>
                                    <div class="price-row"><i class="fa fa-check"></i> &nbsp; Actualizaciones</div>
                                    <div class="price-row">
                                        <button (click)="seleccionaSuscripcion('ANUAL')" class="btn btn-success waves-effect waves-light m-t-20">Seleccionar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container clearfix text-right">

                <button 
                    class="btn btn-inverse" 
                    type="button" 
                    role="menuitem"
                    (click)="cerrarModal()"
                    >
                    Cancelar 
                    
                </button>                
            </div>
        </div>

        <div *ngIf="step2" class="container">

                    <form id="payment-form" [formGroup]="form" (submit)="completarPago()">
                        <div id="payment-element"></div>
                        <div *ngIf="error != ''" class="alert alert-warning text-center m-t-20 text-center" role="alert">
                            <h4>Hubo un error</h4>
                            <i class="fa fa-info"></i>
                            <p class="mb-0 text-center">{{error}}</p>
                        </div>
                        <br>
                        <div class="col-lg-12">
                            <div class="card bg-success text-white">
                                <div class="card-body">
                                    <div class="d-flex">
                                        <div class="stats">
                                            <h1 class="text-white">{{precioSuscripcion}}</h1>
                                            <h6 class="text-white">{{tipoSuscripcion}}</h6>
                                        </div>
                                        <div class="stats-icon text-right ml-auto"><i class="fa fa-dollar display-5 op-3 text-dark"></i></div>
                                    </div>
                                </div>
                            </div>
                            <p class="info"><i class="fa fa-info-circle"></i> El comprobante llegará a tu correo electronico registrado </p>
                        </div>
                        <div class="actions clearfix text-right m-t-20">
                            <button 
                                class="btn btn-inverse m-l-10" 
                                type="button" 
                                (click)="anterior()" 
                                role="menuitem">
                                Anterior
                            </button>

                        
                            <button 
                                id="submit"
                                *ngIf="!loading && cargadoCompletamente"
                                class="btn btn-success m-l-10" 
                                type="submit" 
                                role="menuitem"
                                >
                                Comprar
                            </button>
                            <button
                                *ngIf="loading || !cargadoCompletamente" 
                                class="btn btn-success m-l-10" 
                                type="button" 
                                role="menuitem"
                                disabled
                                >
                                <i class="fa fa-spin fa-refresh fa-1x"></i> Esperando ... 
                            </button>
                        </div>
                        <br><br>
                        <!-- <div id="error-message"></div> -->
                    </form>
        </div>


        




    </div>
</div>