import { Component, OnInit } from '@angular/core';
import { SociosService } from 'src/app/services/socios.service';

@Component({
  selector: 'app-asistencias-generales',
  templateUrl: './asistencias-generales.component.html',
  styleUrls: ['./asistencias-generales.component.css']
})
export class AsistenciasGeneralesComponent implements OnInit {

  public desde:number=0;
  public hasta:number=5;
  public numeroDePagina:number=0;
  public itemsPorPagina:number=10;
  public totalPaginasArray:number[]=[];
  public disabledFeature:boolean=true;
  public enabledPdf:boolean=false;
  public cargando:boolean=false;
  public buscandoPorNombre:boolean=false;
  public rangoFechas:any = {}
  public asistencias:number=0;
  public asistenciasPorDia:number=0;
  public asistenciasData:any = [];
  public infoPaginacion:any;
  public totalPaginasInt:number=1;
  public totalItems:number=0;





  constructor(
    private sociosService:SociosService
  ) { }

  ngOnInit(): void {

    const fechaActual = this.obtenerFechaActual();
    this.rangoFechas.fechaInicio = fechaActual
    this.rangoFechas.fechaFin = fechaActual
    this.obtenerAsistencias()

  }

  obtenerAsistencias(){
    this.sociosService.obtenerRegistroAsistencias(this.numeroDePagina, this.itemsPorPagina, this.rangoFechas).subscribe(({asistencias, infoPaginacion}) => {




      this.asistenciasData = asistencias;        
        this.infoPaginacion = infoPaginacion
        this.totalPaginasInt = infoPaginacion.totalPages
        this.asistenciasPorDia = infoPaginacion.totalElements
        this.totalPaginasArray=[]
        this.totalItems= infoPaginacion.totalElements
  
        
        for (let i = 0; i < this.totalPaginasInt; i++) {
            this.totalPaginasArray.push(i);
          }



      
    })
  }

  downloadPDF(){
    // TODO::
  }

  abrirPDFOtraVentana(res) {
    const blob = new Blob([res.body], { type: 'application/pdf' }),
      url = window.URL.createObjectURL(blob);
      window.open(url);
  }

  eliminarAsistencia(idSocio){
    // TODO::
  }

  seleccionaItemsPorPagina(e){

    this.itemsPorPagina = e;
    this.obtenerAsistencias();
    
  }

  seleccionaPagina(data){

    this.numeroDePagina =  data.paginaSeleccionada;
    this.desde =  data.desde;
    this.hasta =  data.hasta;
    this.obtenerAsistencias();
  }

  dateStart(e){
    this.rangoFechas.fechaInicio = e;
    this.obtenerAsistencias();
  }
  dateEnd(e){
    this.rangoFechas.fechaFin = e;
    this.obtenerAsistencias();
  }

  obtenerFechaActual() {
    const fecha = new Date();
    
    const anio = fecha.getFullYear();
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const dia = fecha.getDate().toString().padStart(2, '0');
    
    const fechaFormateada = `${anio}-${mes}-${dia}`;
    
    return fechaFormateada;
  }

}
