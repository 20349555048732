import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { GymService } from 'src/app/services/gym.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {
  
  public oldPassword: string = '';
  public nombre: string = '';
  public apellidoPaterno: string = '';
  public apellidoMaterno: string = '';
  public fechaNacimiento: string = '';
  public sexo: string = '';
  public telefono: string = '';
  public correo: string = '';
  public direccion: string = '';
  public newPassword: string = '';
  public frase: string = 'Quiero modificar los datos de mi centro deportivo';
  public confirmNewPassword: string = '';
  public isDisabled: boolean = true;
  public isDisabledFormDatos: boolean = true;
  public editMode: boolean = false;
  public noCoinciden: boolean = false;
  public loading: boolean = false;
  public loadingDatos: boolean = false;
  public alertEmailEnabled: boolean = false;
  public alertInputRequiredEnabled: boolean = false;
  public keyImg:string = '';
  public urlImg:string = '';


  constructor(
    private usuarioService:UsuarioService,
    public mensajesSwalService:MensajesSwalService,
    public gymService:GymService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.obtenerInfoUsuario();

  }

  recibirFoto(foto){
      this.keyImg = foto;
  }

  recibirStatusLoading(status){
    this.isDisabledFormDatos = status;

    if (!this.isDisabledFormDatos) {
      this.validaFormDatosUsuario();
    }
  }


  
  async cambiarDatos(){    
    try {

      Swal.fire({
        title: '¿Seguro que deseas cambiar los datos?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#E5B700',
        cancelButtonColor: '#717171',
        cancelButtonText:'No',
        confirmButtonText: 'Si'
      }).then((result) => {
          if (result.isConfirmed) {
            this.confirmarCambioDeDatos();
          }    
       })
     
    } catch (error) {
      console.error(error);
    }
  }

  confirmarCambioDeDatos(){

    const dataForm = {
      nombres: this.nombre,
      apellidoPaterno: this.apellidoPaterno,
      apellidoMaterno: this.apellidoMaterno,
      fechaNacimiento: this.fechaNacimiento,
      sexo : this.sexo,
      telefono : this.telefono,
      correo : this.correo,
      direccion : this.direccion,
      keyImg : this.keyImg
    }


    

    this.loadingDatos = true;
    this.usuarioService.actualizarInfoUsuario(dataForm).subscribe(() => {
      this.obtenerInfoUsuario();
      this.loadingDatos = false;
      this.editMode = false;
      this.mensajesSwalService.modificadosConExito('Datos', 'o');
    })

  }


  cambiarPassword(dataForm){
    this.loading = true;

    Swal.fire({
      title: '¿Cambiar contraseña actual?',
      text: 'Esta acción quedara registrada a tu usuario',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#07d79c',
      cancelButtonColor: '#717171',
      cancelButtonText:'No',
      confirmButtonText: 'Confirmar'
    }).then((result) => {
      if (result.isConfirmed) {

        this.usuarioService.cambiarPassword(dataForm).subscribe(() => {
              this.mensajesSwalService.modificadoConExito('Contraseña', 'a');
              this.limpiaData();
              this.loading = false;
      
            }, ()=>{
              this.mensajesSwalService.huboUnError('Verifica las contraseñas');
              this.loading = false;
            })

          }
        this.loading = false;
    })

  }

  obtenerInfoUsuario(){
    this.usuarioService.obtenerUsuario().subscribe((res:any) => {

      this.nombre = res.nombres;
      this.apellidoPaterno = res.apellidoPaterno;
      this.apellidoMaterno = res.apellidoMaterno;
      this.fechaNacimiento = res.fechaNacimiento;
      this.sexo = res.sexo;
      this.telefono = res.telefono;
      this.correo = res.email;
      this.direccion = res.direccion;
      this.urlImg = res.urlImg; 
      this.validaFormDatosUsuario();

      const ProfileData = {
        nombre: res.nombres,
        image: res.urlImg,
        email: res.email
      }
      
      this.usuarioService.updateProfileData(ProfileData);

    })
  }

  validaFormDatosUsuario(){
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!this.nombre || !this.correo || !this.apellidoPaterno || !this.sexo) {
      this.isDisabledFormDatos = true;
      this.alertInputRequiredEnabled = true;
    } else {
      this.alertInputRequiredEnabled = false;
      const esCorreoValido = regexEmail.test(this.correo);
      if (esCorreoValido) {
        this.alertEmailEnabled = false;
        this.isDisabledFormDatos = false;
        
      } else {
        this.alertEmailEnabled = true;
        this.isDisabledFormDatos = true;
      }
    } 
  }

  cancelEditMode(){
    this.editMode = false;
    this.obtenerInfoUsuario();
  }

  validaAll(){
    if (!this.oldPassword || !this.newPassword || !this.confirmNewPassword) {
      this.isDisabled = true;
    } else if (this.newPassword != this.confirmNewPassword) {
      this.isDisabled = true;
      this.noCoinciden = true;
    } else {  
      this.isDisabled = false;
      this.noCoinciden = false;
    }
  }

  
  limpiaData(){
    this.oldPassword='';
    this.newPassword='';
    this.confirmNewPassword='';
  }

}
