<div class="fondo-modal-imagen animated fadeIn fast" [class.oculto]="gymService.ocultarModalPayments" >
    
    <div 
        id="modalPasarela" 
        class="modal fade bs-example-modal-lg show" 
        tabindex="-1" 
        role="dialog" 
        aria-labelledby="myModalPasarelaLabel" 
        style="display: block; padding-right: 16px;"
    >
        
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="col-md-8">
                        <h4 class="modal-title" id="myModalAddSocioLabel">Suscripción</h4>
                    </div>
                    <div class="col-md-4 text-right">
                        <i class="fa fa-window-close cursorpointer" (click)="cerrarModal()"></i>
                    </div>
                </div>
                <div class="modal-body">
                    
                    <app-pasarela>
                    </app-pasarela>

                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-info waves-effect" data-dismiss="modal">Close</button>
                </div>                 -->
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>    
    



</div>
 