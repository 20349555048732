<div class="fondo-modal-imagen animated fadeIn fast" [class.oculto]="sociosService.ocultarModalAgregar" >
    
    <div id="modalAgregarSocio" class="modal fade bs-example-modal-lg show" tabindex="-1" role="dialog" aria-labelledby="myModalAddSocioLabel" style="display: block; padding-right: 16px;">
        
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 *ngIf="!modoEditar" class="modal-title" id="myModalAddSocioLabel">Agregar socio</h4>
                    <h4 *ngIf="modoEditar && !modoComprar" class="modal-title" id="myModalAddSocioLabel">Editar socio</h4>
                    <h4 *ngIf="modoEditar && modoComprar" class="modal-title" id="myModalAddSocioLabel">Comprar membresía - {{ nombreUsuario }}</h4>
                </div>
                <div class="modal-body">
                    
                    <datos-socio 
                        (idSocioEmit)="continua($event)"
                        (refreshSocios)="recargaDataSocios($event)"
                        (cancelarOperacion)="cancelarOperaciones($event)"
                        [idSocioRegreso]="idSocioDeRegreso || null"
                        [modoEditar]="modoEditar || false"
                        *ngIf="mystep1"
                        >
                    </datos-socio>
                    <venta-membresia
                        (anteriorEmit)="isCurrent($event)"
                        (refreshSocios)="recargaDataSocios($event)"
                        (cancelarOperacion)="cancelarOperaciones($event)"
                        [modoEditar]="modoEditar || false"
                        [idSocioInput]="idSocioInputEnviar"
                        *ngIf="mystep2"
                        >
                    </venta-membresia>

                </div>                
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>    
    



</div>
 