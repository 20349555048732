import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'mi-paginador',
  templateUrl: './paginador.component.html',
  styleUrls: ['./paginador.component.css']
})
export class PaginadorComponent implements OnInit {

  @Output() numeroDePaginaEmit = new EventEmitter<Object>();
  @Output() cargaData = new EventEmitter<number>();
  @Input() itemsPorPaginaInput:number
  @Input() numeroDePaginaInput:number
  @Input() desdeInput:number
  @Input() hastaInput:number
  @Input() totalPaginasArray:number[]=[];
  public itemsPorPagina:number=20;
  public numeroDePagina:number=0;
  public desde:number=0;
  public hasta:number=5;
  public estaActivoSiguiente:boolean=false
  public estaActivoAnterior:boolean=false



  constructor() { }

  ngOnInit(): void {
    this.itemsPorPagina = this.itemsPorPaginaInput;
    this.numeroDePagina = this.numeroDePaginaInput;
    this.desde = this.desdeInput
    this.hasta = this.hastaInput
    if (this.totalPaginasArray.length>5) {
      this.estaActivoSiguiente=true
    }
    
  }

  calculaIndiceDePaginas(num){
    if (num==5) {
      
      if (this.totalPaginasArray.length>=this.hasta) {

        this.desde+=num;
        this.hasta+=num;
        this.estaActivoSiguiente=true;
        this.estaActivoAnterior=true;

      }else{
        this.estaActivoSiguiente=false;
        this.estaActivoAnterior=true;
        return;
        
      }
    }

    else if (num==-5) {
      if (this.desde>0) {

        this.desde=this.desde-5;
        this.hasta=this.hasta-5;
        this.estaActivoSiguiente=true;
        this.estaActivoAnterior=true;

        
      }else{
        this.estaActivoSiguiente=true;
        this.estaActivoAnterior=false;
        return;
        
      }
    }
    
    
  }


  seleccionaItemsPorPagina(e){
    this.itemsPorPagina = e.target.value;
    this.cargaData.emit(this.itemsPorPagina);
  }

  seleccionaPagina(paginaSeleccionada){

    this.numeroDePagina = paginaSeleccionada;
    this.numeroDePaginaEmit.emit(
                              {
                                paginaSeleccionada, 
                                desde:this.desde,
                                hasta:this.hasta
                              });
 
  }

}
