import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public temaLink = document.querySelector('#theme');


  constructor(
    
  ) { 
    
    const temaGuardado= localStorage.getItem('theme') || './assets/css/colors/default-dark.css' ;
    this.temaLink.setAttribute('href', temaGuardado);
  }

  changeTheme(tema:string, links:NodeListOf<Element>){
    const urlTema=`./assets/css/colors/${tema}.css`
    this.temaLink.setAttribute('href', urlTema);
    localStorage.setItem('theme', urlTema);
    this.checkCurrentTheme(links);
  }

  checkCurrentTheme(links){

      links.forEach( elem=> {
      elem.classList.remove('working');
      
      const btnTheme= elem.getAttribute('data-theme');
      const btnThemeUrl=`./assets/css/colors/${btnTheme}.css`;
      const currentThemeUrl= this.temaLink.getAttribute('href');
      
        if (btnThemeUrl==currentThemeUrl) {
          elem.classList.add('working'); 
        }
      
    })
    
}
}
