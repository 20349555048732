import { Component, OnInit } from '@angular/core';
import { Socio } from 'src/app/models/socio.model';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import { ProductosService } from 'src/app/services/productos.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-corte',
  templateUrl: './corte.component.html',
  styleUrls: ['./corte.component.css']
})
export class CorteComponent implements OnInit {
  public totalGeneral:number=0;
  public totalPorPagina:number=0;
  public disabledFeature:boolean=true;
  public productosData:any[]=[];
  public cargando:boolean=false;
  public buscandoPorNombre:boolean=false;
  public fechaInicio:Date = null;
  public fechaFin:Date = null;
  public desde:number=0;
  public hasta:number=5;
  public infoPaginacion:any;
  public totalItems:number=0;
  public numeroDePagina:number=0;
  public itemsPorPagina:number=10;
  public totalPaginasInt:number=1;
  public totalPaginasArray:number[]=[];
  public idProductoInput:Object=null;
  public productoInput:Object=null;
  public terminoBusqueda:string=''
  public labelProductos:string= 'productos'
  public socio:Socio=null;
  public filtroPorVencer:boolean=false;
  public filtroSinRenovar:boolean=false;
  public resumeCorte:boolean=false;
  public rangoFechas:any = {}
          

  constructor(
    private productosService:ProductosService,
    private mensajesSwalService:MensajesSwalService,
    
  ) { }

  ngOnInit(): void {
    const fechaActual = this.obtenerFechaActual();
    this.rangoFechas.fechaInicio = fechaActual
    this.rangoFechas.fechaFin = fechaActual
    this.cargarProductos()
  }

  downloadPDF(){
    this.productosService.descargarCorteProductos(this.rangoFechas)
      .subscribe((res)=>{
       this.abrirPDFOtraVentana(res);
      })
  }

  abrirPDFOtraVentana(res) {
    const blob = new Blob([res.body], { type: 'application/pdf' }),
      url = window.URL.createObjectURL(blob);
      window.open(url);
  }

obtenerFechaActual() {
  const fecha = new Date();
  
  const anio = fecha.getFullYear();
  const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
  const dia = fecha.getDate().toString().padStart(2, '0');
  
  const fechaFormateada = `${anio}-${mes}-${dia}`;
  
  return fechaFormateada;
}

dateStart(e){
  this.rangoFechas.fechaInicio = e;
  this.cargarProductos();
}
dateEnd(e){
  this.rangoFechas.fechaFin = e;
  this.cargarProductos();
}

  buscar(busqueda):void{
    

    const terminoTxt = busqueda.terminoBusqueda;
    this.cargando=true;
    this.buscandoPorNombre=true

    if (terminoTxt=='') {
      
      this.cargarProductos();

    }else{

        this.productosService.obtenerProductoPorNombre(terminoTxt).subscribe(producto=>{
        
          this.productosData = producto;

          this.cargando=false;

        },()=>{
          this.cargando=false
        })
              
    }

    
  }

  abrirModal(tipoDeModal, producto){
    
    if (tipoDeModal == 'agregar' ) {
      this.productosService.abrirModalProducto()

    }else if (tipoDeModal == 'editar') {
      
      this.idProductoInput = {
                            productoID: producto.id,
                            operacion:'editar'

                          }

      this.productosService.abrirModalProducto()

    }
    
  }

  cambiarStock(producto){

    this.productoInput = producto
    this.productosService.abrirModalStock();
    
 
   }

  changeMode(){
    this.numeroDePagina =  0;
    this.cargarProductos();
   }
  cargarProductos(){
    
    this.idProductoInput=null;
    this.buscandoPorNombre=false
    this.cargando=true;
    

  try {
    if (!this.resumeCorte) {
      
    this.productosService.obtenerVentaProductos(this.numeroDePagina, this.itemsPorPagina, this.rangoFechas)
    .subscribe(({ventaProductos, infoPaginacion, res})=>{

      this.productosData = ventaProductos;
      this.totalPorPagina = 0;
      this.totalGeneral = res.extraInfo.total;

      for (const producto of this.productosData) {
        const subtotal = producto.cantidad * producto.precioVenta;
        this.totalPorPagina = this.totalPorPagina + subtotal;
      }


      
      this.infoPaginacion = infoPaginacion
      this.totalPaginasInt = infoPaginacion.totalPages
      this.totalPaginasArray=[]
      this.totalItems= infoPaginacion.totalElements

      
      for (let i = 0; i < this.totalPaginasInt; i++) {
          this.totalPaginasArray.push(i);
        }
      
      this.cargando=false;

    })

  } else {




    this.productosService.obtenerResumenVentaProductos(this.numeroDePagina, this.itemsPorPagina, this.rangoFechas)
    .subscribe(({ventaProductos, infoPaginacion, res})=>{

      this.productosData = ventaProductos;
      this.totalPorPagina = 0;
      this.totalGeneral = res.extraInfo.total;

      for (const producto of this.productosData) {
        const subtotal = producto.cantidad * producto.precioVenta;
        this.totalPorPagina = this.totalPorPagina + subtotal;
      }


      
      this.infoPaginacion = infoPaginacion
      this.totalPaginasInt = infoPaginacion.totalPages
      this.totalPaginasArray=[]
      this.totalItems= infoPaginacion.totalElements

      
      for (let i = 0; i < this.totalPaginasInt; i++) {
          this.totalPaginasArray.push(i);
        }
      
      this.cargando=false;

    })





  }

    
  } catch (error) {
    this.cargando=false;
  }


  }

  eliminarVentaProducto(producto){

    Swal.fire({
      title: '¿Eliminar venta de producto?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#E5B700',
      cancelButtonColor: '#717171',
      cancelButtonText:'No',
      confirmButtonText: 'Si'
    }).then((result) => {

        if (result.isConfirmed) {

          this.productosService.eliminarVentaProducto(producto.id).subscribe(res=>{
      
            this.mensajesSwalService.eliminadoConExito();
            this.cargarProductos();
          })

        }        
     })

  }





  
  seleccionaItemsPorPagina(e){

    this.itemsPorPagina = e;
    this.cargarProductos();
    
  }

  seleccionaPagina(data){

    this.numeroDePagina =  data.paginaSeleccionada;
    this.desde =  data.desde;
    this.hasta =  data.hasta;
    this.cargarProductos();
  }


  filtroSocios(e){

    switch (e) {
      case 'todos':
        this.filtroPorVencer=false;
        this.filtroSinRenovar=false;
        break;
      case 'vencidas':

        this.filtroPorVencer=false;
        this.filtroSinRenovar=false;
        
        break;
      case 'por-vencer':

        this.filtroPorVencer=true;
        this.filtroSinRenovar=false;
        
        break;
      case 'sin-renovar':
        
        this.filtroPorVencer=false;
        this.filtroSinRenovar=true;

        break;
    
      default:
        break;
    }

    
  }

  filtroCorte(e) {
    // TODO::
    console.log(e);
    
  }

}
