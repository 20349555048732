import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class ModalImgService {

  public tipo:'usuarios' | 'medicos' | 'hospitales';
  public id:string;
  public img:string;

  public cambioImagen:EventEmitter<string>=new EventEmitter();

  private _ocultarModal:boolean=true;

  constructor() { }

  get ocultarModal(){
    return this._ocultarModal;
  }

  abrirModal(
    tipo: 'usuarios' | 'medicos' | 'hospitales', 
    id:string, 
    img:string='not-found'
    ){
      this._ocultarModal=false;
      this.tipo=tipo;
      this.id=id;
      this.img=img;
      // http://localhost:3000/api/upload/usuarios/45807759-3286-4761-aba5-6f5cd9ff9a33.png
      
      if (img.includes('https')) {
        this.img=img;
      }else{
        this.img=`${base_url}/upload/${tipo}/${img}`
      }
      
  }
  cerrarModal(){
    this._ocultarModal=true;
  
    }
    

}
