import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Usuario } from '../models/usuario.model';
import { Hospital } from '../models/hospital.model';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class BusquedasService {

  
  constructor(private http:HttpClient) { }

  get headers(){

    return{

            headers:{
              'x-token':this.token
            }
         } 
   }

   get token(){
    return localStorage.getItem('token') || '';
  }

  private transformarAtipoUsuario(resultados:any[]):Usuario[]{
    return resultados
    .map(user=>new Usuario(user.nombre,user.email,'',user.img, user.google, user.role, user.uid));
  }

  private transformarAtipoHospital(resultados){
    return resultados;
  }

  private transformarAtipoMedico(resultados){
    
    return resultados;
  }

  busqueda(coleccion, terminoBusqueda){
    
    const url = `${base_url}/busqueda/colecciones/${coleccion}/${terminoBusqueda}`;
    
    return this.http.get<any[]>(url, this.headers)
      .pipe(
        map((res:any)=>{
          
          switch (coleccion) {
            case 'usuarios':
              return this.transformarAtipoUsuario(res.data)
              break;

            case 'hospitales':
         
              return this.transformarAtipoHospital(res.data)
              break;

            case 'medicos':
         
              return this.transformarAtipoMedico(res.data)
              break;
          
            default:
              return [];
          }
          
        })
      )


  }


  busquedaTotal(termino){
    
    if (termino == '' || termino ==null) {
      return;
    }
    
    const url = `${base_url}/busqueda/${termino}`;
    return this.http.get<any[]>(url, this.headers);

  }

}
