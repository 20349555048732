import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';

declare const gapi:any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls:['./login.component.css']
})
export class LoginComponent implements OnInit {

  public token:string = '';
  public loading:boolean = false;
  public formSubmitted:boolean = false;
  public newFeatures:boolean = false;
  public auth2:any;
  public loginForm =  this.formBuilder.group({
    grant_type: "password",
    username:[localStorage.getItem('username') || '', [Validators.required, Validators.email]],
    password:['', Validators.required],
    rememberme:[false]
  });

  constructor(

      private router:Router,
      private mensajesSwalService:MensajesSwalService,
      private activateRoute:ActivatedRoute,
      private formBuilder:FormBuilder,
      private usuarioService:UsuarioService,
      private ngZone:NgZone,

    ) { }

  ngOnInit():void{
    // this.renderButton();
    this.token = this.activateRoute.snapshot.queryParamMap.get('token');

    if (this.token) {
      this.showModal();
    }
    
  }

  showModal(){
    Swal.fire({
      icon: 'info',
      title: "Espera ...",
      text: 'Validando activación',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
          this.usuarioService.activarCliente(this.token).subscribe(() => {
            Swal.close()
            this.mensajesSwalService.activadoConExito();
          }, (error) => {
            Swal.close()
            this.mensajesSwalService.huboUnError(error);
          })
      },
      willClose: () => {
        console.log('---');
      }
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('***');
        
      }
    });
  }


  login(){
    this.loading = true;
    this.formSubmitted=true;
    if (this.loginForm.invalid ) {
      this.loading = false;
      return;
    }
        this.usuarioService.login(this.loginForm.value).subscribe(()=>{

          if (this.loginForm.get('rememberme').value) {
            localStorage.setItem('username', this.loginForm.get('username').value)            
          }else{
            localStorage.removeItem('username');            
          }

          localStorage.removeItem('paymentIntent'); 

          this.router.navigateByUrl("/");
        
        },(error)=>{
          this.loading = false;
          
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error
          });
          
        }
        
        );
  }

}
