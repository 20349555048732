<div class="row animated fadeIn fast">

    <div class="col-md-5">
        <div class="card">
            <div class="card-body">

                <form #formBusqueda="ngForm">

                    <div class="input-group">
                        <input type="text" [(ngModel)]="terminoBusqueda" name="terminoBusqueda" class="form-control"
                            placeholder="Escribe para buscar..." autocomplete="off">
                        <span class="input-group-btn">
                            <button class="btn btn-info" type="submit" (click)="buscar(formBusqueda.value)">
                                Buscar
                            </button>
                        </span>
                    </div>
                </form>
                <br>
                <div class="col-md-12 text-left styleTypeView">
                    <select 
                        [(ngModel)] ="typeView"
                        class="custom-select custom-select-sm"
                        (change)="cambiarTipoDeVista($event.target.value)"
                    >
                        <option value="todos">Todos</option>
                        <option value="top">Top 5</option>
                    </select>
                </div>
                <br>
                <div *ngIf="!cargando && typeView =='top' " class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr class="table-info">
                                <th>Nombre</th>
                                <th>Descripción</th>
                                <th>Agregar</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="productosData.length>0">
                            <tr *ngFor="let producto of productosData">
                                <td>
                                    <div class="col-12">
                                        <h5>{{producto.nombre}}</h5>
                                        <h6 class="card-subtitle"><span class="tiempoActual"><strong>
                                                    ${{producto.precio}}</strong></span> - Stock: {{producto.cantidad}}
                                            pzs</h6>
                                        <h6 class="card-subtitle"><span class="rojo my-f-s-12">Cad:
                                                {{producto.fechaCaducidad | date: 'dd/MM/yyyy'}}</span></h6>
                                    </div>
                                </td>
                                <td>
                                    <h6 class="card-subtitle">
                                        {{producto.descripcion}}
                                    </h6>
                                </td>
                                <td class="text-center">
                                    <button
                                        class="btn btn-circle btn-success"
                                        (click)="agregarProducto(producto)"
                                    >
                                    <i class="fa fa-plus"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="productosData.length<=0 && !cargando">
                            <tr>
                                <td colspan="8">
                            <div class="p-20 text-center">
                                No hay resultados de la busqueda
                            </div>

                            </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="!cargando && typeView =='todos' " class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr class="table-info">
                                <th>Nombre</th>
                                <th>Descripción</th>
                                <th>Agregar</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="productosData.length>0">
                            <tr *ngFor="let producto of productosData">
                                <td>
                                    <div class="col-12">
                                        <h5>{{producto.nombre}}</h5>
                                        <h6 class="card-subtitle"><span class="tiempoActual"><strong>
                                                    ${{producto.precio}}</strong></span> - Stock: {{producto.cantidad}}
                                            pzs</h6>
                                        <h6 class="card-subtitle"><span class="rojo my-f-s-12">Cad:
                                                {{producto.fechaCaducidad | date: 'dd/MM/yyyy'}}</span></h6>
                                    </div>
                                </td>
                                <td>
                                    <h6 class="card-subtitle">
                                        {{producto.descripcion}}
                                    </h6>
                                </td>
                                <td class="text-center">
                                    <button
                                        class="btn btn-circle btn-success"
                                        (click)="agregarProducto(producto)"
                                    >
                                    <i class="fa fa-plus"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="productosData.length<=0 && !cargando">
                            <tr>
                                <td colspan="8">
                            <div class="p-20 text-center">
                                No hay resultados de la busqueda
                            </div>

                            </td>

                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="clearfix"></div>
                <div *ngIf="!cargando && typeView =='todos' " class="top-20 col-lg-12 col-md-12">                          

                    <mi-paginador
                        *ngIf="!buscandoPorNombre" 
                        (cargaData)="seleccionaItemsPorPagina($event)"
                        (numeroDePaginaEmit)="seleccionaPagina($event)"
                        [totalPaginasArray]="totalPaginasArray || 0"    
                        [itemsPorPaginaInput]="itemsPorPagina"    
                        [numeroDePaginaInput]="numeroDePagina || 0"    
                        [desdeInput]="desde || 0"    
                        [hastaInput]="hasta || 5"    
                        >
                    </mi-paginador>
                
                </div>

                <div *ngIf="cargando" class="row animated fadeIn fast">
                    <div class="col-12">
                        <div class="alert alert-info text-center" role="alert">
                            <h4>Cargando</h4>
                            <i class="fa fa-spin fa-refresh fa-2x"></i>
                            <p class="mb-0"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-7">
        <div class="card">
            <div class="card-body">
                <form [formGroup]="ventaProductoForm" (ngSubmit)="confirmarVenta()"
                    class="tab-wizard wizard-circle wizard clearfix" role="application">

                    <h2 class="card-title text-center">
                        <i class="fa fa-shopping-bag"></i> Venta
                    </h2>
                    <br>
                    <div class="h350 table-responsive">

                        <div class="form-group row">
                            <div class="col-md-2">
                                Quitar
                            </div>      
                            <div class="col-md-4">
                                Producto
                            </div>

                            <div class="col-md-3">
                                Cantidad
                            </div>
                            <div class="col-md-3">
                                Subtotal
                            </div>
                        </div>


                        <div 
                            formArrayName="productos"
                            *ngFor="let producto of productos.controls; let i = index"
                            >
                            <div [formGroupName]="i">
                                <div class="form-group row">
                                    <div class="col-md-2">
                                        <button
                                            class="btn btn-circle btn-danger"
                                            (click)="quitarProducto(i)"
                                        >
                                            <i class="fa fa-trash-o"></i>
                                        </button>
                                    </div>
                                    <div class="col-md-4">
                                        <h5>{{producto.value.nombre}}</h5>
                                        <h6 class="card-subtitle">
                                            <span class="tiempoActual">
                                                <strong>
                                                    ${{producto.value.precio}}
                                                </strong>
                                            </span> - Stock: {{producto.value.stock}} pzs
                                        </h6>
                                    </div>

                                    <div class="col-md-3">
                                        <input 
                                            type="number" 
                                            [attr.id]="'cantidad' + i"
                                            class="form-control"
                                            formControlName="cantidad"
                                            (ngModelChange)="agregaProductoACarrito($event, producto.value.stock)"   
                                        >
                                    </div>
                                    <div class="col-md-3">
                                        ${{producto.value.precio * producto.value.cantidad }}
                                    </div>
                                </div>
                            </div>                        
                        </div>

                    </div>

                    <div class="text-center top-20">
                        <h1>Total: <strong><span class="azulFuerte">{{total | currency:'USD':'symbol':'1.2-2'}}</span></strong></h1>
                    </div>
                    <br>
                    <div class="text-center my-p-lr-50">
                        <button 
                            *ngIf="!isSubmit"
                            type="submit"
                            class="btn btn-rounded btn-block btn-success"
                            [disabled]="!listoParaVenta"
                            >
                            Confirmar venta
                        </button>
                        <button
                            *ngIf="isSubmit"    
                            type="button"
                            class="btn btn-rounded btn-block btn-success"
                            disabled
                        >
                            <i class="fa fa-spin fa-refresh fa-1x"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</div>