import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { AppRoutingModule } from '../app-routing.module';



import { SharedModule } from '../shared/shared.module';
import { ComponentsModule } from '../components/components.module';
import { PipesModule } from '../pipes/pipes.module';

import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { PromesasComponent } from './promesas/promesas.component';
import { RxjsComponent } from './rxjs/rxjs.component';
import { PerfilComponent } from './perfil/perfil.component';
import { BusquedaComponent } from './busqueda/busqueda.component';
import { SociosComponent } from './socios/socios.component';
import { ModalAgregarSocioComponent } from './socios/modal-agregar-socio/modal-agregar-socio.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { CrearMembreciasComponent } from './membrecias/crear-membresias/crear-membrecias.component';
import { MembresiasComponent } from './membrecias/membresias.component';
import { DatosSocioComponent } from './socios/shared/datos-socio/datos-socio.component';
import { VentaMembresiaComponent } from './membrecias/shared/venta-membresia/venta-membresia.component';
import { DetalleSocioComponent } from './socios/detalle-socio/detalle-socio.component';


import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from '../interceptors/interceptor.service';
import { UsuarioService } from '../services/usuario.service';
import { VentasComponent } from './ventas/ventas.component';
import { ModalProductoComponent } from './productos/modal-producto/modal-producto.component';
import { ModalStockComponent } from './productos/modal-stock/modal-stock.component';
import { ProductosComponent } from './productos/productos.component';
import { CorteComponent } from './corte/corte.component';
import { CorteMembresiasComponent } from './corte/corte-membresias.component';
import { SociosVentasComponent } from './socios-ventas/socios-ventas.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { ModalAgregarUsuarioComponent } from './usuarios/modal-agregar-usuario/modal-agregar-usuario.component';
import { DatosUsuarioComponent } from './usuarios/shared/datos-usuario/datos-usuario.component';
import { ModalVerPermisosComponent } from './usuarios/modal-ver-permisos/modal-ver-permisos.component';
import { CheckAsistenciasComponent } from './asistencias/check-asistencias/check-asistencias.component';
import { AsistenciasGeneralesComponent } from './asistencias/asistencias-generales/asistencias-generales.component';
import { ModalPaymentsComponent } from './payments/modal-payments/modal-payments.component';
import { PasarelaComponent } from './payments/pasarela/pasarela.component';
import { AdministradoresComponent } from './administradores/administradores.component';
import { ModalAgregarAdminComponent } from './administradores/modal-agregar-admin/modal-agregar-admin.component';
import { DatosAdminComponent } from './administradores/datos-admin/datos-admin.component';
import { GastosComponent } from './gastos/gastos.component';
import { ModalGastosComponent } from './gastos/modal-gastos/modal-gastos.component';
import { CategoriasComponent } from './categorias/categorias.component';




@NgModule({
  declarations: [
    DashboardComponent,
    PagesComponent,
    AccountSettingsComponent,
    PromesasComponent,
    RxjsComponent,
    PerfilComponent,
    UsuariosComponent,
    AdministradoresComponent,
    ModalAgregarAdminComponent,
    DatosAdminComponent,
    BusquedaComponent,
    SociosComponent,
    SociosVentasComponent,
    ModalAgregarSocioComponent,
    ModalAgregarUsuarioComponent,
    ServiciosComponent,
    CrearMembreciasComponent,
    MembresiasComponent,
    DatosSocioComponent,
    VentaMembresiaComponent,
    DetalleSocioComponent,
    VentasComponent,
    ModalProductoComponent,
    ProductosComponent,
    ModalStockComponent,
    CorteComponent,
    CorteMembresiasComponent,
    DatosUsuarioComponent,
    ModalVerPermisosComponent,
    CheckAsistenciasComponent,
    AsistenciasGeneralesComponent,
    ModalPaymentsComponent,
    PasarelaComponent,
    GastosComponent,
    ModalGastosComponent,
    CategoriasComponent

  ],
  exports: [
    DashboardComponent,
    PagesComponent,
    AccountSettingsComponent,
    SociosComponent,
    ModalAgregarSocioComponent,
    ModalAgregarUsuarioComponent,
    ServiciosComponent,
    CrearMembreciasComponent,
    MembresiasComponent,
    DatosSocioComponent,
    DatosUsuarioComponent,
    VentaMembresiaComponent,
    DetalleSocioComponent,
    ModalVerPermisosComponent,
    CheckAsistenciasComponent,
    AsistenciasGeneralesComponent,
    ModalPaymentsComponent,
    PasarelaComponent,
    AdministradoresComponent,
    ModalAgregarAdminComponent,
    DatosAdminComponent,
    GastosComponent,
    ModalGastosComponent,
    CategoriasComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    RouterModule,
    ComponentsModule,
    ReactiveFormsModule,
    PipesModule
    
    // AppRoutingModule
  ],
  // providers:[
  //   {
  //     provide:HTTP_INTERCEPTORS,
  //     useClass:InterceptorService,
  //     multi:true
  //   }
  // ]
})
export class PagesModule { }
