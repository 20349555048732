<div id="main-wrapper">
    <app-header></app-header>
    <app-siderbar></app-siderbar>
        <div class="page-wrapper">
            <div class="container-fluid">
                <div
                    [class.oculto]="gymService.ocultarAlertGetter"
                    class="alert " 
                    [ngClass]="{
                        'alert-info': diasRestantes > 5 && diasRestantes <= 10,
                        'alert-warning': diasRestantes <= 5 && diasRestantes > 0,
                        'alert-danger': diasRestantes <= 0
                        }"
                >
                    <button type="button" class="close cursorpointer" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                    <h3 
                    [ngClass]="{
                        'text-info': diasRestantes > 5 && diasRestantes <= 10,
                        'text-warning': diasRestantes <= 5 && diasRestantes > 0,
                        'text-danger': diasRestantes <= 0
                        }"
                        >
                        <i class="fa "
                        [ngClass]="{
                            'fa-exclamation': diasRestantes > 5 && diasRestantes <= 10,
                            'fa-exclamation-circle': diasRestantes <= 5 && diasRestantes > 0,
                            'fa-exclamation-triangle': diasRestantes <= 0
                            }"
                        ></i> 
                        {{ diasRestantes > 0 ? textWarning : textDanger}}
                    </h3> 
                    <span *ngIf="role == 'ROLE_ADMIN'">
                        {{textSuscription}} <a class="cursorpointer" routerLink="/dashboard/acount-settings">configuración</a> o da clic <span class="hereButton cursorpointer" (click)="goToPay()">aquí</span>
                    </span>
                    <span *ngIf="role == 'ROLE_MANDATED'">
                        {{textEncargado}}
                    </span>
                </div>
                <app-breadcrumbs></app-breadcrumbs>
                <!-- sistema de rutas -->
                <router-outlet></router-outlet>
                <footer class="footer">
                    © 2024 UNIFIT
                </footer>
            </div>
        </div>
</div>

<app-modal-img></app-modal-img>

<!-- <div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <h4 class="card-title">Striped Progress bar</h4>

            </div>
        </div>
    </div>
</div> -->
