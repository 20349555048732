<div class="fondo-modal-imagen animated fadeIn fast" [class.oculto]="gastosService.ocultarModalGastos" >
    
    <div id="modalAgregarGasto" class="modal fade bs-example-modal-lg show" tabindex="-1" role="dialog" aria-labelledby="myModalAddGastoLabel" style="display: block; padding-right: 16px;">
        
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 *ngIf="!modoEditar" class="modal-title" id="myModalAddGastoLabel">Agregar gasto</h4>
                    <h4 *ngIf="modoEditar" class="modal-title" id="myModalAddGastoLabel">Editar gasto</h4>
                </div>
                <div class="modal-body">
                    <div class="card-body wizard-content customBody">

                        <form 
                            [formGroup]="creaProductoForm"    
                            (ngSubmit)="crearGasto()" 
                            class="tab-wizard wizard-circle wizard clearfix" 
                            role="application"
                        >
                            <div class="content clearfix">
                            <!-- Step 1 -->                                
                                <div class="row">
                                       
                                    <div class="col-md-4 col-sm-6">
                                        <div class="form-group">
                                            
                                            <div class="text-center">
                                                <img *ngIf="!imgPreview" src="../../../../assets/images/foto-not-found-black.jpg" class="my-foto img-fluid" alt="">
                                                <img *ngIf="imgPreview" [src]=imgPreview class="my-foto img-fluid" alt="">
                                            </div>
                                                
                                            <div class="text-center">
                                                <label for="idSelectFoto" class="mylabelFoto">
                                                    <div *ngIf="!loading" type="button" class="btn btn-info btn-sm btn-block">
                                                        <i class="ti-settings text"></i>
                                                        <span class="text"> Selecciona comprobante </span>
                                                    </div>
                                                </label>
                                                &nbsp;
                                                <button (click)="quitarImagen()" type="button" *ngIf="!loading" class="btn btn-sm btn-outline-secondary">
                                                    <i class="fa fa-trash-o"></i>
                                                </button>
                                            </div>
                    
                    
                                            <div *ngIf="loading" type="button" class="myloading animated fadeIn fast btn btn-secondary btn-sm btn-block">
                                                <i class="fa fa-spin fa-refresh fa-1x"></i>
                                                <span class="text"> Cargando ... </span>
                                            </div>
                                            <input 
                                                type="file"
                                                (change)="cambiarImagen($event)"  
                                                id="idSelectFoto"
                                            >       
                                        </div>
                                    </div>
                    
                                    <div class="col-md-8 col-sm-6">
                                        <div class="row">
                    
                                            <div class="col-md-12 col-sm-12">
                                                <div class="form-group">
                                                    <label for="codigo">*Descripción:</label>
                                                    <textarea 
                                                        formControlName="descripcion"
                                                        class="form-control" 
                                                        id=""  
                                                        rows="5"
                                                        placeholder="Escribe una descripción del gasto realizado ..."
                                                    ></textarea>
                                                    <div 
                                                            *ngIf="descripcion.invalid && (descripcion.dirty || descripcion.touched)" 
                                                            class="myalert" 
                                                            role="alert"
                                                            >
                                                            <span>*Es necesario el campo descripción</span>
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-sm-12">
                                                <div class="form-group">
                                                    <label for="app">*Costo:</label>
                                                    <input 
                                                        type="number" 
                                                        class="form-control floating-labels" 
                                                        id="montoid"
                                                        formControlName="monto"
                                                        autocomplete="off"
                        
                                                    >
                        
                                                        <div 
                                                            *ngIf="monto.invalid && (monto.dirty || monto.touched)" 
                                                            class="myalert" 
                                                            role="alert"
                                                            >
                                                            <span>*Es necesario el campo costo</span>
                                                        </div>
                                                </div>
                                            </div>
                                                                    
                                            <div class="col-md-6 col-sm-12">
                                                <div class="form-group">
                                                    <label for="codigo">*Fecha de gasto:</label>
                                                    <input 
                                                        type="date"
                                                        formControlName="fecha"
                                                        class="form-control" 
                                                        autocomplete="off"
                                                        autofocus
                                                    >
                                                    <div 
                                                        *ngIf="fecha.invalid && (fecha.dirty || fecha.touched)" 
                                                        class="myalert" 
                                                        role="alert"
                                                        >
                                                        <span>*Es necesario el campo fecha</span>
                                                    </div>    
                                                    
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-sm-12">
                                                <div class="form-group">
                                                    <label for="codigo">*Categoría:</label>
                                                
                                                    <select 
                                                        class="form-control custom-select" 
                                                        formControlName="idCategoria"
                                                    >
                                                        <option *ngFor="let categoria of categoriasData" [value]="categoria.id">{{ categoria.nombre }}</option>
                                                    </select>
                                                    <div 
                                                        *ngIf="idCategoria.invalid && (idCategoria.dirty || idCategoria.touched)" 
                                                        class="myalert" 
                                                        role="alert"
                                                        >
                                                        <span>*Es necesario seleccionar una categoría</span>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                                               
                                        </div>
                                    </div>
                                </div>                                

                            </div>
                        
                            <div class="actions clearfix">
                    
                                <ul role="menu" aria-label="Pagination">
                    
                                    <li>
                                        <button 
                                            type="button"
                                            (click)="cerrarModal()"
                                            class="btn btn-inverse waves-effect text-left"
                                            
                                            >
                                            Cancelar
                                        </button>
                                        &nbsp;
                                        <button
                                            *ngIf="!loading"
                                            [disabled]="creaProductoForm.invalid"
                                            class="btn btn-info" 
                                            type="submit" 
                                            role="menuitem"
                                        >
                                            Guardar
                                        </button>

                                        <button 
                                            *ngIf="loading"
                                            type="button" 
                                            class="btn btn-info" 
                                            disabled
                                            >
                                            <i class="fa fa-spin fa-refresh fa-1x"></i> Esperando ...
                                        </button>
                                    </li>                                    
                                </ul>
                            </div>
                    
                    </form>
                    </div>                    
                                     

                </div>                
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>    
    



</div>
 