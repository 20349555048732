<div class="fondo-modal-imagen animated fadeIn fast" [class.oculto]="usuariosService.ocultarModalPermisos">

    <div id="modalPermisos" class="modal fade bs-example-modal-lg show" tabindex="-1" role="dialog"
        aria-labelledby="myModalPermisos" style="display: block; padding-right: 16px;">

        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalPermisosLabel">Permisos de {{nombre}}</h4>
                </div>
                <div class="modal-body">
                    <section id="steps-uid-0-p-2" role="tabpanel" aria-labelledby="steps-uid-0-h-2" class="body"
                        aria-hidden="true">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <table class="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Sección</th>
                                                    <th class="text-nowrap text-center">Estatus</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let permiso of permisos">
                                                    <td>{{permiso.nombre}}</td>
                                                    <td class="text-center">
                                                        <div class="switch">
                                                            
                                                            <label>
                                                                <input type="checkbox" [checked]="permiso.estatus" disabled><span
                                                                    class="lever switch-col-light-blue"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>

                            </div>



                        </div>
                    </section>

                </div>
                <div class="modal-footer">
                    <button type="button" (click)="cerrarModal()" class="btn btn-info waves-effect">Cerrar</button>
                </div>

            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>




</div>