import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MembreciasService } from 'src/app/services/membrecias.service';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import { SociosService } from 'src/app/services/socios.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'detalle-socio',
  templateUrl: './detalle-socio.component.html',
  styleUrls: ['./detalle-socio.component.css']
})
export class DetalleSocioComponent implements OnInit {

  public idSocioInput:Object=null;
  public dataSocio:any = {
    urlImgThumbnail:null
  }
  public fechaActual = new Date();
  public anioActual:any= this.fechaActual.getFullYear();
  public mesActual:any=this.fechaActual.getMonth();
  public anioAnterior:number=this.fechaActual.getFullYear()-1;
  public anioPosterior:number=this.fechaActual.getFullYear()+1;
  public diasRestantes:number = 0;
  public nombreMes:string = '';
  public mesElegido:any = null;
  public yearElegido:any = null;
  public meses:string[]=['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre'];
  public asistenciasData:any[] = [];
  public membresiasData:any[] = [];
  public eliminarActive:boolean = true;
  public isDisabled:boolean = true;
  public textoAverificar:string = '';
  public frase:string = null;
  public dataUltimaMembresia:any = {
      folio:'000000'
  }
  /*
    Esto se hizo asi para evitar mostrar un error en consola ya que la 
    vista carga mas rapido de lo que responde el servicio podria quedar como:
    public dataUltimaMembresia:any = null;
  */
  constructor(
    private membreciasService:MembreciasService,
    private sociosService:SociosService,
    private mensajesSwalService:MensajesSwalService,
    private activateRoute:ActivatedRoute,
    private router:Router,
    public utilsService: UtilsService


  ) { }


  ngOnInit(): void {
    this.yearElegido = this.anioActual;
    this.mesElegido = this.mesActual;
    this.nombreMes = this.meses[this.mesActual];
    this.activateRoute.params.subscribe(({id})=>{
      this.obtenerSocio(id);
    })
  }


  reimprimirTicket(membresia){
    console.log(membresia);

    this.membreciasService.obtenerticket(membresia.folio).subscribe(res=>{
      this.abrirPDFOtraVentana(res);

    }, (error) => {
     console.log(error);
    })

  }

  abrirPDFOtraVentana(res) {
    const blob = new Blob([res.body], { type: 'application/pdf' }),
      url = window.URL.createObjectURL(blob);
      window.open(url);
  }
  
  registraAsistencia(id){

    
    this.sociosService.registrarAsistencia(id).subscribe(()=>{
      this.mensajesSwalService.registrarAsistencia();
      this.obtenerAsistencias()
    })

  }
  obtenerUltimaMembresia(id){
    this.membreciasService.obtenerUltimaMembresia(id).subscribe(res=>{
      

      if (res) {
        this.dataUltimaMembresia = res.ultimaMembresia;
      }
    },(err)=>{
      console.log(err);
    })
  }

  obtenerMembresiasCompradas(){
    this.membreciasService.obtenerMembresiasCompradas(this.dataSocio.id).subscribe(res=>{
      
      this.membresiasData = res.data;
      
    })
  }

  obtenerAsistencias(){
    this.sociosService.obtenerAsistencias(this.dataSocio.id, this.anioActual, this.mesActual+1).subscribe(res=>{
      this.asistenciasData = res;     
      
    })
  }

  obtenerSocio(id){
    this.sociosService.obtenerSocio(id).subscribe(({socio})=>{
      this.dataSocio = socio;
      this.diasRestantes = socio.dias;
      this.obtenerUltimaMembresia(this.dataSocio.id)
      this.frase = 'Dar de baja al socio seleccionado';
    })
  }

  abrirModal(tipoDeModal, socio){
    
    if (tipoDeModal == 'agregar' ) {
      this.sociosService.abrirModalAgregarSocio()
    }else if (tipoDeModal == 'editar') {
      
      this.idSocioInput = {
                            socioID: socio.id,
                            operacion:'editar'

                          }

      this.sociosService.abrirModalAgregarSocio()

    }else if (tipoDeModal == 'comprar') {
      
      this.idSocioInput = {
        socioID: socio.id,
        operacion:'comprar'

      }

      this.sociosService.abrirModalAgregarSocio()

      
    }
    
  }

  eliminarSocio(){
    this.eliminarActive = false
  }

  confirmarEliminar(){
    this.sociosService.eliminaSocio(this.dataSocio.id).subscribe(res =>{
      this.mensajesSwalService.eliminadoConExito();
      this.router.navigateByUrl('/dashboard/socios');

    }, (err)=>{
      this.mensajesSwalService.error500(err);
    })
    
  }

  cancelarEliminar(){
    this.textoAverificar = '';
    this.isDisabled = true;
    this.eliminarActive = true;
  }

  verificaTexto(){

    if (this.textoAverificar != this.frase) {
      this.isDisabled = true;
      
    }else{
      this.isDisabled = false;      
    }
        
  }
  eliminarMembresia(membresia){
    

    Swal.fire({
      title: '¿Eliminar venta de membresía?',
      text:'Esta operacion se reflejara en el corte de caja',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#E5B700',
      cancelButtonColor: '#717171',
      cancelButtonText:'No',
      confirmButtonText: 'Si'
    }).then((result) => {

        if (result.isConfirmed) {

          

          this.membreciasService.eliminarVentaMembresia(membresia.id).subscribe(()=>{
      
            this.mensajesSwalService.eliminadoConExito();
            this.obtenerMembresiasCompradas();
          })

        }        
     })

  }
  editarSocio(){

    this.idSocioInput = {
      socioID: this.dataSocio.id,
      operacion:'editar'
    }

    this.sociosService.abrirModalAgregarSocio()

    // this.editarSocio.emit(this.dataSocio);
  }

  cambiaMes(e){

    const index = this.meses.indexOf(e.target.value)
    this.mesElegido=index;
    this.obtenerAsistenciasConfiltrado();

  }

  cambiaYear(e){
    this.yearElegido=e.target.value
    this.obtenerAsistenciasConfiltrado();
  }

  obtenerAsistenciasConfiltrado(){
    this.sociosService.obtenerAsistencias(this.dataSocio.id, this.yearElegido, this.mesElegido+1).subscribe(res=>{
      this.asistenciasData = res
    })
  }

}

