<ul class="pagination justify-content-end">
    <li class="page-item">
        <select 
            class="form-control" 
            (change)="seleccionaItemsPorPagina($event)"
            >
            <option value="{{itemsPorPagina}}">{{itemsPorPagina}}</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
        </select>
    </li>
    <li class="page-item cursorpointer">
        <span 
            (click)="calculaIndiceDePaginas(-5)" 
            class="page-link"
            [class.active]="estaActivoAnterior"
            >Anterior
        </span>
    </li>
    <div
        [class.active]="pagina==numeroDePagina"  
        class="page-item cursorpointer" 
        *ngFor="let pagina of totalPaginasArray | slice:desde:hasta"
        >

        <li>
            <span
                class="page-link" 
                (click)="seleccionaPagina(pagina)"
                >
                {{pagina+1}}</span>
        </li>
    </div>

    <li class="page-item cursorpointer">
        <span 
            (click)="calculaIndiceDePaginas(5)" 
            class="page-link"
            [class.active]="estaActivoSiguiente"
            >Siguiente
        </span>
    </li>
</ul>