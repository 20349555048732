import Swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { Servicio } from 'src/app/interfaces/servicio.interface';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import { ServiciosGymService } from 'src/app/services/servicios-gym.service';
import { GastoService } from 'src/app/services/gastos.service';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styles: [
  ]
})
export class CategoriasComponent implements OnInit {

  public servicioTxt:string
  public cargando:boolean=true;
  public desde:number=0;
  public categoriasData:Servicio[]=[];
  public backUpcategoriasData:Servicio[]=[];
  public nombre:string
  public descripcion:string="Ejercicio"
  public servicio:any = {
    nombre: '',
    descripcion:'Ejercicio'
  }


  constructor(
    private gastosService:GastoService,
    // private serviciosGymService:ServiciosGymService,
    public mensajesSwalService:MensajesSwalService,

  ) { }

  ngOnInit(){
  
    this.cargarCategorias()
  
  }

  buscarServicio(servicio){
    if (servicio=='') {
      // this.cargarCategorias();
      this.categoriasData = this.backUpcategoriasData
      this.cargando = false;

    }else{
        this.categoriasData = this.backUpcategoriasData.filter(function (item:any) {          
          return item.nombre.toLowerCase().includes(servicio.toLowerCase());
        });
        this.cargando = false;
    }
    
    
  }
  async agregar(){

    try {
      await Swal.fire({
        title:'Escribe nombre para la categoría',
        input: 'text',
        inputAutoTrim:true,
        inputValue: '',
        inputPlaceholder: 'Escribe nombre ...',
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'El campo es obligatorio'
          }

        const data = {
          nombre:value,
          descripcion:""
        }

        this.gastosService.crearCategoria(data).subscribe(()=>{
          this.servicio.nombre = '';
          this.mensajesSwalService.agregadoConExito('Categoria','a')
          this.cargarCategorias();      
          
          })
          
          }
      })
      
    } catch (error) {
      console.error(error);
    }

      


  }


  cargarCategorias(){
    this.cargando=true;

    this.gastosService.obtenerCategorias(0, 100)
    .subscribe(({categorias})=>{
      
      this.categoriasData = categorias;
      this.backUpcategoriasData = categorias;
      this.cargando=false;
      
    })
  }

  eliminarCategoria(id){

    Swal.fire({
      title: '¿Eliminar categoría?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#E5B700',
      cancelButtonColor: '#717171',
      cancelButtonText:'No',
      confirmButtonText: 'Si'
    }).then((result) => {
        if (result.isConfirmed) {
            this.gastosService.eliminarCategoria(id).subscribe((res:any)=>{

              const isDeleted = res.data;
                if (isDeleted) {
                  this.mensajesSwalService.eliminadoConExito();
                  this.cargarCategorias();
                } else {
                  this.mensajesSwalService.categoriaAsignada();
                }              
            },(err)=>{
              this.mensajesSwalService.error500(err);
            })
        }        
     })
    
  }


  async editarCategoria(categoria){


    const { value='' } = await Swal.fire({
       title:'Escribe el nuevo nombre para "' +categoria.nombre + '"',
       input: 'text',
       inputAutoTrim:true,
       inputValue: categoria.nombre,
       inputPlaceholder: 'Escribe nombre ...',
       showCancelButton: true,
       inputValidator: (value) => {
         if (!value) {
           return 'Debes escribir un nombre para "' +categoria.nombre + '"'
         }

        const data= {
          nombre:value,
          descripcion:""
        }

        this.gastosService.modificarCategoria(categoria.id, data).subscribe(()=>{

          this.mensajesSwalService.modificadoConExito('Categoria','a');
          this.cargarCategorias();

        },(error)=>{
          this.mensajesSwalService.error500(error);
        })
        
       }
     })
 
 
   }


}
