<div class="body-asistencias">
    <div class="row">

        <div class="col-md-12 title-asistencias">
        </div>

        <div class="clearfix"></div>

        <div *ngIf="dataSocio.folio != null" class="col-md-6 col-lg-6 col-xlg-6 content-card-user text-center">
            <div class="card card-body">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="row">
                            <div class="col-md-4 col-lg-4 text-center">
                                <img 
                                    *ngIf="dataSocio.urlImgThumbnail!=null" 
                                    [src]="dataSocio.urlImg" 
                                    src="../assets/images/users/1.jpg" 
                                    alt="user" 
                                    class="img-circle img-responsive"
                                >
                                <img 
                                    *ngIf="dataSocio.urlImgThumbnail==null" 
                                    src="../assets/images/foto-not-found-black.jpg" 
                                    alt="user" 
                                    class="img-circle"
                                    width="220"
                                    height="230"
                                >
                                <!-- <img src="../assets/images/users/1.jpg" alt="user" class="img-circle img-responsive"> -->
                            </div>

                            <div class="col-md-8 col-lg-8 text-center">
                                <h1 class="box-title m-b-0 m-t-40">{{dataSocio.nombres}} {{dataSocio.apellidoPaterno}} {{dataSocio.apellidoMaterno}}</h1> 
                                <h4 class="m-t-20">Número de socio: {{dataSocio.folio}}</h4>
                            </div>
                         </div>

                        <div class="row m-t-30">
                            <div class="col-lg-6 col-md-6">
                                <div class="card bg-success">
                                    <div class="card-body">
                                        <div class="d-flex no-block">
                                            <div class="m-r-20 align-self-center">
                                                <i class="icons-resume fa fa-calendar"></i>
                                            </div>
                                            <div class="align-self-center">
                                                <h6 class="text-white m-t-10 m-b-0">Fecha de inicio</h6>
                                                <h4 *ngIf="dataSocio.fechaInicioMembrecia == null" class="m-t-0 text-white"> - - - - - - </h4>
                                                <h4 *ngIf="dataSocio.fechaInicioMembrecia != null" class="m-t-0 text-white">{{dataSocio.fechaInicioMembrecia | date: 'dd/MM/yyyy'}}</h4>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="card bg-warning">
                                    <div class="card-body">
                                        <div class="d-flex no-block">
                                            <div class="m-r-20 align-self-center">
                                                <i class="icons-resume fa fa-calendar-o"></i>
                                            </div>
                                            <div class="align-self-center">
                                                <h6 class="text-white m-t-10 m-b-0">Fecha de término</h6>
                                                <h4 *ngIf="dataSocio.fechaFinMembrecia==null" class="m-t-0 text-white"> - - - - - - </h4>
                                                <h4 *ngIf="dataSocio.fechaFinMembrecia!=null" class="m-t-0 text-white">{{dataSocio.fechaFinMembrecia | date: 'dd/MM/yyyy'}}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 m-t-10">
                                <div 
                                    class="card" 
                                    [ngClass]="{
                                    'bg-info': dataSocio.dias > 10,
                                    'bg-warning': dataSocio.dias <= 10 && dataSocio.dias > 5,
                                    'bg-danger': dataSocio.dias <= 5
                                    }"
                                    >
                                    <div class="card-body contentDays">
                                        <div class="d-flex no-block">
                                            <div class="m-r-40 align-self-center"><i class="icons-resume fa fa-exclamation-circle"></i></div>
                                            <div class="align-self-center ">
                                                <h2 class="text-white m-t-10 m-b-0">Dias restantes</h2>
                                                
                                                
                                                <strong> <h1 *ngIf="dataSocio.dias==null" class="m-t-0 text-white">0</h1> </strong>
                                                <strong> <h1 *ngIf="dataSocio.dias<=0" class="m-t-0 text-white">0</h1> </strong>
                                                <strong> <h1 *ngIf="dataSocio.dias>0" class="m-t-0 text-white">{{dataSocio.dias}}</h1> </strong>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <small><p class="info text-left"><i class="fa fa-info-circle"></i> Si los datos no pertenecen a tu cuenta solo presiona la tecla <b>F5</b> o <b>recarga la página</b> </p></small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 contentButton">
                            <button
                                *ngIf="dataSocio.fechaInicioMembrecia!=null && dataSocio.estatus=='ACTIVO' && !loadingConfirm"  
                                (click)="confirmarAsistencia()"
                                type="button" 
                                class="btn waves-effect waves-light btn-lg btn-rounded btn-success"
                            >
                                Confirmar asistencia
                            </button>
                            <button 
                                *ngIf="loadingConfirm"
                                disabled
                                type="button" 
                                class="btn waves-effect waves-light btn-lg btn-rounded btn-success"
                                >
                                <i class="fa fa-spin fa-refresh fa-1x"></i> Registrando ...
                            </button>
                </div>
            </div>



        </div>
    </div>
</div>