import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class GastoService {

  public resource:string = 'api_gastos';
  private _ocultarModalGastos:boolean=true;
  private _ocultarModalStock:boolean=true;

  constructor(
    private http:HttpClient
  ) { }

  get token():string{
    return localStorage.getItem('token') || '';
  }

  get headers(){
    return{
            headers:{
              "Authorization": `Bearer ${this.token}`
            }
         } 
   }

   get headersTopdf(){
    return{
            headers:new HttpHeaders({
              'Content-Type':  'application/json',
              responseType : 'blob',
              Accept : 'application/pdf',
              observe : 'response'
              }),
         } 
   }

  get ocultarModalGastos(){
    return this._ocultarModalGastos;
  }

  abrirModalGastos(){
     this._ocultarModalGastos = false;
  }
  cerrarModalGastos(){
    this._ocultarModalGastos = true;
  }
  
  get ocultarModalStock(){
    return this._ocultarModalStock;
  }

  abrirModalStock(){
     this._ocultarModalStock = false;
  }
  cerrarModalStock(){
    this._ocultarModalStock = true;
  }


  crearGastos(params:any){

      const url = `${base_url}/${this.resource}`;

        return this.http.post(url, params, this.headers).pipe(
          map((res:any)=>{
            
            // const res:number = res.data

                return {
                  res
                };
            
          })
        )
  }

  crearCategoria(params:any){

      const url = `${base_url}/${this.resource}/categorias`;

        return this.http.post(url, params, this.headers).pipe(
          map((res:any)=>{
            
                return {
                  res
                };
            
          })
        )
  }

  obtenerCategorias(numeroDePagina:number ,itemsPorPagina:number){

    const url = `${base_url}/${this.resource}/categorias/${numeroDePagina}/${itemsPorPagina}`;
    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const categorias = res.data
        const infoPaginacion = res.pageInfo
            
            return {
              categorias,
              infoPaginacion
            };
        
      })
    )

  }
  obtenerGastos(numeroDePagina:number ,itemsPorPagina:number, extraData:any){

    const url = `${base_url}/${this.resource}/filter/${numeroDePagina}/${itemsPorPagina}?fechaInicio=${extraData.fechaInicio}&fechaFin=${extraData.fechaFin}&idCategoria=${extraData.idsCategoria}`;
    

    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const extraInfo = res.extraInfo;
        const gastos = res.data;
        const infoPaginacion = res.pageInfo;
            
            return {
              gastos,
              infoPaginacion,
              extraInfo
            };
        
      })
    )

  }

  obtenerGastosTop(itemsTop:number){

    const url = `${base_url}/${this.resource}/venta/top/${itemsTop}`;

    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{
        return res.data        
      })
    )

  }

  obtenerGasto(id){
    
    const url = `${base_url}/${this.resource}/${id}`;
    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{
        const gasto = res.data
            
            return {
              gasto
            };
        
      })
    )

  }

  obtenerGastosPorNombre(nombre){
    
    const url = `${base_url}/${this.resource}/search?text=${nombre}`;
    
    return this.http.get(url, this.headers)
    .pipe(
      map((res:any)=>{
        const gastos:any[] = res
            
            return gastos;
        
      }),catchError(this.manejarError)
      
    )

  }

  manejarError(error:HttpErrorResponse){
    console.log(error);

    return throwError('Hubo un error en la peticion')
    
  }


  manejarError500(error:HttpErrorResponse){
    console.log(error);

    return throwError('Hubo un error, contacte al admin')
    
  }

  modificarGastos(id, formData){
    const url = `${base_url}/${this.resource}/${id}`;
    
    return this.http.put(url, formData, this.headers);

  }
  modificarCategoria(id, formData){
    const url = `${base_url}/${this.resource}/categorias/${id}`;
    
    return this.http.put(url, formData, this.headers);

  }

  eliminarGastos(id:number){
    const url = `${base_url}/${this.resource}/${id}`;
    return this.http.delete(url, this.headers).pipe(
      map((res:any)=>{
            return res;
      }),catchError(this.manejarError500)
      
    )
  }

  eliminarCategoria(id:number){
    
    const url = `${base_url}/${this.resource}/categorias/${id}`;
    return this.http.delete(url, this.headers).pipe(
      map((res:any)=>{
        
            return res;
      }),catchError(this.manejarError500)
      
    )
  }
  eliminarVentaGastos(id:number){
    const url = `${base_url}/${this.resource}/venta/${id}`;
    return this.http.delete(url, this.headers).pipe(
      map((res:any)=>{
            return res;
      }),catchError(this.manejarError500)
      
    )
  }
  

  cambiaStock(id:number, params:any){
    const url = `${base_url}/${this.resource}/${id}`;
      return this.http.patch(url, params, this.headers).pipe(
        map((res:any)=>{
              return {
                res
              };
        }),catchError(this.manejarError500)
      )
  }

  ventaGastos(params){

      const url = `${base_url}/${this.resource}/venta/nuevo/ticket`;

        return this.http.post(url, params,{
          responseType: 'blob',
          observe: 'response',
          headers: new HttpHeaders(
            { 
              "Authorization": `Bearer ${this.token}`,
              "Accept" : 'application/pdf',
              'Content-Type':  'application/json'
          })
        });
  }

  obtenerVentaGastos(numeroDePagina:number, itemsPorPagina:number, params){
    
    const url = `${base_url}/${this.resource}/venta/corte/${numeroDePagina}/${itemsPorPagina}?fechaInicio=${params.fechaInicio}&&fechaFin=${params.fechaFin}`;
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const ventaGastos = res.data
        const infoPaginacion = res.pageInfo
        
            return {
              ventaGastos,
              infoPaginacion,
              res
            };
        
      })
    )
  }
  obtenerResumenVentaGastos(numeroDePagina:number, itemsPorPagina:number, params){
    const url = `${base_url}/${this.resource}/venta/corte/resumen/${numeroDePagina}/${itemsPorPagina}?fechaInicio=${params.fechaInicio}&&fechaFin=${params.fechaFin}`;
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const ventaGastos = res.data
        const infoPaginacion = res.pageInfo
        
            return {
              ventaGastos,
              infoPaginacion,
              res
            };
        
      })
    )
  }

  descargarCorteGastos(params){
    const url = `${base_url}/${this.resource}/venta/corte/pdf?fechaInicio=${params.fechaInicio}&&fechaFin=${params.fechaFin}`;
    return this.http.get(url, {
      responseType: 'blob',
      observe: 'response',
      headers: new HttpHeaders(
        { 
          "Authorization": `Bearer ${this.token}`,
          "Accept" : 'application/pdf',
          'Content-Type':  'application/json'
      })
    })
  }

} //fin
