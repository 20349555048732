import { Component, DoCheck, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Servicio } from 'src/app/interfaces/servicio.interface';
import { MembreciasService } from 'src/app/services/membrecias.service';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import { ServiciosGymService } from 'src/app/services/servicios-gym.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-membrecias',
  templateUrl: './crear-membrecias.component.html',
  styleUrls: ['./crear-membrecias.component.css']
})
export class CrearMembreciasComponent implements OnInit {

  public cargando:boolean=true;
  public serviciosData:Servicio[]=[];
  public serviciosDataClone:Servicio[]=[];
  public serviciosDataSeleccionados:any[]=[];
  public idServiciosSeleccionados:any[]=[];
  public desde:number=0;
  public dataSelected:boolean = false
  public objPeriodo:any
  public creaMembresiaFormSend:any={}
  public periodosObtenidos:any[]=[]
  public isDisabled:boolean=true
  public campoNombreAlert:boolean=true  
  public campoInscripcionAlert:boolean=false  
  public nombrePrecioNull:boolean=false
  public isEdit:boolean=false;
  public membresiaData:any=null;
  public idMembresia: number = null;
  public loading: boolean = false;
  public membresia:any = {
    nombreMembresia: '',
    inscripcionStatus: true,
    precioInscripcion: 0,
    periodos: [
      { 
        id: null,
        keyName: 'keyYear',
        key: 'YEAR',
        activo: false,
        precio: 0 
      },
      { 
        id: null,
        keyName: 'keyMonth',
        key: 'MONTH',
        activo: false,
        precio: 0 
      },
      { 
        id: null,
        keyName: 'keyQuincena',
        key: 'FORTNIGHT',
        activo: false,
        precio: 0 
      },
      { 
        id: null,
        keyName: 'keySemana',
        key: 'WEEK',
        activo: false,
        precio: 0 
      },
      { 
        id: null,
        keyName: 'keyDia',
        key: 'DAY',
        activo: false,
        precio: 0 
      },
  ],
  }

  constructor(
    private serviciosGymService:ServiciosGymService,
    private membreciasService:MembreciasService,
    public mensajesSwalService:MensajesSwalService,
    private router:Router,
    private activateRoute:ActivatedRoute

  ) {

  }

  ngOnInit(){
    this.idServiciosSeleccionados = [];
    this.validaAll();
    
    this.activateRoute.params.subscribe(({id})=>{
      this.idMembresia = id;

      if (id==undefined) {
        this.isEdit=false;
      }else{
        this.isEdit=true;
      }
      this.cargarServicios();
    })
  }
  

  async cargarServicios(){
    this.cargando=true;

    await this.serviciosGymService.obtenerServiciosPromise()
    .then(res=>{     
      this.serviciosData = res.data;
      this.serviciosDataClone = res.data;      
      this.cargando=false;      

      if (res.data.length != 0) {
        this.obtenerMembresia(this.idMembresia);
      }
      
    })
  }

  seleccionarServicio(servicio){

    const objId = {
        id:servicio.id
    }

    const index = this.serviciosData.indexOf(servicio);
    this.serviciosData.splice(index, 1);
    
    this.serviciosDataSeleccionados.push(servicio)
    this.idServiciosSeleccionados.push(objId)

    this.dataSelected=true
    this.validaAll();    
  }

  deseleccionarServicio(servicio){

    const index = this.serviciosDataSeleccionados.indexOf(servicio);
    this.serviciosDataSeleccionados.splice(index, 1);
    this.idServiciosSeleccionados.splice(index, 1);
    
    this.serviciosData.push(servicio)

    if (this.serviciosDataSeleccionados.length>0) {
      this.dataSelected=true
    }else{
      this.dataSelected=false
    }
    this.validaAll();    
  }

  validaCamposPrecio(nombrePeriodo) {
    const periodoIndex = {
      year: 0,
      month: 1,
      quincena: 2,
      semana: 3,
      dia: 4
    };
  
    const periodo = this.membresia.periodos[periodoIndex[nombrePeriodo]];
  
    if (periodo.precio === null || periodo.precio === undefined || periodo.precio < 0) {
      this.nombrePrecioNull = true;
      this.validaAll()
    } else {
      this.nombrePrecioNull = false;
      this.validaAll()
    }
  }
  
  validaAll(){
    if (!this.validacionNombre() || !this.dataSelected || !this.validaPeriodoSeleccionado() || this.nombrePrecioNull) {
       this.isDisabled = true;
      } else {
        this.isDisabled = false;
    }
  }

  validacionNombre(){
    if (this.membresia.nombreMembresia == '' || this.membresia.nombreMembresia == null) {
      this.campoNombreAlert = true
      return false;
    }else{
      this.campoNombreAlert = false
      return true;      
    }
  }

  validaPeriodoSeleccionado(){
    for (const membresia of this.membresia.periodos) {
      if (membresia.activo) {
        return true;
      } 
    }
    return false;
  }

  crearMembresia(form){
    this.loading = true;
    this.membresia.servicios = this.idServiciosSeleccionados;

    console.log('membresia por crear:::');
    console.log(this.membresia);
    

    if (!this.isEdit) {
      Swal.fire({
        title: '¿Crear membresía?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#07d79c',
        cancelButtonColor: '#717171',
        cancelButtonText:'No',
        confirmButtonText: 'Confirmar'
      }).then((result) => {
        if (result.isConfirmed) {
              this.membreciasService.crearMembresia(this.membresia).subscribe(res=>{
                this.mensajesSwalService.agregadoConExito('Membrecia', 'a')
                this.router.navigateByUrl('/dashboard/membresias');
                this.loading = false;
              }, (error)=>{
                this.mensajesSwalService.huboUnError(error);
                this.loading = false;
              })
              
            } else{
              this.loading = false;
            }
          })

    }else{

      Swal.fire({
        title: '¿Editar membresía?',
        text: 'Esta acción quedara registrada a tu usuario',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#07d79c',
        cancelButtonColor: '#717171',
        cancelButtonText:'No',
        confirmButtonText: 'Confirmar'
      }).then((result) => {
        if (result.isConfirmed) {
  
              this.membreciasService.editarMembresia(this.membresia, this.idMembresia).subscribe(res=>{
                this.mensajesSwalService.modificadoConExito('Membrecia', 'a')
                this.router.navigateByUrl('/dashboard/membresias');
                this.loading = false;
        
              }, (error)=>{
                this.mensajesSwalService.huboUnError(error);
                this.loading = false;
              })

            }
          this.loading = false;
      })

    }

  }

  obtenerMembresia(id){    
    this.membreciasService.obtenerMembresia(id).subscribe(res=>{
      
      this.membresiaData = res;
      const serviciosEncontrados = this.membresiaData.membresia.servicios;
      const periodosEncontrados = this.membresiaData.membresia.periodos;

      if (serviciosEncontrados.length>0) {

        this.serviciosDataSeleccionados=[...serviciosEncontrados];      
        this.dataSelected=true;


        for (let index1 = 0; index1 < this.serviciosDataClone.length; index1++) {

          for (let index2 = 0; index2 < serviciosEncontrados.length; index2++) {
            
            if (this.serviciosDataClone[index1].id == serviciosEncontrados[index2].id) {

                const indexServicio = this.serviciosData.indexOf(this.serviciosDataClone[index1]);
                this.serviciosData.splice(indexServicio, 1);

                const objId = {
                  id:serviciosEncontrados[index2].id
                }

                this.idServiciosSeleccionados.push(objId)
            
            }
          }
        }
      }

      if (periodosEncontrados.length>0) {
        for (const periodo of periodosEncontrados) {
          switch (periodo.key) {
            
            case 'DIA':

              this.membresia.periodos[4].precio = periodo.precio;
              this.membresia.periodos[4].id = periodo.id;
              this.membresia.periodos[4].activo = periodo.activo;
              this.nombrePrecioNull=false;

              break;
            
            case 'SEMANA':
              this.membresia.periodos[3].precio = periodo.precio;
              this.membresia.periodos[3].id = periodo.id;
              this.membresia.periodos[3].activo = periodo.activo;
              this.nombrePrecioNull=false;
              
              break;

            case 'QUINCENA':

              this.membresia.periodos[2].precio = periodo.precio;
              this.membresia.periodos[2].id = periodo.id;
              this.membresia.periodos[2].activo = periodo.activo;
              this.nombrePrecioNull=false;

              break;

              case 'MES':

                this.membresia.periodos[1].precio = periodo.precio;
                this.membresia.periodos[1].id = periodo.id;
                this.membresia.periodos[1].activo = periodo.activo;
                this.nombrePrecioNull=false;

              break;

            case 'AÑO':

              this.membresia.periodos[0].precio = periodo.precio;
              this.membresia.periodos[0].id = periodo.id;
              this.membresia.periodos[0].activo = periodo.activo;
              this.nombrePrecioNull=false;

              break;
          
            default:
              
              break;
          }
          
        }

   
      }

      this.membresia.precioInscripcion = this.membresiaData.membresia.precioInscripcion;
      this.membresia.nombreMembresia = this.membresiaData.membresia.nombreMembresia;
      this.membresia.servicios = this.idServiciosSeleccionados;
      
      this.validaAll();
    })
  }





}
