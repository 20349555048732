<div class="row">
    <div class="col-md-6 col-sm-12 col-xs-12">
        <div class="ribbon-vwrapper-reverse card">
            <div (click)="goPay()" class="ribbon ribbon-bookmark ribbon-vertical-r ribbon-success cursorpointer"><i class="fa fa-dollar"></i></div>
            <div class="d-flex no-block">
                <div class="m-r-20 align-self-center"><span class="lstick m-r-20"></span><img src="../assets/images/icon/expense.png" alt="Income" /></div>
                <div class="align-self-center">
                    <h6 class="text-muted m-t-10 m-b-0"> {{diasRestantes > 0 ? 'Licencia activa' : 'Licencia vencida'}}</h6>
                    <h2 [ngClass]="{'text-danger': diasRestantes <= 0 }" class="m-t-0" >{{diasRestantes}} dias restantes</h2>
                    <p *ngIf="diasRestantes <= 10" class="info"><i class="fa fa-info-circle"></i> Para renovar la suscripción da clic <span class="hereButton cursorpointer" (click)="goPay()">aquí</span></p>
                </div>

            </div>
        </div>

        <div class="card m-t-20">
            <div class="card-body">

                <form #formCambiaDatosGym="ngForm">
                    <h3>Cambiar datos de mi centro deportivo</h3>
                    <small><i class="fa fa-info-circle"></i> Estos datos se muestran en los tickets de venta</small>
                    <br>

                    <div class="row">

                        <div class="col-md-3 m-t-20">
                            <app-photo
                                [urlImg]="urlImg" 
                                [botonCargarFoto]="editMode" 
                                (fotoCargada)="recibirFoto($event)"
                                (loadingStatus)="recibirStatusLoading($event)"
                            >
                            </app-photo>
                        </div>
                        <div class="col-md-9">
                            <div class="form-group">

                                <label class="m-t-20" for="nombres">*Nombre del centro:</label>
                                <input 
                                    type="text"
                                    class="form-control" 
                                    [(ngModel)]="nombre"
                                    name="nombre"
                                    [readOnly]="!editMode"
                                    autocomplete="off"
                                    (ngModelChange)="validaFormDatosGym()"
                                    >    
                                <label class="m-t-20" for="nombres">*Teléfono :</label>
                                <input 
                                    type="text"
                                    class="form-control" 
                                    [(ngModel)]="telefono"
                                    name="telefono"
                                    [readOnly]="!editMode"
                                    autocomplete="off"
                                    (ngModelChange)="validaFormDatosGym()"
                                    >    
                                <label class="m-t-20" for="nombres">*Email de contacto :</label>
                                <input 
                                    type="text"
                                    class="form-control" 
                                    [(ngModel)]="correo"
                                    name="correo"
                                    [readOnly]="!editMode"
                                    autocomplete="off"
                                    (ngModelChange)="validaFormDatosGym()"
                                    >
                                    
                                <div *ngIf="alertEmailEnabled" class="myalert m-t-20" role="alert">
                                    <span>Verifica el formato del correo: ejemplo@mail.com</span>
                                </div>
                                <label class="m-t-20" for="nombres">Dirección :</label>
                                <input 
                                    type="text"
                                    class="form-control" 
                                    [(ngModel)]="direccion"
                                    name="direccion"
                                    [readOnly]="!editMode"
                                    autocomplete="off"
                                    (ngModelChange)="validaFormDatosGym()"
                                    >    
                                    <div *ngIf="alertInputRequiredEnabled" class="myalert m-t-20" role="alert">
                                        <span>*Falta algun campo obligatorio</span>
                                    </div>
                                    <br>
        
        
                                    <div class="text-right">
                                        <button 
                                            *ngIf="!loadingDatos && !editMode"
                                            type="submit" 
                                            (click)="editMode = true" 
                                            class="btn btn-info m-t-40" 
                                            >
                                            Editar
                                        </button>
                                        <button 
                                            *ngIf="!loadingDatos && editMode"
                                            type="button" 
                                            (click)="cancelEditMode()" 
                                            class="btn btn-warning m-t-40" 
                                            >
                                            Cancelar
                                        </button>
                                        &nbsp;
                                        <button 
                                            *ngIf="!loadingDatos && editMode"
                                            type="submit" 
                                            (click)="cambiarDatos()" 
                                            class="btn btn-info m-t-40" 
                                            [disabled]=isDisabledFormDatos
                                            >
                                            Guardar cambios
                                        </button>
                                        <button 
                                            *ngIf="loadingDatos"
                                            type="button" 
                                            class="btn btn-info m-t-40" 
                                            disabled
                                            >
                                            <i class="fa fa-spin fa-refresh fa-1x"></i> Esperando ...
                                        </button>
                                        
                                    </div>
        
        
                                </div>
                        </div>
    
    
    
                    </div>



                    

                </form>
            </div>
        </div>        
        <div class="card">
            <div class="card-body">
                

                <h3>Seleccione tema</h3>
                    <div class="r-panel-body">
                        <ul id="themecolors" class="m-t-20">
                            <!-- working -->
                            <li><b>Con el sidebar claro</b></li>
                            <li><a (click)="changeTheme('green')" data-theme="green" class="selector green-theme ">2</a></li>
                            <li><a (click)="changeTheme('default')" data-theme="default" class="selector default-theme">1</a></li>
                            <li><a (click)="changeTheme('red')" data-theme="red" class="selector red-theme">3</a></li>
                            <li><a (click)="changeTheme('blue')" data-theme="blue" class="selector blue-theme">4</a></li>
                            <li><a (click)="changeTheme('purple')" data-theme="purple" class="selector purple-theme">5</a></li>
                            <li><a (click)="changeTheme('megna')" data-theme="megna" class="selector megna-theme">6</a></li>
                            
                            <li class="d-block m-t-30"><b>Con el sidebar oscuro</b></li>
                            <li><a (click)="changeTheme('default-dark')" data-theme="default-dark" class="selector default-dark-theme working">7</a></li>
                            <li><a (click)="changeTheme('green-dark')" data-theme="green-dark" class="selector green-dark-theme">8</a></li>
                            <li><a (click)="changeTheme('red-dark')" data-theme="red-dark" class="selector red-dark-theme">9</a></li>
                            <li><a (click)="changeTheme('blue-dark')" data-theme="blue-dark" class="selector blue-dark-theme">10</a></li>
                            <li><a (click)="changeTheme('purple-dark')" data-theme="purple-dark" class="selector purple-dark-theme">11</a></li>
                            <li><a (click)="changeTheme('megna-dark')" data-theme="megna-dark" class="selector megna-dark-theme">12</a></li>
                        </ul>
                    </div>
            </div>
        </div>

        <modal-payments></modal-payments>
    </div>