import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesRoutingModule } from './pages/pages.routing';
import { AuthRoutinModule } from './auth/auth.routing';

import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { CheckAsistenciasComponent } from './pages/asistencias/check-asistencias/check-asistencias.component';


const routes: Routes=[
  {path:'', redirectTo: '/dashboard', pathMatch:'full'},
  {path:'check-asistencias', redirectTo: '/check-asistencias', pathMatch:'full'},
  {path:'**', component: NopagefoundComponent },
]

@NgModule({
  declarations: [],
  imports: [
    
    RouterModule.forRoot(routes),
    PagesRoutingModule,
    AuthRoutinModule
  
  ],
  exports:[RouterModule]
})
export class AppRoutingModule { }
