import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
// import { MembreciasService } from 'src/app/services/membrecias.service';
import { SociosService } from 'src/app/services/socios.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { UtilsService } from 'src/app/services/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'datos-socio',
  templateUrl: './datos-socio.component.html',
  styleUrls: ['./datos-socio.component.css']
})
export class DatosSocioComponent implements OnInit, OnChanges {

  @Input() idSocioRegreso
  @Input() modoEditar = false
  @Output() idSocioEmit = new EventEmitter<number>();
  @Output() refreshSocios = new EventEmitter<string>();
  @Output() cancelarOperacion = new EventEmitter<string>();

  public creaSocioForm:any
  public idSocio:number=null
  public formSubmitted:boolean = false;
  public imgPreview:any='';
  public imgPreviewBack:any='';
  public loading:boolean = false
  public fotoAsubir:any=[]
  public uploadPercentage:any=0
  public keyImg:string='' 
  public myUrlImg:string='' 
  public correoValido:boolean = true

  constructor(
    private formBuilder:FormBuilder,
    public sociosService:SociosService,
    private usuarioService:UsuarioService,
    private utilsService: UtilsService

  ) {

    this.creaSocioForm =  this.formBuilder.group({
    
      email:['', [Validators.email]],
      // email:['', [Validators.required, Validators.email]],
      nombres:['', Validators.required],
      apellidoPaterno:['', Validators.required],
      apellidoMaterno:'',
      fechaNacimiento:[''],
      sexo:['', Validators.required],
      telefono:['', [Validators.minLength(10),  Validators.maxLength(10)]],
      // telefono:['', [Validators.required, Validators.minLength(10),  Validators.maxLength(10)]],
      keyImg:'',
      urlImg:''
    });
   }
  ngOnChanges(): void {
    // this.modoEditar=true
    this.obtenerSocio();
    


  }

  ngOnInit(): void {
    
    this.obtenerSocio();

  }


  obtenerSocio(){

    if (this.idSocioRegreso!=null) {
      this.idSocio = this.idSocioRegreso
      this.sociosService.obtenerSocio(this.idSocioRegreso).subscribe(({socio})=>{

        var customEmail = '';
        if (!this.utilsService.verificaCorreo(socio.email)) {
          customEmail = socio.email;
        }
        
        if (socio.urlImg!='' || socio.urlImg!=null) {
          this.imgPreview = socio.urlImg;
          this.imgPreviewBack = socio.urlImg;
        }
        this.creaSocioForm.patchValue({
          email:customEmail,
          nombres:socio.nombres,
          apellidoPaterno:socio.apellidoPaterno,
          apellidoMaterno:socio.apellidoMaterno,
          fechaNacimiento:socio.fechaNacimiento,
          sexo:socio.sexo,
          telefono:socio.telefono,
          urlImg:socio.urlImg,
          keyImg:socio.keyImg,
        })
        
      })
    }

  }

  cambiarImagen(event){
    this.loading=true;

    const file  = event.target.files[0]
    
    
    if (!file) {
      if (this.imgPreview) {
        this.loading=false;


        return this.imgPreview
      }else{
        this.loading=false;
        return this.imgPreview='';
      }

    }else{
      this.fotoAsubir.push(file);
      this.subirImagen(file);
    }

  }

  subirImagen(file){
    
    try {

      const formImagen = new FormData();
      
      this.fotoAsubir.forEach(miFoto => {
        formImagen.append('file', miFoto)
        
      });

      this.sociosService.fotoSocioUploadService(formImagen)
        .subscribe(({respuesta,cargaBytes,cargaTotal}) => {
          
          if (respuesta) {

            this.myUrlImg = respuesta.data
            
            this.creaSocioForm.patchValue(
                {
                  keyImg:this.myUrlImg,
                  urlImg:''

                }
                );

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend=()=>{
              this.imgPreview=reader.result;
            }

            this.loading=false;
            this.fotoAsubir = [];   
            
          }
    
          const carga = parseInt(cargaBytes)
          const total = parseInt(cargaTotal)

          this.uploadPercentage =  Math.round( ( carga / total ) * 100 )
          
      })

    } catch (error) {


      this.loading=false;
      this.fotoAsubir = [];
      this.imgPreview='';

    }
    
  }

  quitarImagen(){

    this.fotoAsubir = [];   
    this.creaSocioForm.patchValue({
      urlImg:'',
      keyImg:null,
    });
    
    if (this.imgPreviewBack!='' || this.imgPreviewBack!=null) {
      this.imgPreview = this.imgPreviewBack
    }else{
      this.imgPreview='';
    }

  }


  compruebaEmail(e){
      

    if (e) {
      const email = e.target.value
      
      this.usuarioService.obtenerUsuarioPorCorreo(email).subscribe((data)=>{
        
        if (data.data == true) {
          this.correoValido = true
          
        }else{
          this.correoValido = false
          
        }
      })
    }

    
    
  }

  crearSocio(){ 
    
    // this.mystep1 = false;
    // this.mystep2 = true;
    this.formSubmitted=true;
    this.loading = true;    

      if (this.idSocio==null) {
        
        this.sociosService.crearSocio(this.creaSocioForm.value).subscribe(({idSocio})=>{
          this.idSocio=idSocio
          this.loading = false;    
          
          this.idSocioEmit.emit(this.idSocio);
          // this.limpiaSoloData()
          // this.creaVentaForm.patchValue({idSocio:this.idSocio});
        
        })    
    
      }else{
        
        this.sociosService.modificarSocio(this.idSocio, this.creaSocioForm.value).subscribe(res=>{
          
          this.loading = false;    
         
            if (!this.modoEditar) {

              this.idSocioEmit.emit(this.idSocio);
            }else{
              this.refreshSocios.emit('recargaSocios');
              this.limpiaData()
            }
            
        })    

      }

  }

  cerrarModal(){ 
 
      
      Swal.fire({
        title: '¿Seguro que deseas cancelar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#E5B700',
        cancelButtonColor: '#717171',
        cancelButtonText:'No',
        confirmButtonText: 'Si'
      }).then((result) => {
          if (result.isConfirmed) {
            if (this.idSocio==null) {
              this.limpiaData()
            }else{
              this.sociosService.eliminaSocio(this.idSocio).subscribe(res=>{
                this.limpiaData()
              })
            }
          }        
       })

 }
  cerrarModalEditar(){ 
 
      
      Swal.fire({
        title: '¿Cancelar editar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#E5B700',
        cancelButtonColor: '#717171',
        cancelButtonText:'No',
        confirmButtonText: 'Si'
      }).then((result) => {
          if (result.isConfirmed) {
            this.cancelarOperacion.emit('recargaSocios');
            this.limpiaData()
          }        
       })

 }

 limpiaData(){

  this.sociosService.cerrarModalAgregarSocio()
  this.creaSocioForm.patchValue({
    nombres:'',
    apellidoPaterno:'',
    apellidoMaterno:'',
    fechaNacimiento:'',
    sexo:'',
    telefono:'',
    email:'',
    keyImg:null,
    urlImg:null
  });

  this.imgPreview='';
  this.imgPreviewBack='';
  this.creaSocioForm.markAsPristine();
  this.creaSocioForm.markAsUntouched();
  this.correoValido = true;
  this.idSocio=null;
  this.modoEditar=false;
  this.idSocioRegreso=null
  this.formSubmitted=false;    

  }

limpiaSoloData(){
  this.creaSocioForm.patchValue({
    nombres:'',
    apellidoPaterno:'',
    apellidoMaterno:'',
    fechaNacimiento:'',
    sexo:'',
    telefono:'',
    email:'',
    keyImg:null,
    urlImg:null
  });

  this.imgPreview='';
  this.imgPreviewBack='';
  this.creaSocioForm.markAsPristine();
  this.creaSocioForm.markAsUntouched();
  this.correoValido = true;
  this.idSocio=null;
  this.modoEditar=false;
  this.idSocioRegreso=null
  this.formSubmitted=false;
}


get nombres() {return this.creaSocioForm.get('nombres')}
get apellidoPaterno() {return this.creaSocioForm.get('apellidoPaterno')}
get fechaNacimiento() {return this.creaSocioForm.get('fechaNacimiento')}
get sexo() {return this.creaSocioForm.get('sexo')}
get telefono() {return this.creaSocioForm.get('telefono')}
get email() {return this.creaSocioForm.get('email')}

}
