// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base_url:'https://api-dev.unifit.mx',
  // front_url:'http://localhost:4200',
  front_url:'https://admin-dev.unifit.mx',
  basic_token: 'Basic ZnJvbnRlbmRhcHA6MTIzNDU=',
  stripe_pk: 'pk_test_51OWrInLcTBg6euC64UEaJtpsMTHbrqCVWZkd173dFOGcyZ7Pb7h1T31VMDiiMBYBRAapw6q4qi9Q3YDOqMK0LRuM00BYFhr27D'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
// https://myadmin-back.herokuapp.com/