import { Component, OnInit } from '@angular/core';
import { Socio } from 'src/app/models/socio.model';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import { SociosService } from 'src/app/services/socios.service';
import { UtilsService } from 'src/app/services/utils.service';
// import * as clipboard from 'clipboard-polyfill';
import { SafeUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-socios',
  templateUrl: './socios.component.html',
  styles: [
  ]
})
export class SociosComponent implements OnInit {

  public sociosData:Socio[]=[];
  public cargando:boolean=true;
  public buscandoPorNombre:boolean=false;

  public desde:number=0;
  public hasta:number=5;
  public infoPaginacion:any;
  public totalItems:number=0;
  public numeroDePagina:number=0;
  public itemsPorPagina:number=10;
  public totalPaginasInt:number=1;
  public totalPaginasArray:number[]=[];
  public idSocioInput:Object=null;
  public terminoBusqueda:string=''
  public socio:Socio=null;
  public loadingAsistencia:boolean=false;
  public filtroPorVencer:boolean=false;
  public opcionesFiltrado:boolean=false;
  public sociosMembresias:string='todos';
  public myAngularxQrCode:string='';
  public diasDeVencer:number=10;
  public mesesSinRenovar:number=3;
  public diasDeVencerEstatus:boolean=false;
  public mesesSinRenovarEstatus:boolean=false;
  public qrCodeDownloadLink: SafeUrl = "";
  // @ViewChild('qrcodeElement', { static: false }) qrcodeElement: ElementRef;
          

  constructor(
    private sociosService:SociosService,
    private mensajesSwalService:MensajesSwalService,
    private utilsService: UtilsService
  ) { 
    this.myAngularxQrCode = 'Your QR code data string';
  }

  ngOnInit(): void {
    this.cargarSocios()
    
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }


  buscar(busqueda):void{
    
    const terminoTxt = busqueda.terminoBusqueda;
    this.cargando=true;
    this.buscandoPorNombre=true

    if (terminoTxt=='') {
      
      this.cargarSocios();

    }else{

        this.sociosService.obtenerSocioPorNombre(terminoTxt).subscribe(socios=>{
        
          this.sociosData = socios;

          this.cargando=false;

        },()=>{
          this.cargando=false
        })
              
    }

    
  }

  abrirModal(tipoDeModal, socio){
    
    if (tipoDeModal == 'agregar' ) {
      this.sociosService.abrirModalAgregarSocio()
    }else if (tipoDeModal == 'editar') {
      
      this.idSocioInput = {
                            socioID: socio.id,
                            operacion:'editar'

                          }

      this.sociosService.abrirModalAgregarSocio()

    }else if (tipoDeModal == 'comprar') {
      
      this.idSocioInput = {
        socioID: socio.id,
        operacion:'comprar'

      }

      this.sociosService.abrirModalAgregarSocio()

      
    }
    
  }



  // async copiarQR() {
  //   const canvas = this.qrcodeElement.nativeElement.querySelector('canvas');
  //   canvas.toBlob(async (blob) => {
  //     if (blob) {
  //       const item = new clipboard.ClipboardItem({ 'image/png': blob });
  //       await clipboard.write([item]);
  //       alert('QR copiado al portapapeles');
  //     }
  //   });
  // }

  descargarQR(id){
    
    this.sociosService.obtenerqr(id).subscribe(res=>{
      this.abrirPDFOtraVentana(res);

    }, (error) => {
     console.log(error);
    })

  }

  abrirPDFOtraVentana(res) {
    const blob = new Blob([res.body], { type: 'application/pdf' }),
      url = window.URL.createObjectURL(blob);
      window.open(url);
  }


  // copiarQR(folio){
  //   console.log('folio:::');
  //   console.log(folio);
  // }

  cargarSocios(){
    
    this.idSocioInput=null;
    this.buscandoPorNombre=false
    this.cargando=true;

  
      this.sociosService.obtenerSocios(this.numeroDePagina, this.itemsPorPagina)
      .subscribe(({socios, infoPaginacion})=>{
  
        this.sociosData = socios;
                
        this.infoPaginacion = infoPaginacion
        this.totalPaginasInt = infoPaginacion.totalPages
        this.totalPaginasArray=[]
        this.totalItems= infoPaginacion.totalElements
  
        
        for (let i = 0; i < this.totalPaginasInt; i++) {
            this.totalPaginasArray.push(i);
          }
  
        
        this.cargando=false;
  
      })

  }
  cargarSociosVencidos(){
    
    this.idSocioInput=null;
    this.buscandoPorNombre=false
    this.cargando=true;

  
      this.sociosService.obtenerSociosVencidos(this.numeroDePagina, this.itemsPorPagina)
      .subscribe(({socios, infoPaginacion})=>{
  
        this.sociosData = socios;
        
        this.infoPaginacion = infoPaginacion
        this.totalPaginasInt = infoPaginacion.totalPages
        this.totalPaginasArray=[]
        this.totalItems= infoPaginacion.totalElements
  
        
        for (let i = 0; i < this.totalPaginasInt; i++) {
            this.totalPaginasArray.push(i);
          }
  
        
        this.cargando=false;
  
      })

  }
  cargarSociosActivos(){
    
    this.idSocioInput=null;
    this.buscandoPorNombre=false
    this.cargando=true;

  
      this.sociosService.obtenerSociosActivos(this.numeroDePagina, this.itemsPorPagina)
      .subscribe(({socios, infoPaginacion})=>{
  
        this.sociosData = socios;
        
        this.infoPaginacion = infoPaginacion
        this.totalPaginasInt = infoPaginacion.totalPages
        this.totalPaginasArray=[]
        this.totalItems= infoPaginacion.totalElements
  
        
        for (let i = 0; i < this.totalPaginasInt; i++) {
            this.totalPaginasArray.push(i);
          }
  
        
        this.cargando=false;
  
      })

  }
  cargarSociosPorVencer(){

    if (this.diasDeVencer < 0) {
      return;
    }
    
    this.idSocioInput=null;
    this.buscandoPorNombre=false
    this.cargando=true;

  
      this.sociosService.obtenerSociosPorVencer(this.numeroDePagina, this.itemsPorPagina, this.diasDeVencer)
      .subscribe(({socios, infoPaginacion})=>{
  
        this.sociosData = socios;
        
        this.infoPaginacion = infoPaginacion
        this.totalPaginasInt = infoPaginacion.totalPages
        this.totalPaginasArray=[]
        this.totalItems= infoPaginacion.totalElements
  
        
        for (let i = 0; i < this.totalPaginasInt; i++) {
            this.totalPaginasArray.push(i);
          }
  
        
        this.cargando=false;
  
      })

  }

  cargarSociosSinRenovar(){

    if (this.mesesSinRenovar < 0) {
      return;
    }
    
    this.idSocioInput=null;
    this.buscandoPorNombre=false
    this.cargando=true;

  
      this.sociosService.obtenerSociosSinRenovar(this.numeroDePagina, this.itemsPorPagina, this.mesesSinRenovar)
      .subscribe(({socios, infoPaginacion})=>{
  
        this.sociosData = socios;
        
        this.infoPaginacion = infoPaginacion
        this.totalPaginasInt = infoPaginacion.totalPages
        this.totalPaginasArray=[]
        this.totalItems= infoPaginacion.totalElements
  
        
        for (let i = 0; i < this.totalPaginasInt; i++) {
            this.totalPaginasArray.push(i);
          }
  
        
        this.cargando=false;
  
      })

  }
  cargarSociosSinRegistrar(){

       
    this.idSocioInput=null;
    this.buscandoPorNombre=false
    this.cargando=true;

  
      this.sociosService.obtenerSociosSinRegistrar(this.numeroDePagina, this.itemsPorPagina)
      .subscribe(({socios, infoPaginacion})=>{
  
        this.sociosData = socios;
        
        this.infoPaginacion = infoPaginacion
        this.totalPaginasInt = infoPaginacion.totalPages
        this.totalPaginasArray=[]
        this.totalItems= infoPaginacion.totalElements
  
        
        for (let i = 0; i < this.totalPaginasInt; i++) {
            this.totalPaginasArray.push(i);
          }
  
        
        this.cargando=false;
  
      })

  }

  cargarSociosPorFotos(){
    this.idSocioInput=null;
    this.buscandoPorNombre=false
    this.cargando=true;

    setTimeout(() => {
      this.sociosService.obtenerSocios(this.numeroDePagina, this.itemsPorPagina)
      .subscribe(({socios, infoPaginacion})=>{
  
        this.sociosData = socios;
        
        this.infoPaginacion = infoPaginacion
        this.totalPaginasInt = infoPaginacion.totalPages
        this.totalPaginasArray=[]
        this.totalItems= infoPaginacion.totalElements
  
        
        for (let i = 0; i < this.totalPaginasInt; i++) {
            this.totalPaginasArray.push(i);
          }
  
        
        this.cargando=false;
  
      })
    }, 1500);

  }
  
  seleccionaItemsPorPagina(e){

    this.itemsPorPagina = e;
    this.filtroSocios(this.sociosMembresias);
    
  }

  seleccionaPagina(data){

    this.numeroDePagina =  data.paginaSeleccionada;
    this.desde =  data.desde;
    this.hasta =  data.hasta;
    this.filtroSocios(this.sociosMembresias);
  }

  registraAsistencia(id){
    this.loadingAsistencia = true;
    

    
    this.sociosService.registrarAsistencia(id).subscribe(res=>{
      this.loadingAsistencia = false;

      this.mensajesSwalService.registrarAsistencia();

      
    })
  }

  filtroSocios(e){

    switch (e) {
      case 'todos':
        this.diasDeVencerEstatus=false;
        this.mesesSinRenovarEstatus=false;
        this.cargarSocios();
      break;
      case 'vencidas':
        this.diasDeVencerEstatus=false;
        this.mesesSinRenovarEstatus=false;
        this.cargarSociosVencidos();
      break;
      case 'activas':
        this.diasDeVencerEstatus=false;
        this.mesesSinRenovarEstatus=false;
        this.cargarSociosActivos();
      break;
      case 'por-vencer':
        this.diasDeVencerEstatus=true;
        this.mesesSinRenovarEstatus=false;
        this.cargarSociosPorVencer();
      break;
      case 'sin-renovar':
        this.diasDeVencerEstatus=false;
        this.mesesSinRenovarEstatus=true;
        this.cargarSociosSinRenovar();
      break;
      case 'sin-registrar':
        this.diasDeVencerEstatus=false;
        this.mesesSinRenovarEstatus=false;
        this.cargarSociosSinRegistrar();
      break;
    
      default:
        break;
    }

    
  }
  
}
