import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import { MultimediaService } from 'src/app/services/multimedia.service';
import { ProductosService } from 'src/app/services/productos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'modal-producto',
  templateUrl: './modal-producto.component.html',
  styleUrls: ['./modal-producto.component.css']
})
export class ModalProductoComponent implements OnInit, OnChanges {

  @Input() idProductoInput:any
  @Output() refreshData = new EventEmitter<string>();

  public creaProductoForm:any
  public modoEditar:boolean=false;
  public loading:boolean = false
  public imgPreview:any='';
  public fotoAsubir:any=[]
  public imgPreviewBack:any='';
  public uploadPercentage:any=0
  public myUrlImg:string='';
  public idProducto:number=null;



  constructor(
    public productoService:ProductosService,
    public multimediaService:MultimediaService,
    private formBuilder:FormBuilder,
    private mensajesSwalService:MensajesSwalService
  ) {
    this.creaProductoForm =  this.formBuilder.group({
      nombre:['', Validators.required],
      precio:['', Validators.required],
      cantidad:['', Validators.required],
      keyImg:null,
      urlImg:null,
      urlImgThumbnail:null,
      codigo:'',
      costo:null,
      fechaCaducidad:null,
      descripcion:''
    });
   }


  ngOnInit(): void {

  }

  ngOnChanges(): void {

    if (this.idProductoInput==null || this.idProductoInput=='' ) {
        this.modoEditar=false
        
    }else{
    
      this.modoEditar=true;
      this.idProducto = this.idProductoInput.productoID
      this.obtenerProducto();


    }
  }

  obtenerProducto(){

    if (this.idProducto!=null) {
      this.productoService.obtenerProducto(this.idProducto).subscribe(({producto})=>{
        

        if (producto.urlImg!='' || producto.urlImg!=null) {
          this.imgPreview = producto.urlImg;
          this.imgPreviewBack = producto.urlImg;
        }
        this.creaProductoForm.patchValue({

          nombre:producto.nombre,
          precio:producto.precio,
          cantidad:producto.cantidad,
          codigo:producto.codigo,
          costo:producto.costo,
          descripcion:producto.descripcion,
          fechaCaducidad:producto.fechaCaducidad,
          keyImg:null,
          urlImg:null,
          urlImgThumbnail:null

        })
        
      })
    }

  }


  crearProducto(){


    this.loading = true;
    if (!this.modoEditar) {

      Swal.fire({
        title: '¿Registrar producto?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#E5B700',
        cancelButtonColor: '#717171',
        cancelButtonText:'No',
        confirmButtonText: 'Si'
      }).then((result) => {
  
          if (result.isConfirmed) {
  
            this.productoService.crearProducto(this.creaProductoForm.value).subscribe((res:any)=>{

              // if (res.message == 'ok') {
              // }
              
              this.loading = false;                
              
              this.mensajesSwalService.agregadoConExito('Producto','o')
              this.productoService.cerrarModalProducto();
              this.limpiaData();
              this.refreshData.emit();
            })
  
          }
       })      
    }else{

      Swal.fire({
        title: '¿Editar producto?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#E5B700',
        cancelButtonColor: '#717171',
        cancelButtonText:'No',
        confirmButtonText: 'Si'
      }).then((result) => {
  
          if (result.isConfirmed) {
  
            this.productoService.modificarProducto(this.idProducto ,this.creaProductoForm.value).subscribe(res=>{
        
              this.loading = false;
              this.mensajesSwalService.modificadoConExito('Producto','o')
              this.productoService.cerrarModalProducto();
              this.limpiaData();
              this.refreshData.emit();
            })
  
          }        
       })

    }

    

  }



  limpiaData(){

    this.creaProductoForm.patchValue({
      nombre:'',
      precio:null,
      cantidad:null,
      codigo:null,
      costo:null,
      descripcion:'',
      fechaCaducidad:null,
      keyImg:null,
      urlImg:null,
      urlImgThumbnail:null,
    });
    this.idProductoInput = null;
    this.modoEditar = false;
    this.creaProductoForm.markAsPristine();
    this.creaProductoForm.markAsUntouched();
    this.imgPreview='';
    this.imgPreviewBack='';

  }

  cambiarImagen(event){
    this.loading=true;

    const file  = event.target.files[0]
    
    
    if (!file) {
      if (this.imgPreview) {
        this.loading=false;


        return this.imgPreview
      }else{
        this.loading=false;
        return this.imgPreview='';
      }

    }else{
      this.fotoAsubir.push(file);
      this.subirImagen(file);
    }

  }

  subirImagen(file){
    
    try {

      const formImagen = new FormData();
      
      this.fotoAsubir.forEach(miFoto => {
        formImagen.append('file', miFoto)
        
      });

      this.multimediaService.subirFoto(formImagen)
        .subscribe(({respuesta,cargaBytes,cargaTotal}) => {
          
          if (respuesta) {

            this.myUrlImg = respuesta.data
            
            this.creaProductoForm.patchValue(
                {
                  keyImg:this.myUrlImg
                }
                );

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend=()=>{
              this.imgPreview=reader.result;
            }

            this.loading=false;
            this.fotoAsubir = [];   
            
          }
    
          const carga = parseInt(cargaBytes)
          const total = parseInt(cargaTotal)

          this.uploadPercentage =  Math.round( ( carga / total ) * 100 )
          
      })

    } catch (error) {


      this.loading=false;
      this.fotoAsubir = [];
      this.imgPreview='';

    }
    
  }

  quitarImagen(){

    this.fotoAsubir = [];   
    this.creaProductoForm.patchValue({
      urlImg:null,
      keyImg:null,
    });
    
    if (this.imgPreviewBack!='' || this.imgPreviewBack!=null) {
      this.imgPreview = this.imgPreviewBack
    }else{
      this.imgPreview='';
    }

  }

  cerrarModal(){

    Swal.fire({
      title: '¿Cancelar?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#E5B700',
      cancelButtonColor: '#717171',
      cancelButtonText:'No',
      confirmButtonText: 'Si'
    }).then((result) => {

        if (result.isConfirmed) {
      
            this.limpiaData();
            this.productoService.cerrarModalProducto();

        }        
     })
  }



  get nombre() {return this.creaProductoForm.get('nombre')}
  get precio() {return this.creaProductoForm.get('precio')}
  get cantidad() {return this.creaProductoForm.get('cantidad')}
  get codigo() {return this.creaProductoForm.get('cantidad')}
  get costo() {return this.creaProductoForm.get('costo')}
  get fechaCaducidad() {return this.creaProductoForm.get('fechaCaducidad')}
  get descripcion() {return this.creaProductoForm.get('descripcion')}

}
