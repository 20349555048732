import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { SociosService } from 'src/app/services/socios.service';
import { UsuarioService } from 'src/app/services/usuario.service';
// import { VentaMembresiaComponent } from '../../membrecias/shared/venta-membresia/venta-membresia.component';

@Component({
  selector: 'modal-agregar-usuario',
  templateUrl: './modal-agregar-usuario.component.html',
  styleUrls: ['./modal-agregar-usuario.css']
})
export class ModalAgregarUsuarioComponent implements OnInit, OnChanges {

  // @ViewChild(VentaMembresiaComponent) ventaMembresia: VentaMembresiaComponent;
  @Output() refreshSocios = new EventEmitter<string>();
  @Input() idSocioInput:any


  public mystep1:boolean=true;
  public mystep2:boolean=false;
  public idSocioInputEnviar:number=null;
  public idSocioDeRegreso;
  public modoEditar:boolean=false;
  public modoComprar:boolean=false;
  public nombreUsuario:String='';


  
  constructor(
    public sociosService:SociosService,
    public usuariosService:UsuarioService,

  ) { }



  ngOnChanges(): void {

    if (this.idSocioInput==null || this.idSocioInput=='' ) {
        this.modoEditar=false
        this.modoComprar=true
        
    }else if ((this.idSocioInput!=null || this.idSocioInput!='') && this.idSocioInput.operacion == 'comprar') {
      
      this.modoEditar=true
      this.modoComprar=true
      this.continua(this.idSocioInput.socioID)

    }else{
    
      this.idSocioDeRegreso=this.idSocioInput.socioID;
      this.modoEditar=true;
      this.modoComprar=false

      
    }
  }

  ngOnInit(): void {    
  }

  continua(e){

    this.mystep1 = false;
    this.mystep2 = true;
    this.idSocioInputEnviar = e;
    
    this.sociosService.obtenerSocio(e).subscribe(({socio}) => {
      this.nombreUsuario = `${socio.nombres} ${socio.apellidoPaterno} ${socio.apellidoMaterno}`;
    })
    
  }



  recargaDataSocios(e){
    this.mystep1 = true;
    this.mystep2 = false;
    this.modoEditar=false
    this.idSocioDeRegreso=null
    this.refreshSocios.emit('recargaSociosAunMas');


  }

  cancelarOperaciones(){
    this.mystep1 = true;
    this.mystep2 = false;
    this.modoEditar=false;
    this.modoComprar=false;
    this.idSocioDeRegreso=null;
    this.nombreUsuario = '';
  }

  isCurrent(idSocio:number){
    this.mystep1 = true;
    this.mystep2 = false;
    this.idSocioDeRegreso=idSocio;
     
  }


}
