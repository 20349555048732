import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RefreshDataService } from 'src/app/services/refresh-data.service';
import { SiderbarService } from 'src/app/services/siderbar.service';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-siderbar',
  templateUrl: './siderbar.component.html',
  styles: [
  ]
})
export class SiderbarComponent implements OnInit {

  public imgUrl:any;
  public nombre:string;
  public role:string = '';

  
  constructor(
    public siderbarService: SiderbarService,
    private userService:UsuarioService,
    private cdr: ChangeDetectorRef

    ) { 
    
    this.nombre = 'Demo'; 
    this.imgUrl = 'not-found';
    
  }

  ngOnInit(): void {
    
    this.obtenerRole();
    this.obtenerInfoUsuario();
    this.getDataFromSubject();
    
        
  }

  getDataFromSubject(){
    this.userService.profileData$.subscribe((newData) => {
      this.nombre = newData.nombre;
      this.imgUrl = newData.image;
      // Forzar la detección de cambios cuando se actualizan los datos
      this.cdr.detectChanges();
    });
  }

  obtenerRole(){
    const dataUser = this.userService.decodificarJWT();
    this.role = dataUser.role;
  }

  obtenerInfoUsuario(){
    this.userService.obtenerUsuario().subscribe((res:any)=>{

      const ProfileData = {
        nombre: res.nombres,
        image: res.urlImg,
        email: res.email
      }
      
      this.userService.updateProfileData(ProfileData);

    });

  }

  logout(){
    
    this.userService.logout();

  }


}
