import Swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/models/usuario.model';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { ModalImgService } from 'src/app/services/modal-img.service';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-modal-img',
  templateUrl: './modal-img.component.html',
  styles: [
  ]
})
export class ModalImgComponent implements OnInit {

  public usuario:Usuario;
  public imagenSubir: File;
  public imgPreview:any=null;
  public ocultarModal:boolean=false;
  
  constructor(
    public modalImgService:ModalImgService,
    private usuarioService:UsuarioService,
    public fileUploadService:FileUploadService 
  ) {
    this.usuario = this.usuarioService.usuario;
   }

  ngOnInit(): void {
  }

  cambiarImagen(file){
    this.imagenSubir=file;

    if (!file) {
      
      return this.imgPreview=null;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend=()=>{
      this.imgPreview=reader.result;
      
    }

  }


  subirImagen(){


    const uid = this.modalImgService.id;
    const tipo = this.modalImgService.tipo

    this.fileUploadService.actualizarFoto(this.imagenSubir, tipo, uid)
    .then(img => {
            
      Swal.fire({
        icon: 'success',
        title: 'Cambios guardados',
        text: 'Se guardaron los cambios exitosamente'
      });
      this.modalImgService.cambioImagen.emit(img);
      this.cerrarModal();

    }).catch(()=>{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'No se pudo subir la imagen, verifivca los tipos de archivos aceptados'
        });
        
    });
    
  }

  cerrarModal(){
    this.imgPreview=null;
    this.modalImgService.cerrarModal();
  }

}
