 <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <section id="wrapper" class="login-register login-sidebar" style="background-image:url(../assets/images/background/login-register2.jpg);">
        <div class="login-box card">
            <div class="card-body">
                
                <form 
                    class="form-horizontal form-material" 
                    id="loginform"
                    [formGroup]="registerForm"
                    (ngSubmit)="crearUsuario()"
                >
                    
                    <a href="javascript:void(0)" class="text-center db"><img src="../assets/images/logo-icon.png" alt="Home" /><br/><img src="../assets/images/logo-text.png" alt="Home" /></a>
                    <h3 class="box-title m-t-40 m-b-0">Registrate</h3><small>Crea una cuenta y disfruta de UNIFIT</small>

                    <div class="form-group m-t-20">
                        <div class="col-xs-12">
                            <input 
                                class="form-control" 
                                type="text" 
                                placeholder="Nombre del centro deportivo ..."
                                formControlName="nombre"
                            >
                        </div>
                    </div>
                    <div *ngIf="campoNoValido('nombre')" class="row">
                        <div class="col text-danger">
                            <p>El nombre del centro deportivo es obligatorio</p>
                        </div>
                    </div>
                    <div class="form-group m-t-20">
                        <div class="col-xs-12">
                            <input 
                            class="form-control" 
                            type="text" 
                            placeholder="Email"
                            formControlName="email"
                            >
                        </div>
                    </div>
                    <div *ngIf="campoNoValido('email')" class="row">
                        <div class="col text-danger">
                            <p>El email es obligatorio ejemplo@email.com</p>
                        </div>
                    </div>
                    <div class="form-group m-t-20">
                        <div class="col-xs-12">
                            <input 
                            class="form-control" 
                            type="password"
                            (ngModelChange)="contrasenaValidas($event)" 
                            placeholder="Password"
                            formControlName="password"
                            >
                        </div>
                    </div>
                    <div *ngIf="!correosOk" class="row">
                        <div class="col text-danger">
                            <p>Las contraseñas deben coincidir</p>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-xs-12">
                            <input 
                            class="form-control" 
                            type="password"
                            (ngModelChange)="contrasenaValidas($event)" 
                            placeholder="Confirma contraseña"
                            formControlName="password2"
                            >
                        </div>
                    </div>

                    

                    <div class="form-group row">
                        <div class="col-md-12">
                            <div class="checkbox checkbox-primary p-t-0">
                                <input 
                                    id="checkbox-signup" 
                                    type="checkbox"
                                    formControlName="terminos"
                                >

                                <label for="checkbox-signup"> Aceptar términos y condiciones</label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="aceptaTerminos()" class="row">
                        <div class="col text-danger">
                            <p>Es necesario que aceptes terminos y condiciones</p>
                        </div>
                    </div>
                    <div class="form-group text-center m-t-20">
                        <div class="col-xs-12">
                            <button 
                                *ngIf="!loading"
                                class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" 
                                type="submit"
                                [disabled]="registerForm.invalid || !correosOk"
                            >
                                Registrate
                            </button>
                            <button 
                                *ngIf="loading"
                                class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" 
                                type="button" 
                                disabled
                            >
                            <i class="fa fa-spin fa-refresh fa-1x"></i>
                            </button>
                        </div>
                    </div>
                    <div class="form-group m-b-0">
                        <div class="col-sm-12 text-center">
                            <p>¿Ya tienes una cuenta? <a routerLink="/login" class="text-info m-l-5"><b>Ingresa</b></a></p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->