import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GymService } from 'src/app/services/gym.service';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
const front_url = environment.front_url;

declare global {
  interface Window {
    Stripe?: any;
  }
}

@Component({
  selector: 'app-pasarela',
  templateUrl: './pasarela.component.html',
  styleUrls: ['./pasarela.component.css']
})
export class PasarelaComponent implements OnInit {

  private readonly STRIPE!: any; //TODO: window.Stripe
  private elementStripe!: any;
  form: FormGroup = new FormGroup({})


  public goPay:boolean = false;
  public step1:boolean = true;
  public step2:boolean = false;
  public loading:boolean = false;
  public cargadoCompletamente:boolean = false;
  public secretKey:string = '';
  public error:string = '';
  public tipoSuscripcion:string = '';
  public precioSuscripcion:string = '';


  constructor(
    private gymService:GymService,
    public mensajesSwalService:MensajesSwalService,
  ) { 
    this.STRIPE = window.Stripe(environment.stripe_pk);
  }

  ngOnInit(): void {
    this.error = '';
  }

  private createStripeElement = () => {

    const options = {
      clientSecret: this.secretKey,
      appearance: {/*...*/},
    };

    //TODO: SDK de Stripe inicia la generacion de elementos
    this.elementStripe = this.STRIPE.elements(options);

    //TODO: SDK Construimos los inputs de tarjeta, cvc, fecha con estilos
    const paymentElement = this.elementStripe.create('payment');
    paymentElement.mount('#payment-element');

    if (paymentElement.addEventListener) {
      this.cargadoCompletamente = true;      
    }    

  }

  async completarPago(){

    this.loading = true;
      try {
  
        Swal.fire({
          title: 'Confirmar compra?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#E5B700',
          cancelButtonColor: '#717171',
          cancelButtonText:'No',
          confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem('paymentIntent');
              this.confirmaPago();
            } else {
              this.loading = false;
            }    
         })
       
      } catch (error) {
        console.error(error);
        this.loading = true;
      }    
  }

  async confirmaPago(){
    const {error} = await this.STRIPE.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements: this.elementStripe,
      confirmParams: {
        return_url: `${front_url}/dashboard/acount-settings`
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)

      // habiulitar si se habilita en el template
      // const messageContainer = document.querySelector('#error-message');
      // messageContainer.textContent = error.message;
      this.loading = false;
      if (error.code == 'card_declined') {
        this.error = 'Tarjeta declinada, intente con otro metodo de pago';
      }
      if (error.code == 'payment_intent_unexpected_state' && error.payment_intent.status == 'succeeded') {
        const body = {
          type: localStorage.getItem('suscripcion')
        }
        this.mensajesSwalService.loading();
        this.gymService.compruebaIntencionDePago(body).subscribe((res:any)=> {

          if (res.data) {
            this.gymService.cerrarAlert();
            this.mensajesSwalService.suscripcionCreadaExitosamente();
            localStorage.removeItem('suscripcion');
            const timerId = setTimeout(() => {
              window.location.href = `${front_url}/dashboard/acount-settings`;
            }, 1700);

            clearTimeout(timerId);
          } else {
            this.mensajesSwalService.validandoPago();
            this.gymService.cerrarAlert();
            const timerId = setTimeout(() => {
              window.location.href = `${front_url}/dashboard/acount-settings`;
            }, 1700);

            clearTimeout(timerId);

          }

        })

      }
    } else {
      this.loading = false;
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  seleccionaSuscripcion(suscripcion){
    this.tipoSuscripcion = `SUSCRIPCION ${suscripcion}`;
    if (suscripcion=='MENSUAL') {
      this.precioSuscripcion = '429.00 MXN';
    } else if (suscripcion=='SEMESTRAL'){
      this.precioSuscripcion = '2349.00 MXN';
    } else {
      this.precioSuscripcion = '4548.00 MXN';
    }
    this.step1 = false;
    this.step2 = true;

    const body = {
      type: suscripcion
    } 
    localStorage.setItem('suscripcion', suscripcion);
    
    this.gymService.crearIntencionDePago(body).subscribe((res:any)=> {
      this.secretKey = res.data;

      console.log('secretKy:::');
      console.log(this.secretKey);
      
      
      if (this.secretKey != '') {
        this.createStripeElement();
      }
      
    })
  }

  cerrarModal(){
    this.step1 = true;
    this.step2 = false;
    this.secretKey = '';
    this.tipoSuscripcion = '';
    this.precioSuscripcion = '';
    this.loading = false;
    this.gymService.cerrarModalPayments();
    
  }
  anterior(){
    this.step1 = true;
    this.step2 = false;
    this.secretKey = '';
    this.tipoSuscripcion = '';
    this.loading = false;
    this.precioSuscripcion = '';
  }


}
