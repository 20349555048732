import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MembreciasService } from 'src/app/services/membrecias.service';
import { SociosService } from 'src/app/services/socios.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'venta-membresia',
  templateUrl: './venta-membresia.component.html',
  styleUrls: ['./venta-membresia.component.css']
})
export class VentaMembresiaComponent implements OnInit, OnChanges {

  @Input() idSocioInput;
  @Input() modoEditar = false
  @Output() refreshSocios = new EventEmitter<string>();
  @Output() anteriorEmit = new EventEmitter<number>();

  public loading:boolean = false;
  public formMembresiaSubmitted:boolean = false;
  public ocultarModal:boolean=true;
  public creaVentaForm:any
  public idSocio:number=null
  public membresiasData:any=[]
  public periodosData:any=[]
  public precio:number = 0
  public cantidadSeleccionada:number = 1
  public precioInscripcion:number = 0
  public precioInscripcionBackup:number = 0
  public inscripcionStatusLogic:boolean = false;
  public subtotal:number = 0
  public descuentoValido:boolean = true;
  public descuentoArealizar:number = 0;
  public totalAPagar:number = 0;
  public nombrePeriodo:string = 'periodos'
  @Output() cancelarOperacion = new EventEmitter<string>();


  constructor(
    private formBuilder:FormBuilder,
    public membreciasService:MembreciasService,
    public sociosService:SociosService


  ) {

    this.creaVentaForm =  this.formBuilder.group({
      idSocio:[0, Validators.required],
      idPeriodoMembrecia:['', Validators.required],
      cantidad:[1, [Validators.required, Validators.min(1)]],
      descuento:[0, [Validators.required, Validators.min(0)]],
      fechaInicio:['', Validators.required],
      inscripcionStatus:[true, Validators.required]
    });

   }
  ngOnChanges(): void {
    
    this.obtenerMembresias(); 
    this.idSocio = this.idSocioInput;
    this.creaVentaForm.patchValue({idSocio:this.idSocio});
  }

  ngOnInit(): void {
    this.obtenerMembresias(); 
    this.idSocio = this.idSocioInput;
    this.creaVentaForm.patchValue({idSocio:this.idSocio});
 
  }

  estableceEstatusInscripcion(){

    this.inscripcionStatusLogic = this.inscripcionStatus.value;
    if (this.inscripcionStatusLogic) {
      this.precioInscripcion = this.precioInscripcionBackup;
      this.subtotal = (this.precio * this.cantidadSeleccionada) + this.precioInscripcion; 
      this.totalAPagar = this.subtotal - this.descuento.value;
      
    } else {
      this.precioInscripcion = 0;
      this.subtotal = this.precio * this.cantidadSeleccionada;
      this.totalAPagar = this.subtotal - this.descuento.value;

    }


  }

  crearVenta(){   
        
    this.loading = true;
    Swal.fire({
      title: '¿Confirmar compra?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#07d79c',
      cancelButtonColor: '#717171',
      cancelButtonText:'No',
      confirmButtonText: 'Confirmar'
    }).then((result) => {
      if (result.isConfirmed) {

            this.formMembresiaSubmitted=false;
            
            this.membreciasService.confirmarVenta(this.creaVentaForm.value).subscribe(res=>{
              this.cerrarModal('flujoCompleto');
              this.abrirPDFOtraVentana(res);
              if (res.ok) {
                this.loading = false;
              }
              Swal.fire(
                'Venta realizada con exito!',
                'La venta ha sido registrada',
                'success'
              )

            }, (error) => {

              if (error.status == 422) {
                Swal.fire(
                  'Hay un conflicto en la fecha de inicio seleccionada!',
                  'La venta no ha sido registrada',
                  'warning'
                )
                this.loading = false;
              }
              
            })

          } else{
            this.loading = false;
          }
        })

  }

  abrirPDFOtraVentana(res) {
    const blob = new Blob([res.body], { type: 'application/pdf' }),
      url = window.URL.createObjectURL(blob);
      window.open(url);
  }
  cerrarModal(accion){ 

    if (accion == 'omitir') {

      Swal.fire({
        title: '¿Omitir la compra?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#E5B700',
        cancelButtonColor: '#717171',
        cancelButtonText:'No',
        confirmButtonText: 'Si, omitir'
      }).then((result) => {
          if (result.isConfirmed) {
            this.limpiaData()
            this.refreshSocios.emit('recargaSocios');
          }        
      })

   }else if (accion == 'cancelar') {

    Swal.fire({
      title: 'Cancelar la compra?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E5B700',
      cancelButtonColor: '#717171',
      cancelButtonText:'No',
      confirmButtonText: 'Si, cancelar'
    }).then((result) => {
        if (result.isConfirmed) {
          this.limpiaData()
          this.cancelarOperacion.emit('recargaSocios');
        }        
    })

 }
   
  else {
     this.limpiaData()
      this.refreshSocios.emit('recargaSocios');
    }

  }

  limpiaData(){

  this.sociosService.cerrarModalAgregarSocio()
  this.creaVentaForm.reset();
  this.creaVentaForm.markAsPristine();
  this.creaVentaForm.markAsUntouched();
  this.periodosData=[];  
  this.idSocio=null;
  this.precio = 0;
  this.subtotal = 0;
  this.nombrePeriodo = 'periodos'
  this.precioInscripcion = 0;
  this.inscripcionStatusLogic = false;

  }

  obtenerMembresias(){
    this.membreciasService.obtenerMembresias().subscribe(({membresias})=>{
      this.membresiasData = membresias;
      
    })
  }

  obtenerPeriodo(e){

    if (e==null || e=='') {
      this.periodosData=[];
      this.subtotal = 0;
      this.precioInscripcion = 0;
      this.precio = 0;
      this.totalAPagar = 0;
      this.creaVentaForm.markAsPristine();
      this.creaVentaForm.markAsUntouched();
      this.creaVentaForm.patchValue({      
      idPeriodoMembrecia:'',
      cantidad:0,
      descuento:0,
      fechaInicio:''
      });

    } else {

      this.periodosData = [];
      const membresia = this.membresiasData.find((res) => res.id == e);
      this.precioInscripcion = membresia.precioInscripcion;
      this.precioInscripcionBackup = membresia.precioInscripcion;
      this.inscripcionStatusLogic = membresia.inscripcionStatus;      

      if (this.inscripcionStatusLogic) {
        this.subtotal = this.precioInscripcion;
        this.totalAPagar = this.precioInscripcion;
      } else {
        this.subtotal = 0;
        this.totalAPagar = 0;
      }
      this.precio = 0;
      this.creaVentaForm.patchValue({      
        idPeriodoMembrecia:'',
        });

      this.periodosData.push(...membresia.periodos.filter(periodo => periodo.activo));

    }

  }

  obtenerPrecio(e){

    const idPeriodoSeleccionado = e.target.value

    if (idPeriodoSeleccionado == "") {
      this.precio=0
      this.subtotal=0
      this.totalAPagar=0
      this.nombrePeriodo = 'periodos'

      this.creaVentaForm.patchValue({      
        cantidad:0,
        descuento:0,
        
        });

    }else{
      for(let periodo of this.periodosData ){
        if (periodo.id == idPeriodoSeleccionado) {
          this.precio = periodo.precio;
          this.subtotal = periodo.precio + this.precioInscripcion;
          this.totalAPagar = this.precio + this.precioInscripcion; 
          

          switch (periodo.key) {
            case 'DIA':
              this.nombrePeriodo = 'dias'
              break;
            case 'SEMANA':
              this.nombrePeriodo = 'semanas'
              break;
            case 'QUINCENA':
              this.nombrePeriodo = 'quincenas'
              break;
            case 'MES':
              this.nombrePeriodo = 'meses'
              break;
            case 'AÑO':
              this.nombrePeriodo = 'años'
              break;
          
            default:
              this.nombrePeriodo = 'periodos'
              break;
          }

          this.creaVentaForm.patchValue({
            cantidad:1,
            descuento:0,

          });
          
        }
      }
    }
    
  }

  calculaSubtotal(e){

    const cantidad = e;
    this.cantidadSeleccionada = e;
    
    if (cantidad==null || cantidad=='' || cantidad<=0) {      
    return;
    } 
  
    this.subtotal = (this.precio * cantidad) + this.precioInscripcion;
    this.totalAPagar = this.subtotal - this.descuento.value;
  }

  calculaDescuento(cantidad){
    
    
    if (cantidad==null || cantidad=='' || cantidad <= 0) {
      this.descuentoValido = false;
      this.totalAPagar = this.subtotal;
    }else {
        this.descuentoValido = true;
        this.totalAPagar = this.subtotal - cantidad;        
      }

  }

  anterior(){
    this.anteriorEmit.emit(this.idSocio);
     
  }

  get idPeriodoMembrecia() {return this.creaVentaForm.get('idPeriodoMembrecia')}
  get cantidad() {return this.creaVentaForm.get('cantidad')}
  get descuento() {return this.creaVentaForm.get('descuento')}
  get fechaInicio() {return this.creaVentaForm.get('fechaInicio')}
  get inscripcionStatus() {return this.creaVentaForm.get('inscripcionStatus')}


}
