import { Component, OnInit } from '@angular/core';
import { GymService } from 'src/app/services/gym.service';

@Component({
  selector: 'modal-payments',
  templateUrl: './modal-payments.component.html',
  styleUrls: ['./modal-payments.component.css']
})
export class ModalPaymentsComponent implements OnInit {
  public showModal:boolean = false;

  constructor(
    public gymService:GymService
  ) { }

  ngOnInit(): void {
  }


  cerrarModal(){
    this.gymService.cerrarModalPayments();
  }

}
