<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <form 
                    #formBusqueda="ngForm"
                >

                    <div class="input-group">
                        <input 
                            type="text" 
                            [(ngModel)]="terminoBusqueda"
                            name="terminoBusqueda"
                            class="form-control" 
                            placeholder="Puedes buscar por nombre o número de socio..."
                            autocomplete="off"
                        >
                        <span class="input-group-btn">
                            <button 
                                class="btn btn-info" 
                                type="submit"
                                (click)="buscar(formBusqueda.value)"
                            >
                                Buscar
                            </button>
                        </span>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="cargando"
>
    <div class="col-12">
        

        <div class="alert alert-info text-center" role="alert">
            <h4>Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0"></p>
        </div>
       
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="!cargando"
>
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        
                        <h4 class="card-title">Socios</h4>
                        <h6 class="card-subtitle">Socios registrados: {{totalItems}}</h6>
                    </div>

                    <div class="col-md-2 text-right">                          
                        <button (click)="abrirModal('agregar', null)" class="btn btn-primary">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            Agregar
                        </button>
                    </div>
                    
                    <div class="col-md-12">
                        <div class="row">

                            <div class="col-md-10 col-sm-12 col-xs-12 d-flex text-left" >
                                <select 
                                class="custom-select custom-select-sm"
                                [(ngModel)]="sociosMembresias"
                                name="sociosMembresias"
                                (change)="filtroSocios($event.target.value)"
                                >
                                <option value="todos">Todos</option>
                                <option value="vencidas">Membresías vencidas</option>
                                <option value="activas">Membresías activas</option>
                                <option value="por-vencer">Membresías próximas a vencer</option>
                                <option value="sin-renovar">Membresías sin renovar</option>
                                <option value="sin-registrar">Sin membresías registradas</option>
                                </select>
                                &nbsp;
                                <div *ngIf="diasDeVencerEstatus" class="">
                                    <label class="m-l-5 m-r-5" for="">a</label>
                                        <input
                                        type="number" 
                                        class="customInputNumber m-l-5 m-r-5 custom-top-less-5"
                                        name="diasDeVencer"
                                        [(ngModel)] ="diasDeVencer"
                                        (keyup.enter)="cargarSociosPorVencer()"
                                        >
                                    <label class="m-l-5" for="">dias de vencer</label>
                                    <button type="button" (click)="cargarSociosPorVencer()" class="m-l-10 btn btn-circle btn-success custom-top-less-5"> <i class="fa fa-search"></i></button>
                                </div>

                                <div *ngIf="mesesSinRenovarEstatus" class="">
                                    <label class="m-l-5 m-r-5" for="">desde hace</label>
                                        <input
                                        type="number" 
                                        class="customInputNumber m-l-5 m-r-5 custom-top-less-5"
                                        name="mesesSinRenovar"
                                        [(ngModel)] ="mesesSinRenovar"
                                        (keyup.enter)="cargarSociosSinRenovar()"
                                        >
                                    <label class="m-l-5" for="">meses</label>
                                    <button type="button" (click)="cargarSociosSinRenovar()" class="m-l-10 btn btn-circle btn-success custom-top-less-5"> <i class="fa fa-search"></i></button>
                                </div>

                            </div>

                    </div>
                        
                    </div>

                    

                </div>
                <div class="table-responsive top-10">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th class="w100">Foto</th>
                                <th class="text-center">N° Socio</th>
                                <th >Nombre(s)</th>
                                <th>Telefono</th>
                                <th>Membresía</th>
                                <!-- <th>Email</th> -->
                                <th class="text-center">Inicio</th>
                                <th class="text-center">Termina</th>
                                <th class="text-center">Resta</th>
                                <th class="text-nowrap text-center w100">QR</th>
                                <th class="text-nowrap text-center w100">Editar</th>
                                <th class="text-nowrap text-center w100">Asistencia</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="sociosData.length>0">
                           <tr *ngFor="let socio of sociosData">
                            <td class="text-center">
                                <img 
                                    *ngIf="socio.urlImgThumbnail!=null" 
                                    [src]="socio.urlImgThumbnail" 
                                    alt="Imagen de perfil" 
                                    class="img-user"
                                 >
                                <img
                                    *ngIf="socio.urlImgThumbnail==null" 
                                    src="../../../assets/images/foto-not-found-black.jpg" 
                                    alt="Imagen de perfil" 
                                    class="img-user"
                                 >
                                 
                            </td>

                            <td class="cursorpointer text-center" routerLink="/dashboard/socio/{{socio.id}}">{{socio.folio == null ? '-----------' : socio.folio}}</td>
                            <td class="cursorpointer" routerLink="/dashboard/socio/{{socio.id}}">{{socio.nombres}} {{socio.apellidoPaterno}} {{socio.apellidoMaterno}}</td>
                            <td class="cursorpointer" routerLink="/dashboard/socio/{{socio.id}}">{{socio.telefono ?  socio.telefono : '-----------'}}</td>
                            <td class="cursorpointer" routerLink="/dashboard/socio/{{socio.id}}">{{socio.nombreMembrecia ?  socio.nombreMembrecia : '-----------'}}</td>
                            <!-- <td *ngIf="!utilsService.verificaCorreo(socio.email)" class="cursorpointer" routerLink="/dashboard/socio/{{socio.id}}">
                                    {{socio.email | slice:0:20}}{{socio.email.length>20? '...': '' }}
                            </td>
                            <td *ngIf="utilsService.verificaCorreo(socio.email)" class="cursorpointer" routerLink="/dashboard/socio/{{socio.id}}">
                                <span class="mytooltip tooltip-effect-2">
                                   ------------------------ 
                                </span>                        
                            </td> -->
                            <td 
                                routerLink="/dashboard/socio/{{socio.id}}" 
                                class="text-center cursorpointer" 
                                *ngIf="socio.fechaInicioMembrecia!=null"
                                >
                                {{socio.fechaInicioMembrecia | date: 'dd/MM/yyyy'}}
                            </td>
                            <td 
                                routerLink="/dashboard/socio/{{socio.id}}" 
                                class="text-center cursorpointer" 
                                *ngIf="socio.fechaInicioMembrecia==null">- - - - - - 
                            </td>
                            <td 
                                routerLink="/dashboard/socio/{{socio.id}}" 
                                class="text-center table-warning cursorpointer" 
                                *ngIf="socio.fechaFinMembrecia!=null">{{socio.fechaFinMembrecia | date: 'dd/MM/yyyy'}}
                            </td>
                            <td 
                                routerLink="/dashboard/socio/{{socio.id}}" 
                                class="text-center cursorpointer" 
                                *ngIf="socio.fechaFinMembrecia==null">- - - - - - 
                            </td>

                            <td 
                                routerLink="/dashboard/socio/{{socio.id}}" 
                                *ngIf="socio.dias>=0" 
                                nowrap class="cursorpointer text-center">{{socio.dias}} dias
                            </td>
                            <td 
                                routerLink="/dashboard/socio/{{socio.id}}" 
                                *ngIf="socio.dias<0" 
                                nowrap class="cursorpointer text-center">0 dias
                            </td>
                            <td class="text-nowrap text-center">

                                <button (click)="descargarQR(socio.id)" type="button" class="btn btn-secondary btn-circle"><i class="fa fa-qrcode"></i> </button>
                                <!-- <button (click)="copiarQR(socio.folio)" type="button" class="m-l-5 btn-info btn-circle"><i class="fa fa-copy"></i> </button> -->
                               
                            </td>
                               
                               <td class="text-nowrap text-center">

                                    <button (click)="abrirModal('editar', socio)" type="button" class="btn btn-info btn-circle"><i class="fa fa-pencil"></i> </button>
                                   
                                </td>
                               
                               <td class="text-nowrap text-center">

                                  
                                    <button 
                                        *ngIf="socio.fechaInicioMembrecia!=null && socio.estatus=='ACTIVO' && !loadingAsistencia" 
                                        (click)="registraAsistencia(socio.folio)" 
                                        type="button" 
                                        class="btn btn-outline-success btn-circle">
                                            <i class="fa fa-check"></i> 
                                    </button>

                                    <button
                                        *ngIf="loadingAsistencia" 
                                        class="btn btn-outline-secondary btn-circle" 
                                        type="button" 
                                        role="menuitem"
                                        disabled
                                        >
                                        <i class="fa fa-spin fa-refresh fa-1x"></i> 
                                    </button>
                                </td>
                            </tr>
                        </tbody>

                        <tbody *ngIf="sociosData.length<=0">
                            <tr>
                                <td colspan="8">
                            <div class="p-20 text-center">
                                No hay resultados de la busqueda
                            </div>

                            </td>

                            </tr>
                        </tbody>

                    </table>

                    <!-- <qrcode #qrcodeElement [qrdata]="'11'" [width]="256" [errorCorrectionLevel]="'M'"></qrcode> -->
                    <!-- <qrcode #qrcodeElement [qrdata]="'qrData'" [width]="256" [errorCorrectionLevel]="'M'"></qrcode> -->

                    <!-- <qrcode (qrCodeURL)="onChangeURL($event)" [elementType]="'img'" [qrdata]="'test'" [title]="'Hola'" [width]="256" [errorCorrectionLevel]="'M'"></qrcode> -->

                    <!-- <qrcode
                        [qrdata]="'https://github.com/Cordobo/angularx-qrcode'"
                        [allowEmptyString]="true"
                        [alt]="'A custom alt attribute'"
                        [ariaLabel]="'QR Code image with the following content...'"
                        [cssClass]="'center'"
                        [colorDark]="'#000000ff'"
                        [colorLight]="'#ffffffff'"
                        [elementType]="'img'"
                        [errorCorrectionLevel]="'M'"
                        [margin]="4"
                        [scale]="1"
                        [title]="'A custom title attribute'"
                        [width]="300"
                    ></qrcode> -->


                    <!-- <a [href]="qrCodeDownloadLink" download="">Download</a> -->

                </div>

                <div class="clearfix"></div>
                <div class="top-20 col-lg-12 col-md-12">                          
                                
                    <mi-paginador
                        *ngIf="!buscandoPorNombre" 
                        (cargaData)="seleccionaItemsPorPagina($event)"
                        (numeroDePaginaEmit)="seleccionaPagina($event)"
                        [totalPaginasArray]="totalPaginasArray || 0"    
                        [itemsPorPaginaInput]="itemsPorPagina"    
                        [numeroDePaginaInput]="numeroDePagina || 0"    
                        [desdeInput]="desde || 0"    
                        [hastaInput]="hasta || 5"    
                        >
                    </mi-paginador>
                
                </div>

            </div>
        </div>
    </div>
</div>

<modal-agregar-socio 
    [idSocioInput]="idSocioInput || null"
    (refreshSocios)="cargarSocios()"
    >
</modal-agregar-socio>

