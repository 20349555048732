<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                
                <form 
                    #actualizarSocioForm="ngForm"
                    class="tab-wizard wizard-circle wizard clearfix" 
                    role="application" 
                    id="steps-uid-0u"
                >
                    <div class="input-group">
                        <input 
                            type="text" 
                            (keyup.enter)="buscarServicio(servicioTxt.value)" 
                            #servicioTxt=ngModel
                            [(ngModel)]=servicio.nombre 
                            class="form-control" 
                            name="nombre"
                            autocomplete="off"
                            placeholder="Escribe servicio que deseas buscar (pesas, spinning, zumba, etc)..."
                        >
                        
                        <span class="input-group-btn">
                        
                            <button class="btn btn-info" type="button" (click)="buscarServicio(servicioTxt.value)">
                                Ir!
                            </button>
                        
                        </span>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>



<div class="row animated fadeIn fast" *ngIf="cargando">
    <div class="col-12">
        

        <div class="alert alert-info text-center" role="alert">
            <h4>Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0"></p>
        </div>
       
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="!cargando">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">

                    <div class="col-md-8">
                        <h4 class="card-title">Servicios de mi gym</h4>
                        <h6 class="card-subtitle">Servicios registrados </h6>
                    </div>

                    <div class="col-md-4 text-right">
                        <button type="button" (click)="agregar()" data-toggle="tooltip" data-original-title="Edit" class="btn btn-primary">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            Crear servicio
                        </button>
                    </div>

                </div>

                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th class="text-nowrap w100">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let servicio of serviciosData">

                                <td>                                  
                                    {{servicio.nombre}}
                                </td>
                               
                               <td class="text-nowrap">
                                    <button (click)="editarServicio(servicio)" type="button" class="btn btn-info btn-circle"><i class="fa fa-pencil"></i> </button>
                                    &nbsp;
                                    <button (click)="eliminarServicio(servicio.id)" type="button" class="btn btn-danger btn-circle"><i class="fa fa-trash"></i> </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</div>