import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultimediaService } from 'src/app/services/multimedia.service';
import { SociosService } from 'src/app/services/socios.service';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.css']
})
export class PhotoComponent implements OnInit {

  public loading:boolean = false;
  // public botonCargarFoto:boolean = false;
  public imgPreview:any='';
  public imgPreviewBack:any='';
  public fotoAsubir:any=[]
  public myUrlImg:string='' 
  public creaSocioForm:any
  public uploadPercentage:any=0

  @Output() fotoCargada = new EventEmitter<string>();
  @Output() loadingStatus = new EventEmitter<boolean>();
  @Input() botonCargarFoto = false;
  @Input() urlImg = '';


  constructor(
    public sociosService:SociosService,
    public multimediaService:MultimediaService

  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {

    if (!this.botonCargarFoto) {
      this.quitarImagen();
    }

    if (this.urlImg != '' || this.urlImg != null) {
      this.imgPreview = this.urlImg;
      this.imgPreviewBack = this.urlImg;
    }
    
    
  }

  quitarImagen(){

    this.fotoAsubir = [];   
    this.fotoCargada.emit(null);

    
    if (this.imgPreviewBack!='' || this.imgPreviewBack!=null) {
      this.imgPreview = this.imgPreviewBack
    }else{
      this.imgPreview='';
    }

  }


  cambiarImagen(event){
    this.loading=true;
    this.loadingStatus.emit(true);

    const file  = event.target.files[0]
    
    
    if (!file) {
      if (this.imgPreview) {
        this.loading=false;
        this.loadingStatus.emit(false);



        return this.imgPreview
      }else{
        this.loading=false;
        this.loadingStatus.emit(false);

        return this.imgPreview='';
      }

    }else{
      this.fotoAsubir.push(file);
      this.subirImagen(file);
    }

  }

  subirImagen(file){
    
    try {

      const formImagen = new FormData();
      
      this.fotoAsubir.forEach(miFoto => {
        formImagen.append('file', miFoto)
        
      });
      

      this.multimediaService.subirFoto(formImagen)
        .subscribe(({respuesta,cargaBytes,cargaTotal}) => {
          
          if (respuesta) {

            this.myUrlImg = respuesta.data;
          
            this.fotoCargada.emit(this.myUrlImg);

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend=()=>{
              this.imgPreview=reader.result;
            }
            
            this.loading=false;
            this.loadingStatus.emit(false);

            this.fotoAsubir = [];   
            
          }
    
          const carga = parseInt(cargaBytes)
          const total = parseInt(cargaTotal)

          this.uploadPercentage =  Math.round( ( carga / total ) * 100 )
          
      })

    } catch (error) {


      this.loading=false;
      this.loadingStatus.emit(false);

      this.fotoAsubir = [];
      this.imgPreview='';

    }
    
  }

}
