import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SiderbarService {

  public menu=[];

  // public menu:any[]=[
  //   {
  //     titulo: 'Asistencias',
  //     icono: 'fa fa-check-square-o',
  //     submenu:[
  //       {titulo: 'Check', url: '../check-asistencias'},
  //       {titulo: 'Asistencias generales', url: 'asistencias'}
  //     ],
      
  //   },
    
  //   {
  //     titulo: 'Ventas',
  //     icono: 'fa fa-tags',
  //     submenu:[
  //       {titulo: 'Membresia', url: 'venta-membresias'},
  //       {titulo: 'Productos', url: 'venta-insumo'},
  //     ],
      
  //   },
  //   {
  //     titulo: 'Socios',
  //     icono: 'fa fa-users',
  //     submenu:[
  //       {titulo: 'Mis socios', url: 'socios'},
  //     ],
      
  //   },
  //   {
  //     titulo: 'Productos',
  //     icono: 'fa fa-barcode',
  //     submenu:[
  //       {titulo: 'Mis productos', url: 'productos'},
  //     ],
  //   },

  //   {
  //     titulo: 'Membresías',
  //     icono: 'fa fa-address-card',
  //     submenu:[
  //       {titulo: 'Mis membresías', url: 'membresias'},
  //       {titulo: 'Mis servicios', url: 'servicios'},
  //     ],
      
  //   },
  //   {
  //     titulo: 'Usuarios',
  //     icono: 'fa fa-user-circle-o',
  //     submenu:[
  //       {titulo: 'Mis encargados', url: 'encargados'},
  //       {titulo: 'Mis administradores', url: 'administradores'}
  //     ],
      
  //   },
  //   {
  //     titulo: 'Gastos',
  //     icono: 'fa fa-minus-square',
  //     submenu:[
  //       {titulo: 'Mis gastos', url: 'gastos'},
  //       {titulo: 'Categorías', url: 'categorias'},
  //     ],
  //   },
  //   {
  //     titulo: 'Corte',
  //     icono: 'fa fa-key',
  //     submenu:[
  //       {titulo: 'Corte de productos', url: 'corte-productos'},
  //       {titulo: 'Corte de membresías', url: 'corte-membresias'},
  //     ],
  //   },
  // ]

  constructor() { 
    this.menu = [];
    this.menu=JSON.parse(localStorage.getItem('menu')) || [];  
  }



}
