<div class="fondo-modal-imagen animated fadeIn fast" [class.oculto]="modalImgService.ocultarModal" >
    <div 
    id="myModal" 
    class="modal fade show" 
    tabindex="-1" 
    role="dialog" 
    aria-labelledby="myModalLabel" 
    style="display: block;"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel">Cargar imagen</h4>
                    <button type="button" class="close cursorpointer" (click)="cerrarModal()" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                    
                        <img *ngIf="!imgPreview" [src]="modalImgService.img" class="img-perfil" alt="">
                        <img *ngIf="imgPreview" [src]="imgPreview" class="img-perfil" alt="">
                        
                    </div>
                    <br><br>
                    <input type="file"
                           (change)="cambiarImagen($event.target.files[0])"
                            
                    >
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-warning waves-effect" (click)="cerrarModal()">Cancelar</button>
                    <button type="button" class="btn btn-success waves-effect" (click)="subirImagen()">Guardar cambios</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</div>
 