import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';


const base_url = environment.base_url;


@Injectable({
  providedIn: 'root'
})
export class MultimediaService {

  constructor(
    private http:HttpClient
  ) { }


  get token():string{
    return localStorage.getItem('token') || '';
  }

  subirFoto(params){

    const url = `${base_url}/api_multimedia/temporal/api/file/upload`;

        return this.http.post(url, params,{
            reportProgress: true,
            observe: 'events',
            headers: new HttpHeaders(
              { "Authorization": `Bearer ${this.token}` })
          }).pipe(
            map((res:any)=>{
              const respuesta = res.body
              const cargaBytes = res.loaded
              const cargaTotal = res.total
      
                  
                  return {
                    respuesta,
                    cargaBytes,
                    cargaTotal
                  };
              
            }))

  }


}
