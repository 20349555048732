import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const base_url = environment.base_url;

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  
  private _ocultarModalProducto:boolean=true;
  private _ocultarModalStock:boolean=true;

  constructor(
    private http:HttpClient
  ) { }

  get token():string{
    return localStorage.getItem('token') || '';
  }

  get headers(){
    return{
            headers:{
              "Authorization": `Bearer ${this.token}`
            }
         } 
   }

   get headersTopdf(){
    return{
            headers:new HttpHeaders({
              'Content-Type':  'application/json',
              responseType : 'blob',
              Accept : 'application/pdf',
              observe : 'response'
              }),
         } 
   }

  get ocultarModalProducto(){
    return this._ocultarModalProducto;
  }

  abrirModalProducto(){
     this._ocultarModalProducto = false;
  }
  cerrarModalProducto(){
    this._ocultarModalProducto = true;
  }
  
  get ocultarModalStock(){
    return this._ocultarModalStock;
  }

  abrirModalStock(){
     this._ocultarModalStock = false;
  }
  cerrarModalStock(){
    this._ocultarModalStock = true;
  }


  crearProducto(params:any){

      const url = `${base_url}/api_productos/crear`;

        return this.http.post(url, params, this.headers).pipe(
          map((res:any)=>{
            
            // const res:number = res.data

                return {
                  res
                };
            
          })
        )
  }

  obtenerProductos(numeroDePagina:number ,itemsPorPagina:number){

    const url = `${base_url}/api_productos/${numeroDePagina}/${itemsPorPagina}`;

    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const productos = res.data
        const infoPaginacion = res.pageInfo
            
            return {
              productos,
              infoPaginacion
            };
        
      })
    )

  }

  obtenerProductosTop(itemsTop:number){

    const url = `${base_url}/api_productos/venta/top/${itemsTop}`;

    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{
        return res.data        
      })
    )

  }

  obtenerProducto(id){
    
    const url = `${base_url}/api_productos/${id}`;
    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{
        const producto = res.data
            
            return {
              producto
            };
        
      })
    )

  }

  obtenerProductoPorNombre(nombre){
    
    const url = `${base_url}/api_productos/search?text=${nombre}`;
    
    return this.http.get(url, this.headers)
    .pipe(
      map((res:any)=>{
        const producto:any[] = res
            
            return producto;
        
      }),catchError(this.manejarError)
      
    )

  }

  manejarError(error:HttpErrorResponse){
    console.log(error);

    return throwError('Hubo un error en la peticion')
    
  }


  manejarError500(error:HttpErrorResponse){
    console.log(error);

    return throwError('Hubo un error, contacte al admin')
    
  }

  modificarProducto(id, formData){
    const url = `${base_url}/api_productos/${id}`;
    
    return this.http.put(url, formData, this.headers);

  }

  eliminarProducto(id:number){
    const url = `${base_url}/api_productos/${id}`;
    return this.http.delete(url, this.headers).pipe(
      map((res:any)=>{
            return res;
      }),catchError(this.manejarError500)
      
    )
  }
  eliminarVentaProducto(id:number){
    const url = `${base_url}/api_productos/venta/${id}`;
    return this.http.delete(url, this.headers).pipe(
      map((res:any)=>{
            return res;
      }),catchError(this.manejarError500)
      
    )
  }
  

  cambiaStock(id:number, params:any){
    const url = `${base_url}/api_productos/${id}`;
      return this.http.patch(url, params, this.headers).pipe(
        map((res:any)=>{
              return {
                res
              };
        }),catchError(this.manejarError500)
      )
  }

  ventaProductos(params){

      const url = `${base_url}/api_productos/venta/nuevo/ticket`;

        return this.http.post(url, params,{
          responseType: 'blob',
          observe: 'response',
          headers: new HttpHeaders(
            { 
              "Authorization": `Bearer ${this.token}`,
              "Accept" : 'application/pdf',
              'Content-Type':  'application/json'
          })
        });
  }

  obtenerVentaProductos(numeroDePagina:number, itemsPorPagina:number, params){
    
    const url = `${base_url}/api_productos/venta/corte/${numeroDePagina}/${itemsPorPagina}?fechaInicio=${params.fechaInicio}&&fechaFin=${params.fechaFin}`;
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const ventaProductos = res.data
        const infoPaginacion = res.pageInfo
        
            return {
              ventaProductos,
              infoPaginacion,
              res
            };
        
      })
    )
  }
  obtenerResumenVentaProductos(numeroDePagina:number, itemsPorPagina:number, params){
    const url = `${base_url}/api_productos/venta/corte/resumen/${numeroDePagina}/${itemsPorPagina}?fechaInicio=${params.fechaInicio}&&fechaFin=${params.fechaFin}`;
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const ventaProductos = res.data
        const infoPaginacion = res.pageInfo
        
            return {
              ventaProductos,
              infoPaginacion,
              res
            };
        
      })
    )
  }

  descargarCorteProductos(params){
    const url = `${base_url}/api_productos/venta/corte/pdf?fechaInicio=${params.fechaInicio}&&fechaFin=${params.fechaFin}`;
    return this.http.get(url, {
      responseType: 'blob',
      observe: 'response',
      headers: new HttpHeaders(
        { 
          "Authorization": `Bearer ${this.token}`,
          "Accept" : 'application/pdf',
          'Content-Type':  'application/json'
      })
    })
  }

} //fin
