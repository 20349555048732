import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

const base_url =environment.base_url;
@Pipe({
  name: 'imagen'
})
export class ImagenPipe implements PipeTransform {

  transform(img: string='not-found', tipo: 'usuarios'|'medicos'|'hospitales'): string {

    if (!img) {
      return `${base_url}/upload/usuarios/not-found`
  }else if (img.includes('https')) {
      return img
  }else if (img) {
      
      return `${base_url}/upload/${tipo}/${img}`
      
  }else{
      
      return `${base_url}/upload/usuarios/not-found`
       
  }
    
    // return `Hola pipe ${img} y hola ${usuario}`;
  }

}
