import { Component, OnInit } from '@angular/core';
import { Socio } from 'src/app/models/socio.model';
import { GastoService } from 'src/app/services/gastos.service';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import { UtilsService } from 'src/app/services/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-gastos',
  templateUrl: './gastos.component.html',
  styleUrls: ['./gastos.component.css']
})
export class GastosComponent implements OnInit {

  public gastosData:any[]=[];
  public cargando:boolean=true;
  public buscandoPorNombre:boolean=false;

  public totalGeneral:number=0;
  public totalPorPagina:number=0;
  public desde:number=0;
  public hasta:number=5;
  public infoPaginacion:any;
  public totalItems:number=0;
  public numeroDePagina:number=0;
  public selectedCategoriaId:number=0;
  public itemsPorPagina:number=10;
  public totalPaginasInt:number=1;
  public totalPaginasArray:number[]=[];
  public idGastoInput:Object=null;
  public productoInput:Object=null;
  public terminoBusqueda:string=''
  public socio:Socio=null;
  public filtroPorVencer:boolean=false;
  public filtroSinRenovar:boolean=false;
  public extraData:any = {}
  public idsCategoria:any = 0;
  public cargandoCategorias:boolean = false
  public categoriasData:any=null;
  public colorClass: string = 'label-info';
  

  constructor(
    private gastosService:GastoService,
    private mensajesSwalService:MensajesSwalService,
    public utilsService:UtilsService
  ) { }

  ngOnInit(): void {
    this.obtenerCategorias();
    const fechaActual = this.utilsService.obtenerFechaActual();
    this.extraData.fechaInicio = fechaActual
    this.extraData.fechaFin = fechaActual
    this.extraData.idsCategoria = 0
    this.cargarGastos();
  }

  obtenerCategorias(){
    
      this.cargandoCategorias=true; 
      this.gastosService.obtenerCategorias(0, 100)
      .subscribe(({categorias})=>{
  
        this.categoriasData = categorias;        
        this.cargandoCategorias=false;
  
      })

  }

  seleccionarCategoria(e){
    this.extraData.idsCategoria = e;
    this.cargarGastos();
  }

  dateStart(e){
    this.extraData.fechaInicio = e;
    this.cargarGastos();

  }
  dateEnd(e){
    this.extraData.fechaFin = e;
    this.cargarGastos();

  }

  buscar(busqueda):void{
    

    const terminoTxt = busqueda.terminoBusqueda;
    this.cargando=true;
    this.buscandoPorNombre=true

    if (terminoTxt=='') {
      
      this.cargarGastos();

    }else{

        this.gastosService.obtenerGastosPorNombre(terminoTxt).subscribe(gasto=>{
        
          this.gastosData = gasto;

          this.cargando=false;

        },()=>{
          this.cargando=false
        })
              
    }

    
  }

  abrirModal(tipoDeModal, gasto){
    
    if (tipoDeModal == 'agregar' ) {
      this.gastosService.abrirModalGastos()

    }else if (tipoDeModal == 'editar') {
      
      this.idGastoInput = {
                            gastoID: gasto.id,
                            operacion:'editar'

                          }

      this.gastosService.abrirModalGastos()

    }
    
  }

  cambiarStock(producto){

    this.productoInput = producto
    this.gastosService.abrirModalStock();
    
 
   }


  cargarGastos(){
    
    this.idGastoInput=null;
    this.buscandoPorNombre=false
    this.cargando=true;

  
      this.gastosService.obtenerGastos(this.numeroDePagina, this.itemsPorPagina, this.extraData)
      .subscribe(({gastos, infoPaginacion, extraInfo})=>{
  
        this.totalPorPagina = 0;
        this.gastosData = gastos;       
        this.totalGeneral = extraInfo.total;
        this.infoPaginacion = infoPaginacion
        this.totalPaginasInt = infoPaginacion.totalPages
        this.totalPaginasArray=[]
        this.totalItems= infoPaginacion.totalElements

        for (const gasto of this.gastosData) {
          this.totalPorPagina = this.totalPorPagina + gasto.monto;
        }
  
        
        for (let i = 0; i < this.totalPaginasInt; i++) {
            this.totalPaginasArray.push(i);
          }
        
        this.cargando=false;
  
      })

  }


  eliminarGasto(gasto){

    Swal.fire({
      title: '¿Eliminar producto?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#E5B700',
      cancelButtonColor: '#717171',
      cancelButtonText:'No',
      confirmButtonText: 'Si'
    }).then((result) => {

        if (result.isConfirmed) {

          this.gastosService.eliminarGastos(gasto.id).subscribe(res=>{
      
            this.mensajesSwalService.eliminadoConExito();
            this.cargarGastos();
          })

        }        
     })

  }





  
  seleccionaItemsPorPagina(e){

    this.itemsPorPagina = e;
    this.cargarGastos();
    
  }

  seleccionaPagina(data){

    this.numeroDePagina =  data.paginaSeleccionada;
    this.desde =  data.desde;
    this.hasta =  data.hasta;
    this.cargarGastos();
  }


  filtroSocios(e){

    switch (e) {
      case 'todos':
        this.filtroPorVencer=false;
        this.filtroSinRenovar=false;
        break;
      case 'vencidas':

        this.filtroPorVencer=false;
        this.filtroSinRenovar=false;
        
        break;
      case 'por-vencer':

        this.filtroPorVencer=true;
        this.filtroSinRenovar=false;
        
        break;
      case 'sin-renovar':
        
        this.filtroPorVencer=false;
        this.filtroSinRenovar=true;

        break;
    
      default:
        break;
    }

    
  }

}
