import { Component, OnInit } from '@angular/core';
import { Socio } from 'src/app/models/socio.model';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import { SociosService } from 'src/app/services/socios.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styles: [
  ]
})
export class UsuariosComponent implements OnInit {


  public sociosData:Socio[]=[];
  public newFeatures:boolean=false;
  public cargando:boolean=true;
  public buscandoPorNombre:boolean=false;
  public isActive:boolean=true;

  public desde:number=0;
  public hasta:number=5;
  public infoPaginacion:any;
  public totalItems:number=0;
  public numeroDePagina:number=0;
  public itemsPorPagina:number=10;
  public totalPaginasInt:number=1;
  public totalPaginasArray:number[]=[];
  public idSocioInput:Object=null;
  public usuarioInput:Object=null;
  public terminoBusqueda:string=''
  public socio:Socio=null;
  public filtroPorVencer:boolean=false;
  public filtroSinRenovar:boolean=false;
          

  constructor(
    private sociosService:SociosService,
    private usuariosService:UsuarioService,
    private mensajesSwalService:MensajesSwalService
  ) { }

  ngOnInit(): void {
    this.cargarUsuarios()
  }


  buscar(busqueda):void{
    
    const terminoTxt = busqueda.terminoBusqueda;
    this.cargando=true;
    this.buscandoPorNombre=true

    if (terminoTxt=='') {
      
      this.cargarUsuarios();

    }else{

        this.usuariosService.obtenerEncargadoPorNombre(terminoTxt).subscribe(socios=>{
        
          this.sociosData = socios;

          this.cargando=false;

        },()=>{
          this.cargando=false
        })
              
    }

    
  }

  abrirModal(tipoDeModal, socio){
    
    if (tipoDeModal == 'agregar' ) {
      this.usuariosService.abrirModalAgregarUsuario()
    }else if (tipoDeModal == 'editar') {
      
      this.idSocioInput = {
                            socioID: socio.id,
                            operacion:'editar'

                          }

      this.usuariosService.abrirModalAgregarUsuario()

    }else if (tipoDeModal == 'comprar') {
      
      this.idSocioInput = {
        socioID: socio.id,
        operacion:'comprar'

      }

      this.usuariosService.abrirModalAgregarUsuario()

      
    }
    
  }

  abrirModalPermisos(socio){
    this.usuariosService.abrirModalPermisos();
    this.usuarioInput = socio;
  }


  eliminarEncargado(id){

    Swal.fire({
      title: '¿Eliminar encargado?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#E5B700',
      cancelButtonColor: '#717171',
      cancelButtonText:'No',
      confirmButtonText: 'Si'
    }).then((result) => {

        if (result.isConfirmed) {

          this.usuariosService.eliminarEncargado(id).subscribe(res=>{
      
            this.mensajesSwalService.eliminadoConExito();
            this.cargarUsuarios();
          })

        }        
     })

  }

  cargarSocios(){
    
    this.idSocioInput=null;
    this.buscandoPorNombre=false
    this.cargando=true;

  
      this.sociosService.obtenerSocios(this.numeroDePagina, this.itemsPorPagina)
      .subscribe(({socios, infoPaginacion})=>{
  
        this.sociosData = socios;
        
        this.infoPaginacion = infoPaginacion
        this.totalPaginasInt = infoPaginacion.totalPages
        this.totalPaginasArray=[]
        this.totalItems= infoPaginacion.totalElements
  
        
        for (let i = 0; i < this.totalPaginasInt; i++) {
            this.totalPaginasArray.push(i);
          }
  
        
        this.cargando=false;
  
      })

  }
  cargarUsuarios(){
    
    this.idSocioInput=null;
    this.buscandoPorNombre=false
    this.cargando=true;

  
      this.usuariosService.obtenerUsuarios(this.numeroDePagina, this.itemsPorPagina)
      .subscribe(({socios, infoPaginacion})=>{
  
        this.sociosData = socios;

        
        
        this.infoPaginacion = infoPaginacion
        this.totalPaginasInt = infoPaginacion.totalPages
        this.totalPaginasArray=[]
        this.totalItems= infoPaginacion.totalElements
  
        
        for (let i = 0; i < this.totalPaginasInt; i++) {
            this.totalPaginasArray.push(i);
          }
  
        
        this.cargando=false;
  
      })

  }

  cargarSociosPorFotos(){
    this.idSocioInput=null;
    this.buscandoPorNombre=false
    this.cargando=true;

    setTimeout(() => {
      this.sociosService.obtenerSocios(this.numeroDePagina, this.itemsPorPagina)
      .subscribe(({socios, infoPaginacion})=>{
  
        this.sociosData = socios;
        
        this.infoPaginacion = infoPaginacion
        this.totalPaginasInt = infoPaginacion.totalPages
        this.totalPaginasArray=[]
        this.totalItems= infoPaginacion.totalElements
  
        
        for (let i = 0; i < this.totalPaginasInt; i++) {
            this.totalPaginasArray.push(i);
          }
  
        
        this.cargando=false;
  
      })
    }, 1500);

  }
  
  seleccionaItemsPorPagina(e){

    this.itemsPorPagina = e;
    this.cargarSocios();
    
  }

  seleccionaPagina(data){

    this.numeroDePagina =  data.paginaSeleccionada;
    this.desde =  data.desde;
    this.hasta =  data.hasta;
    this.cargarSocios();
  }

  registraAsistencia(id){

    
    this.sociosService.registrarAsistencia(id).subscribe(res=>{

      this.mensajesSwalService.registrarAsistencia();

      
    })
  }

  filtroSocios(e){

    switch (e) {
      case 'todos':
        this.filtroPorVencer=false;
        this.filtroSinRenovar=false;
        break;
      case 'vencidas':

        this.filtroPorVencer=false;
        this.filtroSinRenovar=false;
        
        break;
      case 'por-vencer':

        this.filtroPorVencer=true;
        this.filtroSinRenovar=false;
        
        break;
      case 'sin-renovar':
        
        this.filtroPorVencer=false;
        this.filtroSinRenovar=true;

        break;
    
      default:
        break;
    }

    
  }
  
}
