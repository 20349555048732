<div class="row">
    <div class="col">
        <div class="card">
            <div class="card-body">

                <h4 class="card-title">Usuarios</h4>
                
                <div *ngIf="usuarios.length==0" class="row animated fadeIn fast">
                    <div class="col-12">
                        

                        <div class="alert alert-info text-center" role="alert">
                            <h4>No hay información</h4>
                            <!-- <i class="fa fa-spin fa-refresh fa-2x"></i> -->
                            <p class="mb-0"></p>
                        </div>
                    
                    </div>
                </div>


                <div class="table-responsive"
                     *ngIf="usuarios.length>0"    
                >
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Foto</th>
                                <th>Nombre</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr *ngFor="let usuario of usuarios">
                                <td>
                                    <img [src]="usuario.img | imagen:'usuarios'" 
                                         alt=""
                                         class="w70"
                                         >
                                </td>
                                <td>{{usuario.nombre}}</td>
                            </tr>


                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>

    <div class="col">
        <div class="card">
            <div class="card-body">

                <h4 class="card-title">Hospitales</h4>

                <div *ngIf="hospitales.length==0" class="row animated fadeIn fast">
                    <div class="col-12">
                        

                        <div class="alert alert-info text-center" role="alert">
                            <h4>No hay información</h4>
                            <!-- <i class="fa fa-spin fa-refresh fa-2x"></i> -->
                            <p class="mb-0"></p>
                        </div>
                    
                    </div>
                </div>

                <div class="table-responsive"
                    *ngIf="hospitales.length>0"    
                >
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Foto</th>
                                <th>Nombre</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let hospital of hospitales">
                                <td>
                                    <img [src]="hospital.img | imagen:'hospitales'" 
                                            alt=""
                                            class="w70"
                                            >
                                </td>
                                <td>{{hospital.nombre}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>

    <div class="col">
        <div class="card">
            <div class="card-body">

                <h4 class="card-title">Médicos</h4>

                <div *ngIf="medicos.length==0" class="row animated fadeIn fast">
                    <div class="col-12">
                        

                        <div class="alert alert-info text-center" role="alert">
                            <h4>No hay información</h4>
                            <!-- <i class="fa fa-spin fa-refresh fa-2x"></i> -->
                            <p class="mb-0"></p>
                        </div>
                    
                    </div>
                </div>


                <div class="table-responsive"
                *ngIf="medicos.length>0"
                >
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Foto</th>
                                <th>Nombre</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let medico of medicos">
                                <td>
                                    <img [src]="medico.img | imagen:'medicos'" 
                                            alt=""
                                            class="w70"
                                            >
                                </td>
                                <td>
                                    <a [routerLink] = "['/dashboard/medico', medico.uid]">
                                        {{medico.nombre}}
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>

</div>