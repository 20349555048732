<div class="card-body wizard-content">

    <form 
        [formGroup]="creaSocioForm"    
        (ngSubmit)="crearSocio()" 
        class="tab-wizard wizard-circle wizard clearfix" 
        role="application"
    >
    
        
        <div 
        *ngIf="!modoEditar"
        class="steps clearfix">
                
                <ul role="tablist">
                    <li 
                        role="tab" 
                        class="first current" 
                        aria-disabled="false" 
                        aria-selected="true"
                        >
                        <a id="steps-uid-0-t-0" aria-controls="steps-uid-0-p-0">
                            <!-- <span class="current-info audible">current step: </span> -->
                            <span class="step">
                                1
                            </span> Información personal
                        </a>
                    </li>

                    <li 
                    role="tab" 
                    class="last current" 
                    aria-disabled="false" aria-selected="false"
                    >
                    <a id="steps-uid-0-t-1" aria-controls="steps-uid-0-p-1">
                        <span class="step">
                            2
                        </span> Membresía
                    </a>
                </li>
                    
                </ul>
        
        </div>
        
    
        <div class="content clearfix">

        <!-- Step 1 -->
        <section 
            id="steps-uid-0-p-0" 
            role="tabpanel" 
            aria-labelledby="steps-uid-0-h-0" 
            class="body" 
            aria-hidden="false"
            >
            
            <div class="row">
                   
                <div class="col-md-4 col-sm-6">
                    <div class="form-group">
                        
                        <div class="text-center">
                            <img *ngIf="!imgPreview" src="../../../../assets/images/foto-not-found-black.jpg" class="my-foto img-fluid" alt="">
                            <img *ngIf="imgPreview" [src]=imgPreview class="my-foto img-fluid" alt="">
                        </div>
                            
                        <div class="text-center">
                            <label for="idSelectFoto" class="mylabelFoto">
                                <div *ngIf="!loading" type="button" class="btn btn-info btn-sm btn-block">
                                    <i class="ti-settings text"></i>
                                    <span class="text"> Selecciona foto </span>
                                </div>
                            </label>
                            &nbsp;
                            <button (click)="quitarImagen()" type="button" *ngIf="!loading" class="btn btn-sm btn-outline-secondary">
                                <i class="fa fa-trash-o"></i>
                            </button>
                        </div>


                        <div *ngIf="loading" type="button" class="myloading animated fadeIn fast btn btn-secondary btn-sm btn-block">
                            <i class="fa fa-spin fa-refresh fa-1x"></i>
                            <span class="text"> Cargando ... </span>
                        </div>
                        <input 
                            type="file"
                            (change)="cambiarImagen($event)"  
                            id="idSelectFoto"
                        >       
                    </div>
                </div>

                <div class="col-md-8 col-sm-6">
                    <div class="row">

                        <div class="col-md-6 col-sm-12">
                            <div class="form-group">
                            <label for="nombres">*Nombre(s) :</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                id="nombres"
                                formControlName="nombres"
                                autocomplete="off"
                                autofocus
                                >    
                                
                                <div 
                                    *ngIf="nombres.invalid && (nombres.dirty || nombres.touched)" 
                                    class="myalert" 
                                    role="alert"
                                    >
                                    <span>*Es necesario el campo nombre(s)</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="app">*Apellido paterno:</label>
                            <input 
                                type="text" 
                                class="form-control floating-labels" 
                                id="app"
                                formControlName="apellidoPaterno"
                                autocomplete="off"

                            >

                                <div 
                                    *ngIf="apellidoPaterno.invalid && (apellidoPaterno.dirty || apellidoPaterno.touched)" 
                                    class="myalert" 
                                    role="alert"
                                    >
                                    <span>*Es necesario el campo apellido paterno</span>
                                </div>
                        </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <label for="apm">Apellido materno :</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                id="apm"
                                formControlName="apellidoMaterno"
                                autocomplete="off"

                            >

                        </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="fechaNacimiento">Fecha de nacimiento :</label>
                                <input 
                                    type="date" 
                                    class="form-control" 
                                    id="fechaNacimiento"
                                    formControlName="fechaNacimiento"
                                > 
                            <!-- <div 
                                *ngIf="fechaNacimiento.invalid && (fechaNacimiento.dirty || fechaNacimiento.touched)" 
                                class="myalert" 
                                role="alert"
                                >
                                <span>*Es necesario el campo fecha de nacimiento</span>
                            </div> -->
                            
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div class="form-group">
                                <label class="control-label">*Sexo</label>
                                <select 
                                    class="form-control custom-select" 
                                    formControlName="sexo"
                                >
                                    <option value="Masculino">Masculino</option>
                                    <option value="Femenino">Femenino</option>
                                </select>
                                <div 
                                    *ngIf="sexo.invalid && (sexo.dirty || sexo.touched)" 
                                    class="myalert" 
                 
                                    role="alert"
                                >
                                <span>*Es necesario el campo fecha de nacimiento</span>
                                </div>
                                
                            </div>
                        </div>      
                        <div class="col-md-6 col-sm-12">

                            <div class="form-group">
                                <label for="email">Email :</label>
                                <input 
                                    type="email" 
                                    class="form-control" 
                                    id="email"
                                    formControlName="email" 
                                    (change)="compruebaEmail($event)"
                                    autocomplete="off"
                                >
                                <div 
                                    *ngIf="email.invalid && (email.dirty || email.touched)" 
                                    class="myalert" 
                                    role="alert"
                                >
                                <span>*El campo email debe tener el formato ejemplo@mail.com</span>
                                </div>
                                <div 
                                    *ngIf="!correoValido" 
                                    class="myalert" 
                                    role="alert"
                                >
                                <span>*El email ya se encuentra registrado</span>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-6 col-sm-12">

                            <div class="form-group">
                                <label for="telefono">Telefono :</label>
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    id="telefono"
                                    formControlName="telefono"
                                    autocomplete="off"

                                >
                                <div 
                                    *ngIf="telefono.invalid && (telefono.dirty || telefono.touched)" 
                                    class="myalert" 
                                    role="alert"
                                >
                                
                                <span *ngIf="creaSocioForm.controls['telefono'].hasError('required')">*El campo telefono es requerido </span>
                                <span *ngIf="creaSocioForm.controls['telefono'].hasError('minlength')">*Es telefono requiere mínimo 10 números</span>
                                <span *ngIf="creaSocioForm.controls['telefono'].hasError('maxlength')">*Es telefono requiere máximo 10 números</span>
                               

                                <!-- Si se requier validar con un patron como email "pattern" -->
                                <!-- https://www.it-swarm-es.com/es/forms/angular-4-validadores-de-formulario-minlength-y-maxlength-no-funcionan-en-el-numero-de-tipo-de-campo/834379831/ -->
                            </div> 
                            </div>

                        </div> 

                    </div>
                </div>

            </div>                                
        </section>
        
        
        </div>
    
        <div class="actions clearfix">

            <ul role="menu" aria-label="Pagination">

                <li>
                    <button
                        *ngIf="!modoEditar" 
                        type="button"
                        (click)="cerrarModal()"
                        class="btn btn-inverse waves-effect text-left"
                        
                        >
                        Cancelar
                    </button>
                    <button
                        *ngIf="modoEditar" 
                        type="button"
                        (click)="cerrarModalEditar()"
                        class="btn btn-inverse waves-effect text-left"
                        
                        >
                        Cancelar
                    </button>
                </li>
            
                
                <li 
                    aria-hidden="false" 
                    aria-disabled="false"
                >
                    <button
                        *ngIf="!modoEditar && !loading"
                        [disabled]="creaSocioForm.invalid || !correoValido"
                        class="btn btn-info" 
                        type="submit" 
                        role="menuitem"
                        >
                        Siguiente
                    </button>
                    <button
                        *ngIf="!modoEditar && loading"
                        disabled
                        class="btn btn-info" 
                        type="button" 
                        role="menuitem"
                        >
                        <i class="fa fa-spin fa-refresh fa-1x"></i> Esperando ... 
                    </button>
                    <button
                        *ngIf="modoEditar && !loading"
                        [disabled]="creaSocioForm.invalid || loading || !correoValido"
                        class="btn btn-info" 
                        type="submit" 
                        role="menuitem"
                        >
                        Guardar cambios
                    </button>
                    <button
                        *ngIf="modoEditar && loading"
                        disabled
                        class="btn btn-info" 
                        type="button" 
                        role="menuitem"
                        >
                        <i class="fa fa-spin fa-refresh fa-1x"></i> Esperando ... 
                    </button>
                </li>
                
            </ul>
        </div>

</form>
</div>