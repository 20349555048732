import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '../guards/auth.guard';

import { PagesComponent } from './pages.component';
import { CheckAsistenciasComponent } from './asistencias/check-asistencias/check-asistencias.component';


const routes: Routes = [
    {
        path:'dashboard', 
        component: PagesComponent,
        canActivate:[AuthGuard],
        canLoad:[AuthGuard],
        loadChildren:()=>import('./child-routes.module').then(m=>m.ChildRoutesModule)
      },
    {
        path:'check-asistencias', 
        component: CheckAsistenciasComponent,
        canActivate:[AuthGuard],
        canLoad:[AuthGuard]
      },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule {}
            