<!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="left-sidebar">
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar">
                <!-- Sidebar navigation-->
                <nav class="sidebar-nav">
                    <ul id="sidebarnav">
                        <li class="user-profile">
                            <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                                <img [src]="imgUrl" alt="user" />
                                <span class="hide-menu">{{nombre | slice:0:10}}{{nombre.length>10? '...': '' }} </span>
                            </a>
                            <ul aria-expanded="false" class="collapse">
                                <li><a routerLinkActive="active" routerLink="perfil">Mi Perfil </a></li>
                                <li *ngIf="role == 'ROLE_ADMIN'"><a routerLinkActive="active" routerLink="acount-settings">Configuración</a></li>
                                <li><a class="cursorpointer" (click)="logout()">Salir</a></li>
                            </ul>
                        </li>
                        <li class="nav-devider"></li>
                        <li class="nav-small-cap">PERSONAL</li>
                        
                        
                        <li *ngFor="let item of siderbarService.menu"> 
                            <a class="waves-effect waves-dark" href="#" aria-expanded="false">
                                <i class="{{item.icono}}" style="font-size: 16px;"></i>
                                <span class="hide-menu">{{item.titulo}} 
                                    <span class="label label-rouded label-themecolor pull-right">{{item.submenu.length}}</span>
                                </span>
                            </a>
                            <ul aria-expanded="false" class="collapse">
                                <li *ngFor="let subMenuItem of item.submenu">
                                    <a 
                                        *ngIf="subMenuItem.url != '../check-asistencias'"    
                                        routerLink="{{subMenuItem.url}}"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact:true}"
                                    >
                                    
                                    {{subMenuItem.titulo}} 
                                
                                    </a>
                                    <a *ngIf="subMenuItem.url == '../check-asistencias'" routerLink="{{subMenuItem.url}}"
                                       routerLinkActive="active"
                                       target="_blank"
                                       [routerLinkActiveOptions]="{exact:true}"
                                    >
                                    
                                    {{subMenuItem.titulo}} 
                                
                                    </a>

                                </li>
                             
                            </ul>
                        </li>



                    </ul>
                </nav>
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->