import { environment } from "src/environments/environment"

const base_url = environment.base_url;

export class Usuario {

    constructor(

        public nombre:string,
        public email:string,
        public pass?:string,
        public img?:string,
        public google?:boolean,
        public role?:string,
        public uid?:string
    ){}


    get imagenUrl(){
    

        if (!this.img) {
            return `${base_url}/upload/usuarios/not-found`
        }else if (this.img.includes('https')) {
            return this.img
        }else if (this.img) {
            
            return `${base_url}/upload/usuarios/${this.img}`
            
        }else{
            
            return `${base_url}/upload/usuarios/not-found`
             
        }
    }
}