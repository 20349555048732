<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <form 
                    #formBusqueda="ngForm"
                >

                    <div class="input-group">
                        <input 
                            type="text" 
                            [(ngModel)]="terminoBusqueda"
                            name="terminoBusqueda"
                            class="form-control" 
                            placeholder="Escribe para buscar..."
                            autocomplete="off"
                        >
                        <span class="input-group-btn">
                            <button 
                                class="btn btn-info" 
                                type="submit"
                                (click)="buscar(formBusqueda.value)"
                            >
                                Buscar
                            </button>
                        </span>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="cargando"
>
    <div class="col-12">
        

        <div class="alert alert-info text-center" role="alert">
            <h4>Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0"></p>
        </div>
       
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="!cargando"
>
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        
                        <h4 class="card-title">Encargados</h4>
                        <h6 class="card-subtitle">Encargados registrados: {{totalItems}}</h6>
                    </div>

                    <div class="col-md-2 text-right">                          
                        <button (click)="abrirModal('agregar', null)" class="btn btn-primary">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            Agregar
                        </button>
                    </div>
                                        

                </div>
                <div class="table-responsive top-10">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th class="w100">Foto</th>
                                <th >Nombre(s)</th>
                                <th>Telefono</th>
                                <th>Email</th>
                                <th class="text-center" nowrap>Fecha de registro</th>
                                <th *ngIf="newFeatures" class="">Role</th>
                                <th *ngIf="newFeatures" class="text-center">Estatus</th>
                                <th class="text-nowrap text-center w100">Acciones</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="sociosData.length>0">
                           <tr *ngFor="let socio of sociosData">
                            <td class="text-center">
                                <img 
                                    *ngIf="socio.urlImgThumbnail!=null" 
                                    [src]="socio.urlImgThumbnail" 
                                    alt="Imagen de perfil" 
                                    class="img-user"
                                 >
                                <img
                                    *ngIf="socio.urlImgThumbnail==null" 
                                    src="../../../assets/images/foto-not-found-black.jpg" 
                                    alt="Imagen de perfil" 
                                    class="img-user"
                                 >
                                 
                            </td>

                            <td class="">{{socio.nombres}} {{socio.apellidoPaterno}} {{socio.apellidoMaterno}}</td>
                            <td class="">{{socio.telefono}}</td>
                            <td *ngIf="socio.email" class="">
                                {{socio.email | slice:0:25}}{{socio.email.length>25? '...': '' }}
                            </td>
                            <td *ngIf="!socio.email" class="cursorpointer">------------------------</td>
                            <td class="text-center">
                                {{socio.fechaCreacion | date: 'dd/MM/yyyy'}}
                            </td>
                            
                            <td *ngIf="newFeatures">
                                Administrador
                            </td>
                            <td *ngIf="newFeatures" class="text-center" [ngClass]="{'text-success': socio.estatus, 'text-danger': !socio.estatus}">
                                <i class="fa fa-circle-o"></i> {{socio.estatus}}
                            </td>
                               
                               
                               <td class="text-nowrap text-center">

                                    <button (click)="abrirModal('editar', socio)" type="button" class="btn btn-info btn-circle"><i class="fa fa-pencil"></i> </button>
                                    &nbsp;
                                    <button (click)="abrirModalPermisos(socio)" type="button" class="btn btn-outline-primary btn-circle"><i class="fa fa-eye"></i> </button>
                                    &nbsp;
                                    <button (click)="eliminarEncargado(socio.id)" type="button" class="btn btn-danger btn-circle"><i class="fa fa-trash"></i> </button>

                                </td>
                            </tr>
                        </tbody>

                        <tbody *ngIf="sociosData.length<=0">
                            <tr>
                                <td colspan="8">
                            <div class="p-20 text-center">
                                No hay resultados de la busqueda
                            </div>

                            </td>

                            </tr>
                        </tbody>

                    </table>
                </div>

                <div class="clearfix"></div>
                <div class="top-20 col-lg-12 col-md-12">                          
                                
                    <mi-paginador
                        *ngIf="!buscandoPorNombre" 
                        (cargaData)="seleccionaItemsPorPagina($event)"
                        (numeroDePaginaEmit)="seleccionaPagina($event)"
                        [totalPaginasArray]="totalPaginasArray || 0"    
                        [itemsPorPaginaInput]="itemsPorPagina"    
                        [numeroDePaginaInput]="numeroDePagina || 0"    
                        [desdeInput]="desde || 0"    
                        [hastaInput]="hasta || 5"    
                        >
                    </mi-paginador>
                
                </div>

            </div>
        </div>
    </div>
</div>

<modal-agregar-usuario 
    [idSocioInput]="idSocioInput || null"
    (refreshSocios)="cargarUsuarios()"
    >
</modal-agregar-usuario>
<modal-ver-permisos 
    [usuarioInput]="usuarioInput || null"
    >
</modal-ver-permisos>

