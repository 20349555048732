<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <form 
                    #formBusqueda="ngForm"
                >

                    <div class="input-group">
                        <input 
                            type="text" 
                            [(ngModel)]="terminoBusqueda"
                            name="terminoBusqueda"
                            class="form-control" 
                            placeholder="Puedes buscar por nombre o número de socio..."
                            autocomplete="off"
                        >
                        <span class="input-group-btn">
                            <button 
                                class="btn btn-info" 
                                type="submit"
                                (click)="buscar(formBusqueda.value)"
                            >
                                Buscar
                            </button>
                        </span>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="cargando"
>
    <div class="col-12">
        

        <div class="alert alert-info text-center" role="alert">
            <h4>Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0"></p>
        </div>
       
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="!cargando"
>
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        
                        <h4 class="card-title">Selecciona un socio</h4>
                        <h6 class="card-subtitle">Socios registrados: {{totalItems}}</h6>
                    </div>

                    <div class="col-md-12">
                        <div class="row">

                            <div class="col-md-10 col-sm-12 col-xs-12 d-flex text-left" >
                                <select 
                                class="custom-select custom-select-sm"
                                [(ngModel)]="sociosMembresias"
                                name="sociosMembresias"
                                (change)="filtroSocios($event.target.value)"
                                >
                                <option value="todos">Todos</option>
                                <option value="vencidas">Membresías vencidas</option>
                                <option value="activas">Membresías activas</option>
                                <option value="por-vencer">Membresías próximas a vencer</option>
                                <option value="sin-renovar">Membresías sin renovar</option>
                                <option value="sin-registrar">Sin membresías registradas</option>
                                </select>
                                &nbsp;
                                <div *ngIf="diasDeVencerEstatus" class="">
                                    <label class="m-l-5 m-r-5" for="">a</label>
                                        <input
                                        type="number" 
                                        class="customInputNumber m-l-5 m-r-5 custom-top-less-5"
                                        name="diasDeVencer"
                                        [(ngModel)] ="diasDeVencer"
                                        (keyup.enter)="cargarSociosPorVencer()"
                                        >
                                    <label class="m-l-5" for="">dias de vencer</label>
                                    <button type="button" (click)="cargarSociosPorVencer()" class="m-l-10 btn btn-circle btn-success custom-top-less-5"> <i class="fa fa-search"></i></button>
                                </div>

                                <div *ngIf="mesesSinRenovarEstatus" class="">
                                    <label class="m-l-5 m-r-5" for="">desde hace</label>
                                        <input
                                        type="number" 
                                        class="customInputNumber m-l-5 m-r-5 custom-top-less-5"
                                        name="mesesSinRenovar"
                                        [(ngModel)] ="mesesSinRenovar"
                                        (keyup.enter)="cargarSociosSinRenovar()"
                                        >
                                    <label class="m-l-5" for="">meses</label>
                                    <button type="button" (click)="cargarSociosSinRenovar()" class="m-l-10 btn btn-circle btn-success custom-top-less-5"> <i class="fa fa-search"></i></button>
                                </div>

                            </div>

                    </div>
                        
                    </div>
                    

                </div>
                <div class="table-responsive top-10">
                    <table class="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th class="w100">Foto</th>
                                <th class="text-center">N° Socio</th>
                                <th>Nombre(s)</th>
                                <th>Telefono</th>
                                <th>Membresía</th>
                                <th class="text-center">Inicio</th>
                                <th class="text-center">Termina</th>
                                <th class="text-center">Resta</th>
                                <th class="text-nowrap w100">Acciones</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="sociosData.length>0">
                           <tr *ngFor="let socio of sociosData">
                            <td class="text-center">
                                <img 
                                    *ngIf="socio.urlImgThumbnail!=null" 
                                    [src]="socio.urlImgThumbnail" 
                                    alt="Imagen de perfil" 
                                    class="img-user"
                                 >
                                <img
                                    *ngIf="socio.urlImgThumbnail==null" 
                                    src="../../../assets/images/foto-not-found-black.jpg" 
                                    alt="Imagen de perfil" 
                                    class="img-user"
                                 >
                                 
                            </td>
                            <td class="cursorpointer text-center" routerLink="/dashboard/socio/{{socio.id}}">{{socio.folio == null ? '-----------' : socio.folio}}</td>

                            <td class="cursorpointer table-info" routerLink="/dashboard/socio/{{socio.id}}">{{socio.nombres}} {{socio.apellidoPaterno}} {{socio.apellidoMaterno}}</td>
                            <td>{{socio.telefono}}</td>
                            <td class="cursorpointer" routerLink="/dashboard/socio/{{socio.id}}">{{socio.nombreMembrecia ?  socio.nombreMembrecia : '-----------'}}</td>
                            <td
                                routerLink="/dashboard/socio/{{socio.id}}"  
                                class="text-center" 
                                *ngIf="socio.fechaInicioMembrecia!=null"
                                >
                                {{socio.fechaInicioMembrecia | date: 'dd/MM/yyyy'}}
                            </td>
                            <td  
                                class="text-center" 
                                *ngIf="socio.fechaInicioMembrecia==null">- - - - - - 
                            </td>
                            <td  
                                class="text-center table-warning" 
                                *ngIf="socio.fechaFinMembrecia!=null">{{socio.fechaFinMembrecia | date: 'dd/MM/yyyy'}}
                            </td>
                            <td  
                                class="text-center" 
                                *ngIf="socio.fechaFinMembrecia==null">- - - - - - 
                            </td>

                            <td  
                                *ngIf="socio.dias>=0" 
                                nowrap class="text-center">{{socio.dias}} dias
                            </td>
                            <td  
                                *ngIf="socio.dias<0" 
                                nowrap class="text-center">0 dias
                            </td>
                               
                               
                            <td
                                class="text-nowrap text-center">
                                <button (click)="abrirModal('comprar', socio)" type="button" class="btn waves-effect waves-light btn-rounded btn-success btn-sm mt-10">Seleccionar</button>
                            </td>
                            </tr>
                        </tbody>

                        <tbody *ngIf="sociosData.length<=0">
                            <tr>
                                <td colspan="8">
                            <div class="p-20 text-center">
                                No hay resultados de la busqueda
                            </div>

                            </td>

                            </tr>
                        </tbody>

                    </table>
                </div>

                <div class="clearfix"></div>
                <div class="top-20 col-lg-12 col-md-12">                          
                                
                    <mi-paginador
                        *ngIf="!buscandoPorNombre" 
                        (cargaData)="seleccionaItemsPorPagina($event)"
                        (numeroDePaginaEmit)="seleccionaPagina($event)"
                        [totalPaginasArray]="totalPaginasArray || 0"    
                        [itemsPorPaginaInput]="itemsPorPagina"    
                        [numeroDePaginaInput]="numeroDePagina || 0"    
                        [desdeInput]="desde || 0"    
                        [hastaInput]="hasta || 5"    
                        >
                    </mi-paginador>
                
                </div>

            </div>
        </div>
    </div>
</div>

<modal-agregar-socio 
    [idSocioInput]="idSocioInput || null"
    (refreshSocios)="cargarSocios()"
    >
</modal-agregar-socio>

