import Swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MensajesSwalService {

  constructor(
    private router:Router
  ) { }



  validandoPago(){

    Swal.fire({
      icon: 'info',
      title: 'Su pago se esta validando',
      text: 'En unos momentos su comprobante sera enviado a su correo electronico',
      showConfirmButton: true,
    });

  }

  loading() {

    Swal.fire({
      title: "Validando pago",
      html: "Espere un momento por favor.",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("I was closed by the timer");

      }
    });

  }

  suscripcionCreadaExitosamente(){

    Swal.fire({
      icon: 'success',
      title: 'Suscripción comprada exitosamente',
      text: 'Su comprobante fue enviado a su correo electrónico',
      showConfirmButton: true,
    });

  }
  productoYaExiste(){

    Swal.fire({
      icon: 'info',
      title: 'El producto seleccionado ya ha sido agregado',
      text: 'Para agregar mas del mismo producto, solo modifica el campo "cantidad"',
      showConfirmButton: true,
    });

  }

  alertStock(nombreProducto){

    Swal.fire({
      icon: 'info',
      title: 'No hay suficiente stock para ' +nombreProducto,
      html: 'En la sección de <a href="dashboard/productos">productos</a> puedes agregar más stock a tu inventario.',
      showConfirmButton: true,
    });

  }
  agregadoConExito(concepto:string, genero:string){

    Swal.fire({
      icon: 'success',
      title: concepto + ' agregad'+genero+ ' con éxito!',
      showConfirmButton: false,
      timer: 1500
    });

  }

  modificadoConExito(concepto:string, genero:string){

    Swal.fire({
      icon: 'success',
      title: concepto + ' modificad'+genero+ ' con éxito!',
      showConfirmButton: false,
      timer: 1500

    });

  }
  modificadosConExito(concepto:string, genero:string){

    Swal.fire({
      icon: 'success',
      title: concepto + ' modificad'+genero+'s con éxito!',
      showConfirmButton: false,
      timer: 1500

    });

  }
  registradoConExito(){

    Swal.fire({
      title: 'Usuario registrado con éxito!',
      icon: 'success',
      text: 'Te enviamos un correo para que actives tu cuenta',
      showConfirmButton: true

    }).then((result) => {

        if (result.isConfirmed) {

          this.router.navigateByUrl("/");

        }
     })





  }
  activadoConExito(){

    Swal.fire({
      icon: 'success',
      title: 'Usuario activado con éxito!',
      showConfirmButton: true

    });

  }

  registrarAsistencia(){

    Swal.fire({
      icon: 'success',
      title: 'Asistencia registrada',
      showConfirmButton: false,
      timer: 1500
    })

  }

  eliminadoConExito(){

    Swal.fire({
      icon: 'success',
      title: 'Eliminado exitosamente',
      showConfirmButton: false,
      timer: 1500
    })

  }

  eliminadaConExito(){

    Swal.fire({
      icon: 'success',
      title: 'Eliminada exitosamente',
      showConfirmButton: false,
      timer: 1500
    })

  }


  muestraResultado(){
    Swal.fire(
      'Deleted!',
      'Your file has been deleted.',
      'success'
    )
  }


  membresiaAsignada(){

    Swal.fire({
      icon: 'info',
      title: 'La membresía esta asignada a un socio',
      text: 'Mientras continue asignada, no se podrá eliminar'
    });

  }
  servicioAsignado(){

    Swal.fire({
      icon: 'info',
      title: 'El servicio esta asignado a una membresía',
      text: 'Mientras continue asignado, no se podrá eliminar'
    });

  }
  categoriaAsignada(){

    Swal.fire({
      icon: 'info',
      title: 'La categoría esta asignada a un gasto',
      text: 'Mientras continue asignado, no se podrá eliminar'
    });

  }

  huboUnError(error){

    Swal.fire({
      icon: 'error',
      title: 'Hubo un error',
      text: error
    });

  }

  error500(error){

    Swal.fire({
      icon: 'error',
      title: 'Error 500',
      text: error
    });

  }


}
