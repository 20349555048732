import { Component, OnInit } from '@angular/core';
import { Socio } from 'src/app/models/socio.model';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import { ProductosService } from 'src/app/services/productos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent implements OnInit {

  public productosData:any[]=[];
  public cargando:boolean=true;
  public buscandoPorNombre:boolean=false;

  public desde:number=0;
  public hasta:number=5;
  public infoPaginacion:any;
  public totalItems:number=0;
  public numeroDePagina:number=0;
  public itemsPorPagina:number=10;
  public totalPaginasInt:number=1;
  public totalPaginasArray:number[]=[];
  public idProductoInput:Object=null;
  public productoInput:Object=null;
  public terminoBusqueda:string=''
  public socio:Socio=null;
  public filtroPorVencer:boolean=false;
  public filtroSinRenovar:boolean=false;
          

  constructor(
    private productosService:ProductosService,
    private mensajesSwalService:MensajesSwalService,
    
  ) { }

  ngOnInit(): void {
    this.cargarProductos()
  }


  buscar(busqueda):void{
    

    const terminoTxt = busqueda.terminoBusqueda;
    this.cargando=true;
    this.buscandoPorNombre=true

    if (terminoTxt=='') {
      
      this.cargarProductos();

    }else{

        this.productosService.obtenerProductoPorNombre(terminoTxt).subscribe(producto=>{
        
          this.productosData = producto;

          this.cargando=false;

        },()=>{
          this.cargando=false
        })
              
    }

    
  }

  abrirModal(tipoDeModal, producto){
    
    if (tipoDeModal == 'agregar' ) {
      this.productosService.abrirModalProducto()

    }else if (tipoDeModal == 'editar') {
      
      this.idProductoInput = {
                            productoID: producto.id,
                            operacion:'editar'

                          }

      this.productosService.abrirModalProducto()

    }
    
  }

  cambiarStock(producto){

    this.productoInput = producto
    this.productosService.abrirModalStock();
    
 
   }


  cargarProductos(){
    
    this.idProductoInput=null;
    this.buscandoPorNombre=false
    this.cargando=true;

  
      this.productosService.obtenerProductos(this.numeroDePagina, this.itemsPorPagina)
      .subscribe(({productos, infoPaginacion})=>{
  
        this.productosData = productos;
        
        this.infoPaginacion = infoPaginacion
        this.totalPaginasInt = infoPaginacion.totalPages
        this.totalPaginasArray=[]
        this.totalItems= infoPaginacion.totalElements
  
        
        for (let i = 0; i < this.totalPaginasInt; i++) {
            this.totalPaginasArray.push(i);
          }
        
        this.cargando=false;
  
      })

  }


  eliminarProducto(producto){

    Swal.fire({
      title: '¿Eliminar producto?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#E5B700',
      cancelButtonColor: '#717171',
      cancelButtonText:'No',
      confirmButtonText: 'Si'
    }).then((result) => {

        if (result.isConfirmed) {

          this.productosService.eliminarProducto(producto.id).subscribe(res=>{
      
            this.mensajesSwalService.eliminadoConExito();
            this.cargarProductos();
          })

        }        
     })

  }





  
  seleccionaItemsPorPagina(e){

    this.itemsPorPagina = e;
    this.cargarProductos();
    
  }

  seleccionaPagina(data){

    this.numeroDePagina =  data.paginaSeleccionada;
    this.desde =  data.desde;
    this.hasta =  data.hasta;
    this.cargarProductos();
  }


  filtroSocios(e){

    switch (e) {
      case 'todos':
        this.filtroPorVencer=false;
        this.filtroSinRenovar=false;
        break;
      case 'vencidas':

        this.filtroPorVencer=false;
        this.filtroSinRenovar=false;
        
        break;
      case 'por-vencer':

        this.filtroPorVencer=true;
        this.filtroSinRenovar=false;
        
        break;
      case 'sin-renovar':
        
        this.filtroPorVencer=false;
        this.filtroSinRenovar=true;

        break;
    
      default:
        break;
    }

    
  }

}
