<div class="col-md-12 col-sm-12">
    <div class="form-group">
        
        <div class="text-center">
            <img *ngIf="!imgPreview" src="../../../../assets/images/foto-not-found-black.jpg" class="my-foto img-fluid" alt="">
            <img *ngIf="imgPreview" [src]=imgPreview class="my-foto img-fluid" alt="">
        </div>
            
        <div *ngIf="botonCargarFoto" class="text-center">
            <label for="idSelectFoto" class="mylabelFoto">
                <div *ngIf="!loading" type="button" class="btn btn-info btn-sm btn-block">
                    <i class="ti-settings text"></i>
                    <span class="text"> Selecciona foto </span>
                </div>
            </label>
            &nbsp;
            <button (click)="quitarImagen()" type="button" *ngIf="!loading" class="btn btn-sm btn-outline-secondary">
                <i class="fa fa-trash-o"></i>
            </button>
        </div>


        <div *ngIf="loading" type="button" class="myloading animated fadeIn fast btn btn-secondary btn-sm btn-block">
            <i class="fa fa-spin fa-refresh fa-1x"></i>
            <span class="text"> Cargando ... </span>
        </div>
        <input
            type="file"
            (change)="cambiarImagen($event)"  
            id="idSelectFoto"
        >       
    </div>
</div>