        <div class="row">
            <!-- Column -->
            <div class="top-28 col-lg-4 col-xlg-3 col-md-5">
                <div class="card">
                    <div class="card-body">
                        <div class="text-right">
                            <span (click)="editarSocio()" class="cursorpointer">
                                <i class="fa fa-edit"></i>
                            </span>
                        </div>
                        <div class="m-t-30 text-center"> 

                                <img 
                                    *ngIf="dataSocio.urlImgThumbnail!=null" 
                                    [src]="dataSocio.urlImg" 
                                    alt="Imagen de perfil" 
                                    class="img-circle"
                                    width="150"
                                 >
                                <img
                                    *ngIf="dataSocio.urlImgThumbnail==null" 
                                    src="../../../assets/images/foto-not-found-black.jpg" 
                                    alt="Imagen de perfil" 
                                    class="img-circle"
                                    width="150"
                                    height="165"
                                 >



                            <h4 class="card-title m-t-10">{{dataSocio.nombres}} {{dataSocio.apellidoPaterno}}</h4>
                            <h6 class="card-subtitle">{{dataSocio.nombres}} {{dataSocio.apellidoPaterno}} {{dataSocio.apellidoMaterno}}</h6>
                        </div>
                    </div>
                    <div>
                        <hr> </div>
                    <div class="card-body text-center p-b-30"> 
                        <small class="text-muted p-t-30 db">Telefono</small>
                        <h6>{{dataSocio.telefono}}</h6> 
                    
                        <small class="text-muted p-t-30 db">Correo electrónico </small>
                        <h6 *ngIf="!utilsService.verificaCorreo(dataSocio.email)">{{dataSocio.email}}</h6> 
                        <h6 *ngIf="utilsService.verificaCorreo(dataSocio.email)"> -------------- </h6> 
                                            
                        <small class="text-muted p-t-30 db">Sexo</small>
                        <h6>{{dataSocio.sexo}}</h6>                    
                        
                        <small class="text-muted p-t-30 db">Fecha de nacimiento</small>
                        <h6>{{dataSocio.fechaNacimiento | date: 'dd/MM/yyyy'}}</h6>                    
                        
                        <!-- <small class="text-muted p-t-30 db">Social Profile</small>
                        <br/>
                        <button class="btn btn-circle btn-secondary"><i class="fa fa-facebook"></i></button>
                        <button class="btn btn-circle btn-secondary"><i class="fa fa-twitter"></i></button>
                        <button class="btn btn-circle btn-secondary"><i class="fa fa-youtube"></i></button> -->
                        <div class="text-center top-50">
                            <button *ngIf="eliminarActive" (click)="eliminarSocio()" class="btn btn-outline-danger waves-effect waves-light" type="button">
                                <span class="btn-label"><i class="fa fa-trash"></i></span>Eliminar
                            </button>
                        <div *ngIf="!eliminarActive" class="instructionDelete">

                            <p>Para eliminar al socio escribe el siguiente texto tal como se muestra, excepto las comillas: </p>
                                <div class="form-group">
                                    <label class="rojo">"Dar de baja al socio seleccionado"</label>
                                    <input 
                                        type="text" 
                                        autofocus 
                                        autocomplete="off"
                                        name="textoAverificar" 
                                        class="form-control form-control-sm"
                                        [(ngModel)]="textoAverificar"
                                        (ngModelChange)="verificaTexto()" 
                                        >                                    
                                </div>

                                <button (click)="cancelarEliminar()" class="btn btn-sm btn-outline-inverse waves-effect waves-light" type="button">
                                    Cancelar
                                </button>
                                &nbsp;
                                <button (click)="confirmarEliminar()" [disabled]="isDisabled" class="btn btn-sm btn-outline-danger waves-effect waves-light" type="button">
                                    Confirmar
                                </button>

                        </div>
                        </div>
                    
                    </div>

                </div>
            </div>
            <!-- Column -->
            <!-- Column -->
            <div class="col-lg-8 col-xlg-9 col-md-7">
                <div class="text-right bott-5">
                    <span routerLink="/dashboard/socios" class="icon-back cursorpointer">
                        <i class="fa  fa-angle-left" aria-hidden="true"></i>
                            Regresar
                    </span>
                </div>
                <div class="card">
                    <!-- Nav tabs -->
                    <ul class="nav nav-tabs profile-tab" role="tablist">
                        <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#resumen" role="tab">Resumen</a> </li>
                        <li class="nav-item"> <a class="nav-link" (click)="obtenerMembresiasCompradas()" data-toggle="tab" href="#membresias" role="tab">Membresías</a> </li>
                        <li class="nav-item"> <a class="nav-link" (click)="obtenerAsistencias()" data-toggle="tab" href="#asistencias" role="tab">Asistencias</a> </li>
                    </ul>
                    <!-- Tab panes -->
                    <div class="tab-content">
                        <div class="tab-pane active" id="resumen" role="tabpanel">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-lg-4 col-md-4">
                                        <div class="card bg-success">
                                            <div class="card-body">
                                                <div class="d-flex no-block">
                                                    <div class="m-r-20 align-self-center">
                                                        <i class="icons-resume fa fa-calendar"></i>
                                                    </div>
                                                    <div class="align-self-center">
                                                        <h6 class="text-white m-t-10 m-b-0">Fecha de inicio</h6>
                                                        <h4 *ngIf="dataSocio.fechaInicioMembrecia == null" class="m-t-0 text-white"> - - - - - - </h4>
                                                        <h4 *ngIf="dataSocio.fechaInicioMembrecia != null" class="m-t-0 text-white">{{dataSocio.fechaInicioMembrecia | date: 'dd/MM/yyyy'}}</h4>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4">
                                        <div class="card bg-warning">
                                            <div class="card-body">
                                                <div class="d-flex no-block">
                                                    <div class="m-r-20 align-self-center">
                                                        <i class="icons-resume fa fa-calendar-o"></i>
                                                    </div>
                                                    <div class="align-self-center">
                                                        <h6 class="text-white m-t-10 m-b-0">Fecha de término</h6>
                                                        <h4 *ngIf="dataSocio.fechaFinMembrecia==null" class="m-t-0 text-white"> - - - - - - </h4>
                                                        <h4 *ngIf="dataSocio.fechaFinMembrecia!=null" class="m-t-0 text-white">{{dataSocio.fechaFinMembrecia | date: 'dd/MM/yyyy'}}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-4">
                                        <div class="card bg-danger">
                                            <div class="card-body">
                                                <div class="d-flex no-block">
                                                    <div class="m-r-20 align-self-center"><i class="icons-resume fa fa-exclamation-circle"></i></div>
                                                    <div class="align-self-center">
                                                        <h6 class="text-white m-t-10 m-b-0">Dias restantes</h6>
                                                        
                                                        
                                                        <h4 *ngIf="dataSocio.dias==null" class="m-t-0 text-white">0</h4>
                                                        <h4 *ngIf="dataSocio.dias<=0" class="m-t-0 text-white">0</h4>
                                                        <h4 *ngIf="dataSocio.dias>0" class="m-t-0 text-white">{{dataSocio.dias}}</h4>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                        <div *ngIf="dataUltimaMembresia.folio=='000000'" class="alert alert-info none-data-div col-md-12">
                                            <h5 class="text-info top-30">
                                                <i class="fa fa-exclamation-circle"></i> No hay membresías
                                            </h5> <h6>No hay membresías compradas</h6> 
                                        </div>

                                        <div *ngIf="dataUltimaMembresia.folio!='000000'" class="form-group col-md-4">
                                            <label class="col-md-12">Folio de compra:</label>
                                            <div class="col-md-12">
                                                <span class="my-label-1">{{dataUltimaMembresia.folio || '00000'}}</span>
                                            </div>
                                        </div>
                                        <div *ngIf="dataUltimaMembresia.folio!='000000'" class="form-group col-md-4">
                                            <label class="col-md-12">Fecha de compra:</label>
                                            <div class="col-md-12">
                                                <span class="my-label-1">{{dataUltimaMembresia.fechaCompra}}</span>
                                            </div>
                                        </div>
                                        <div *ngIf="dataUltimaMembresia.folio!='000000'" class="form-group col-md-4">
                                            <label class="col-md-12">Membresía:</label>
                                            <div class="col-md-12">
                                                <span class="my-label-1">{{dataUltimaMembresia.nombreMembrecia | lowercase}}</span>
                                            </div>
                                        </div>
                                        <div *ngIf="dataUltimaMembresia.folio!='000000'" class="form-group col-md-4">
                                            <label class="col-md-12">Servicios:</label>
                                            <div *ngFor="let servicio of dataUltimaMembresia.servicios" class="col-md-12">
                                                <span class="my-label-1">{{servicio}}, </span>
                                            </div>
                                        </div>
                                        <div *ngIf="dataUltimaMembresia.folio!='000000'" class="form-group col-md-4">
                                            <label class="col-md-12">Tipo de membresía:</label>
                                            <div class="col-md-12">
                                                <span class="my-label-1">Por {{dataUltimaMembresia.nombrePeriodo | lowercase}}</span>
                                            </div>
                                        </div>
                                        <div *ngIf="dataUltimaMembresia.folio!='000000'" class="form-group col-md-4">
                                            <label class="col-md-12">Cantidad:</label>
                                            <div class="col-md-12">
                                                <span class="my-label-1">{{dataUltimaMembresia.cantidad}}</span>
                                            </div>
                                        </div>
                                        <div *ngIf="dataUltimaMembresia.folio!='000000'" class="form-group col-md-4">
                                            <label class="col-md-12">Precio/unidad:</label>
                                            <div class="col-md-12">
                                                <span class="my-label-1">${{dataUltimaMembresia.subtotal}}</span>
                                            </div>
                                        </div>
                                        <div *ngIf="dataUltimaMembresia.folio!='000000'" class="form-group col-md-4">
                                            <label class="col-md-12">Descuento:</label>
                                            <div class="col-md-12">
                                                <span class="my-label-1">${{dataUltimaMembresia.descuento}}</span>
                                            </div>
                                        </div>
                                        <div *ngIf="dataUltimaMembresia.folio!='000000'" class="form-group col-md-4">
                                            <label class="col-md-12">Total:</label>
                                            <div class="col-md-12">
                                                <span class="my-label-1">${{dataUltimaMembresia.total}}</span>
                                            </div>
                                        </div>
                                        <div *ngIf="dataUltimaMembresia.folio!='000000'" class="form-group col-md-4">
                                            <label class="col-md-12">Atendio:</label>
                                            <div class="col-md-12">
                                                <span class="my-label-1">{{dataUltimaMembresia.nombreVendedor}}</span>
                                            </div>
                                        </div>
                                        
                                        

 
                                </div>
                                
                            </div>
                        </div>
                        <!--second tab-->
                        <div class="tab-pane" id="membresias" role="tabpanel">
                            <div class="card-body">
                                    <div class="d-flex">
                                        <div class="col-md-6">
                                            <h4 class="card-title"><span class="lstick"></span>Membresías compradas: {{membresiasData.length}}</h4>
                                        </div>
                                        <div class="col-md-6 text-right">                          
                                            <button (click)="abrirModal('comprar', dataSocio)" class="btn waves-effect waves-light btn-rounded btn-success">
                                                <!-- <i class="fa fa-plus" aria-hidden="true"></i> -->
                                                Comprar membresía
                                            </button>
                                        </div>
                                    </div>
                                    <div class="table-responsive m-t-20 no-wrap">
                                        <table class="table vm no-th-brd pro-of-month">
                                            <thead>
                                                <tr>
                                                    <th>Fecha de compra</th>
                                                    <th>Folio ticket</th>
                                                    <th>Membresía</th>
                                                    <th>Tipo</th>
                                                    <th class="text-center">Cantidad</th>
                                                    <th class="text-center">Inicio</th>
                                                    <th class="text-center">Termino</th>
                                                    <th class="text-center">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="membresiasData.length>0">
                                                <tr *ngFor="let membresia of membresiasData">
                                                    
                                                    <td>{{membresia.fechaCompra || '00-00-0000'}}</td>
                                                    <td>{{membresia.folio}}</td>
                                                    <td>{{membresia.nombreMembrecia | lowercase}}</td>
                                                    <td>Por {{membresia.nombrePeriodo | lowercase}}</td>
                                                    <td class="text-center">{{membresia.cantidad}}</td>
                                                    <td class="text-center">{{membresia.fechaInicio}}</td>
                                                    <td class="text-center">{{membresia.fechaFin}}</td>
                                                    <td class="text-center">
                                                        <button (click)="reimprimirTicket(membresia)" type="button" class="btn btn-info btn-circle"><i class="fa fa-file-pdf-o"></i> </button>
                                                        <button (click)="eliminarMembresia(membresia)" type="button" class="btn btn-danger btn-circle m-l-10"><i class="fa fa-trash"></i> </button>
                                                    </td>
                                                </tr>
                                               
                                               
                                            </tbody>

                                            <tbody *ngIf="membresiasData.length<=0">
                                                <tr>
                                                    <td colspan="6">
                                                        <div class="alert alert-info">
                                                            <h5 class="text-info">
                                                                <i class="fa fa-exclamation-circle"></i> No hay membresías
                                                            </h5> <h6>No hay membresías compradas</h6> 
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                
                            </div>
                        </div>
                        <div class="tab-pane" id="asistencias" role="tabpanel">
                         
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="col-md-4">
                                        <h4 class="card-title"><span class="lstick"></span>Asistencias: {{asistenciasData.length}}</h4></div>
                                    <div class="ml-auto col-md-4">
                                        <select 
                                            class="custom-select b-0 cursorpointer"
                                            (change)="cambiaMes($event)"
                                        >
                                            <option selected="">{{nombreMes}}</option>
                                            <option *ngFor="let mes of meses" value="{{mes}}">{{mes}}</option>
                                        </select>

                                        &nbsp;

                                        <select 
                                            class="custom-select b-0 cursorpointer"
                                            (change)="cambiaYear($event)"
                                            >
                                            <option value="{{anioAnterior}}">{{anioAnterior}}</option>
                                            <option selected="{{anioActual}}">{{anioActual}}</option>
                                            <option value="{{anioPosterior}}">{{anioPosterior}}</option>
                                        </select>
                                    </div>
                                    <div class="ml-auto col-md-4 text-right">
                                        <button
                                        *ngIf="diasRestantes > 0 "
                                        (click)="registraAsistencia(dataSocio.folio)" 
                                        class="btn btn-outline-success btn-rounded"
                                        >
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                            Registrar asistencia
                                        </button>

                                        <span *ngIf="diasRestantes <= 0 " class="mytooltip tooltip-effect-2">
                                            <button
                                                disabled
                                                class="btn btn-outline-secondary btn-rounded"
                                            >
                                                <i class="fa fa-check" aria-hidden="true"></i>
                                                Registrar asistencia
                                            </button>
                                            <span class="tooltip-content clearfix">
                                                <span class="tooltip-text text-center">Se requiere tener una membresía activa para registrar asistencias</span>
                                            </span>
                                        </span>  


                                        
                                    </div>
                                </div>
                                <div class="table-responsive m-t-20 no-wrap">
                                    <table class="table vm no-th-brd pro-of-month">

                                        <tbody *ngIf="asistenciasData.length>0">
                                            <tr *ngFor="let asistencia of asistenciasData">
                                                
                                                <td class="text-center">{{asistencia.fecha | date: 'dd/MM/yyyy'}} - <span class="horaClass">{{asistencia.horaEntrada}}</span></td>
                                                
                                            </tr>
                                           
                                           
                                        </tbody>

                                        <tbody *ngIf="asistenciasData.length<=0">
                                            <tr>
                                                <td colspan="6">
                                                    <div class="alert alert-info">
                                                        <h5 class="text-info">
                                                            <i class="fa fa-exclamation-circle"></i> No hay asistencias
                                                        </h5> <h6>No hay asistencias registradas</h6> 
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>
        <!-- Row -->
        <!-- ============================================================== -->
        <!-- End PAge Content -->
        <!-- ============================================================== -->

        <modal-agregar-socio 
            [idSocioInput]="idSocioInput || null"
            (refreshSocios)="ngOnInit()"
        >
        </modal-agregar-socio>

