import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileData } from 'src/app/interfaces/profile.interface';
import { GymService } from 'src/app/services/gym.service';
import { RefreshDataService } from 'src/app/services/refresh-data.service';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
  ]
})
export class HeaderComponent implements OnInit {

  public imgUrl:string;
  public nombre:string;
  public email:string;
  public role:string = '';
  public data:ProfileData;



  constructor( 
    private userService:UsuarioService,
    public gymService:GymService,
    private router:Router,
    private cdr: ChangeDetectorRef
    ) {

    this.imgUrl = 'not-found';
    this.nombre = 'none'; 
    this.email = 'none'; 


    }

  ngOnInit(): void {
    this.obtenerRole();
    this.obtenerInfoUsuario();
    this.getDataFromSubject();

  }

  getDataFromSubject(){
    this.userService.profileData$.subscribe((newData) => {
      this.data = newData;
      this.nombre = this.data.nombre;
      this.imgUrl = this.data.image;
      this.email = this.data.email;
      // Forzar la detección de cambios cuando se actualizan los datos
      this.cdr.detectChanges();
    });
  }


  obtenerRole(){
    const dataUser = this.userService.decodificarJWT();
    this.role = dataUser.role;
  }

  obtenerInfoUsuario(){
    this.userService.obtenerUsuario().subscribe((res:any)=>{

      const ProfileData = {
        nombre: res.nombres,
        image: res.urlImg,
        email: res.email
      }
      
      this.userService.updateProfileData(ProfileData);

    });

  }


  buscar(terminoTxt:string){
      console.log(terminoTxt);
      // if (terminoTxt.length===0) {
      //   terminoTxt="not-found"          
      // }
      // this.router.navigateByUrl(`/dashboard/buscar/${terminoTxt}`);
  }


  logout(){
    this.userService.logout();
  }

}
