<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <form 
                    #formBusqueda="ngForm"
                >

                    <div class="input-group">
                        <input 
                            type="text" 
                            [(ngModel)]="terminoBusqueda"
                            name="terminoBusqueda"
                            class="form-control" 
                            placeholder="Escribe para buscar..."
                            autocomplete="off"
                        >
                        <span class="input-group-btn">
                            <button 
                                class="btn btn-info" 
                                type="submit"
                                (click)="buscar(formBusqueda.value)"
                            >
                                Buscar
                            </button>
                        </span>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="cargando"
>
    <div class="col-12">
        

        <div class="alert alert-info text-center" role="alert">
            <h4>Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0"></p>
        </div>
       
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="!cargando"
>
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        
                        <h4 class="card-title">Productos</h4>
                        <h6 class="card-subtitle">Productos registrados: {{totalItems}}</h6>
                    </div>

                    <div class="col-md-2 text-right">                          
                        <button (click)="abrirModal('agregar', null)" class="btn btn-primary">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            Agregar
                        </button>
                    </div>
                    
                    <!-- <div class="col-md-12 text-left">
                        <select 
                            class="custom-select custom-select-sm"
                            (change)="filtroSocios($event.target.value)"
                        >
                            <option value="todos">Todos</option>
                            <option value="vencidas">Mas vendidos</option>
                            <option value="por-vencer">Menos vendidos</option>
                            <option value="sin-renovar">Proximos a caducar</option>
                            <option value="sin-renovar">Caducados</option>
                        </select>
                        &nbsp;
                        <select
                            *ngIf="filtroPorVencer" 
                            class="custom-select custom-select-sm"
                            (change)="obtenerPeriodo($event.target.value)"
                        >
                            <option>Menos de 10 dias</option>
                            <option>Menos de 5 dias</option>
                            <option>A un día</option>
                        </select>
                        &nbsp;
                        <select
                            *ngIf="filtroSinRenovar" 
                            class="custom-select custom-select-sm"
                            (change)="obtenerPeriodo($event.target.value)"
                        >
                            <option>Mas de 2 meses</option>
                            <option>Mas de 3 meses</option>
                            <option>Mas de 4 meses</option>
                        </select>
                    </div> -->
                    

                </div>
                <div class="table-responsive top-10">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="w100">Foto</th>
                                <th>Producto</th>
                                <th>Descripción</th>
                                <th>Precio</th>
                                <th class="text-center">Stock</th>
                                <th>Caducidad</th>

                                <th class="text-nowrap w100">Acciones</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="productosData.length>0">
                           <tr *ngFor="let producto of productosData">
                            <td class="text-center">
                                <img 
                                    *ngIf="producto.urlImgThumbnail!=null" 
                                    [src]="producto.urlImgThumbnail" 
                                    alt="Imagen de perfil" 
                                    class="img-user"
                                 >
                                <img
                                    *ngIf="producto.urlImgThumbnail==null" 
                                    src="../../../assets/images/foto-not-found-black.jpg" 
                                    alt="Imagen de perfil" 
                                    class="img-user"
                                 >
                            </td>

                            <td class="">{{producto.nombre}}</td>
                            <td class="">{{producto.descripcion}}</td>
                            <td class="">${{producto.precio}}</td>
                            <td (click)="cambiarStock(producto)" class="cursorpointer table-info text-center">
                                <span class="mytooltip tooltip-effect-2">
                                        {{producto.cantidad}}
                                    <span class="tooltip-content clearfix">
                                        <span class="tooltip-text text-center">Agregar o quitar stock</span> 
                                    </span>
                                </span>
                            </td>
                            <td class="">{{producto.fechaCaducidad | date: 'dd/MM/yyyy'}}</td>
                            
                            

                               <td class="text-nowrap text-center">
                                    <button (click)="abrirModal('editar', producto)" type="button" class="btn btn-info btn-circle"><i class="fa fa-pencil"></i> </button>
                                    &nbsp;
                                    <button (click)="eliminarProducto(producto)" type="button" class="btn btn-danger btn-circle"><i class="fa fa-trash"></i> </button>
                                </td>
                            </tr>
                        </tbody>

                        <tbody *ngIf="productosData.length<=0">
                            <tr>
                                <td colspan="8">
                            <div class="p-20 text-center">
                                No hay resultados de la busqueda
                            </div>

                            </td>

                            </tr>
                        </tbody>

                    </table>
                </div>

                <div class="clearfix"></div>
                <div class="top-20 col-lg-12 col-md-12">                          
                                
                    <mi-paginador
                        *ngIf="!buscandoPorNombre" 
                        (cargaData)="seleccionaItemsPorPagina($event)"
                        (numeroDePaginaEmit)="seleccionaPagina($event)"
                        [totalPaginasArray]="totalPaginasArray || 0"    
                        [itemsPorPaginaInput]="itemsPorPagina"    
                        [numeroDePaginaInput]="numeroDePagina || 0"    
                        [desdeInput]="desde || 0"    
                        [hastaInput]="hasta || 5"    
                        >
                    </mi-paginador>
                
                </div>

            </div>
        </div>
    </div>
</div>

<modal-producto 
    [idProductoInput]="idProductoInput || null"
    (refreshData)="cargarProductos()"
    >
</modal-producto>

<modal-stock
    [productoInput]="productoInput || null"
    (refreshData)="cargarProductos()"
>

</modal-stock>

