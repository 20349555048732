import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../services/settings.service';
import { SiderbarService } from '../services/siderbar.service';
import { GymService } from '../services/gym.service';
import { UsuarioService } from '../services/usuario.service';

declare function customInitPlugins();

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html'
 
})
export class PagesComponent implements OnInit {
  public diasRestantes:number = 100;
  public textDanger:string = 'Tu suscripción ha vencido';
  public textWarning:string = 'Tu suscripción esta proxima a vencer';
  private textSuscription:string = 'Para renovar tu suscripción dirigete a ';
  private textEncargado:string = 'Contacta al administrador para reactivar la cuenta';
  public role:string = '';

  constructor(
    // private settingsService:SettingsService,
    private siderbarService:SiderbarService,
    public gymService:GymService,
    private usuarioService:UsuarioService

  ) { }

  ngOnInit(): void {

    customInitPlugins();
    this.siderbarService.menu;
    this.obtenerInfoGym();
    this.obtenerRole();
  }

  obtenerRole(){
    const dataUser = this.usuarioService.decodificarJWT();
    this.role = dataUser.role;
  }

  goToPay(){
    this.gymService.abrirModalPayments();
  }

  obtenerInfoGym(){
    this.gymService.obtenerInfoGym().subscribe(res => {
      this.diasRestantes = res.res.diasRestantes;
      
      if (this.diasRestantes <= 10) {
        this.gymService.abrirAlert();
      }
    })
  }


}
