<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <div class="input-group">
                    <input type="text" (keyup.enter)="buscar(terminoTxt.value)" #terminoTxt class="form-control" placeholder="Escribe para buscar...">
                    <span class="input-group-btn">
                    <button class="btn btn-info" type="button" (click)="buscar(terminoTxt.value)">
                        Ir!
                    </button>
                    </span>
                </div>


            </div>
        </div>
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="cargando"
>
    <div class="col-12">
        

        <div class="alert alert-info text-center" role="alert">
            <h4>Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0"></p>
        </div>
       
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="!cargando"
>
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-8">
                        
                        <h4 class="card-title">Membresías</h4>
                        <h6 class="card-subtitle">Membresías registradas</h6>
                    </div>

                    <div class="col-md-4 text-right">
                        <button type="button" routerLink="/dashboard/crear-membresias" class="btn btn-primary">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                            Crear nueva membresía
                        </button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Nombre de la membresía</th>
                                <th>Servicios</th>
                                <th>Inscripción</th>
                                <th>Periodos activos</th>
                                <th class="text-nowrap w100">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let membresia of membresiasData">
                           
                            <td>{{membresia.nombreMembresia}}</td>
                            <td>
                            
                                <span *ngFor="let servicio of membresia.servicios">
                                    {{servicio.nombre}},
                                </span>                            
                            </td>
                            <td>{{membresia.inscripcionStatus ? '$' +membresia.precioInscripcion : '-------'}}</td>
                            <td>
                                <span class="tooltip-item">
                                <span *ngFor="let periodo of membresia.periodos">
                                    <span *ngIf="periodo.activo"><strong>{{periodo.key}}: </strong> <span class="precioStyle"> ${{periodo.precio}}, </span> </span>
                                </span>
                                </span>
                            </td>
                               
                               
                               <td class="text-nowrap">
                                    <button routerLink="/dashboard/crear-membresias/{{membresia.id}}" type="button" class="btn btn-info btn-circle"><i class="fa fa-pencil"></i> </button>
                                    &nbsp;
                                    <button (click)="eliminarMembresia(membresia.id)" type="button" class="btn btn-danger btn-circle"><i class="fa fa-trash"></i> </button>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- <button class="btn btn-secondary">Siguiente</button>
                &nbsp;
                <button class="btn btn-secondary">Anterior</button> -->
            </div>
        </div>
    </div>
</div>




