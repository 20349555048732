<div class="row">
    <div class="col-md-6 col-sm-12">

        <div class="card m-t-20">
            <div class="card-body">

                <form #formCambiaDatosGym="ngForm">
                    <h3>Cambiar datos de mi cuenta de usuario</h3>
                    <br>

                    <div class="row">

                        <div class="col-md-6 col-sm-12 m-t-20">
                            <app-photo
                                [urlImg]="urlImg" 
                                [botonCargarFoto]="editMode" 
                                (fotoCargada)="recibirFoto($event)"
                                (loadingStatus)="recibirStatusLoading($event)"
                            >
                            </app-photo>
                        </div>
                        <div class="col-md-6 col-sm-12">
                            <div class="form-group">

                                <label class="m-t-20" for="nombres">*Nombres:</label>
                                <input 
                                    type="text"
                                    class="form-control" 
                                    [(ngModel)]="nombre"
                                    name="nombre"
                                    [readOnly]="!editMode"
                                    autocomplete="off"
                                    (ngModelChange)="validaFormDatosUsuario()"
                                    >    
                                <label class="m-t-20" for="nombres">*Apellido paterno:</label>
                                <input 
                                    type="text"
                                    class="form-control" 
                                    [(ngModel)]="apellidoPaterno"
                                    name="apellidoPaterno"
                                    [readOnly]="!editMode"
                                    autocomplete="off"
                                    (ngModelChange)="validaFormDatosUsuario()"
                                    >    
                                <label class="m-t-20" for="nombres">Apellido materno:</label>
                                <input 
                                    type="text"
                                    class="form-control" 
                                    [(ngModel)]="apellidoMaterno"
                                    name="apellidoMaterno"
                                    [readOnly]="!editMode"
                                    autocomplete="off"
                                    (ngModelChange)="validaFormDatosUsuario()"
                                    >    
                                <label class="m-t-20" for="nombres">Fecha de nacimiento:</label>
                                <input 
                                    type="date"
                                    class="form-control" 
                                    [(ngModel)]="fechaNacimiento"
                                    name="fechaNacimiento"
                                    [readOnly]="!editMode"
                                    autocomplete="off"
                                    (ngModelChange)="validaFormDatosUsuario()"
                                    >    
                                
                                
                                <div class="form-group">
                                    <label class="control-label m-t-20">*Sexo</label>
                                    <select 
                                        class="form-control custom-select"
                                        [(ngModel)]="sexo"
                                        [disabled]="!editMode"
                                        name="sexo"
                                    >
                                        <option value="MASCULINO">Masculino</option>
                                        <option value="FEMENINO">Femenino</option>
                                    </select>                                
                                </div>  
                                 
                                <label class="m-t-10" for="nombres">Teléfono :</label>
                                <input 
                                    type="text"
                                    class="form-control" 
                                    [(ngModel)]="telefono"
                                    name="telefono"
                                    [readOnly]="!editMode"
                                    autocomplete="off"
                                    (ngModelChange)="validaFormDatosUsuario()"
                                    >    
                                <label class="m-t-20" for="nombres">*Email:</label>
                                <input 
                                    type="text"
                                    class="form-control" 
                                    [(ngModel)]="correo"
                                    name="correo"
                                    [readOnly]=true
                                    autocomplete="off"
                                    (ngModelChange)="validaFormDatosUsuario()"
                                    >
                                    
                                <div *ngIf="alertEmailEnabled" class="myalert m-t-20" role="alert">
                                    <span>Verifica el formato del correo: ejemplo@mail.com</span>
                                </div>
 
                                    <div *ngIf="alertInputRequiredEnabled" class="myalert m-t-20" role="alert">
                                        <span>*Falta algun campo obligatorio</span>
                                    </div>
                                    <br>
        
        
                                    <div class="text-right">
                                        <button 
                                            *ngIf="!loadingDatos && !editMode"
                                            type="submit" 
                                            (click)="editMode = true" 
                                            class="btn btn-info m-t-40" 
                                            >
                                            Editar
                                        </button>
                                        <button 
                                            *ngIf="!loadingDatos && editMode"
                                            type="button" 
                                            (click)="cancelEditMode()" 
                                            class="btn btn-warning m-t-40" 
                                            >
                                            Cancelar
                                        </button>
                                        &nbsp;
                                        <button 
                                            *ngIf="!loadingDatos && editMode"
                                            type="submit" 
                                            (click)="cambiarDatos()" 
                                            class="btn btn-info m-t-40" 
                                            [disabled]=isDisabledFormDatos
                                            >
                                            Guardar cambios
                                        </button>
                                        <button 
                                            *ngIf="loadingDatos"
                                            type="button" 
                                            class="btn btn-info m-t-40" 
                                            disabled
                                            >
                                            <i class="fa fa-spin fa-refresh fa-1x"></i> Esperando ...
                                        </button>
                                        
                                    </div>
        
        
                                </div>
                        </div>
    
    
    
                    </div>



                    

                </form>
            </div>
        </div>


        <div class="card">
            <div class="card-body">

                <form #formCambiarPass="ngForm">
                    <h3>Cambiar contraseña</h3>
                    <br>
                    <div class="form-group">
                        <label for="nombres">*Contraseña actual :</label>
                        <input 
                            type="password"
                            class="form-control" 
                            [(ngModel)]="oldPassword"
                            name="oldPassword"
                            autocomplete="off"
                            (ngModelChange)="validaAll()"
                            >    
                        <label class="m-t-20" for="nombres">*Contraseña nueva :</label>
                        <input 
                            type="password"
                            class="form-control" 
                            [(ngModel)]="newPassword"
                            name="newPassword"
                            autocomplete="off"
                            (ngModelChange)="validaAll()"
                            >    
                        <label class="m-t-20" for="nombres">*Confirma contraseña actual :</label>
                        <input 
                            type="password"
                            class="form-control" 
                            [(ngModel)]="confirmNewPassword"
                            name="confirmNewPassword"
                            autocomplete="off"
                            (ngModelChange)="validaAll()"
                            >    
                            <div *ngIf="noCoinciden" class="myalert m-t-20" role="alert">
                                <span>*Las contraseñas no coinciden</span>
                            </div>
                            <br>
                            <div class="text-right">
                                <button 
                                    *ngIf="!loading"
                                    type="submit" 
                                    (click)="cambiarPassword(formCambiarPass.value)" 
                                    class="btn btn-info m-t-40" 
                                    [disabled]=isDisabled
                                    >
                                    Guardar cambios
                                </button>
                                <button 
                                    *ngIf="loading"
                                    type="button" 
                                    class="btn btn-info m-t-40" 
                                    disabled
                                    >
                                    <i class="fa fa-spin fa-refresh fa-1x"></i> Esperando ...
                                </button>
                                
                            </div>


                        </div>

                </form>
            </div>
        </div>

        
    </div>