<div class="fondo-modal-imagen animated fadeIn fast" [class.oculto]="productoService.ocultarModalStock" >
    
    <div id="modalStock" class="modal fade bs-example-modal-md show" tabindex="-1" role="dialog" aria-labelledby="myModalStockLabel" style="display: block; padding-right: 16px; top: 7%;">
        
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <form 
                    [formGroup]="cambiarStockForm"    
                    (ngSubmit)="cambiarStock()" 
                    class="tab-wizard wizard-circle wizard clearfix" 
                    role="application"
                >

                <div class="modal-header">
                    <h4 class="modal-title" id="myModalStockLabel">Cambiar Stock - {{nombreProducto}}</h4>
                </div>
                <div class="modal-body">
                    <div class="card-body wizard-content">                       

                            <div class="table-responsive">
                                <table class="table color-table info-table">
                                    <thead>
                                        <tr>
                                            <th class="text-center">Stock</th>
                                            <th class="text-center">Operación</th>
                                            <th class="text-center">Cantidad</th>
                                         
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center my-label-2">{{stockInicial}}</td>
                                            <td class="text-center">
                                                <div class="switch">
                                                    <label>
                                                        -
                                                        <input 
                                                            type="checkbox" 
                                                            formControlName="operacion" 
                                                            (ngModelChange)="calculaOperacion()" 
                                                            >
                                                            <span class="lever"></span>
                                                        +
                                                    </label>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <input 
                                                    type="number" 
                                                    class="w80 form-control"
                                                    formControlName="cantidadAagregar"
                                                    autocomplete="off"
                                                    autofocus
                                                    (ngModelChange)="calculaOperacion()"
                                                    >
                                            </td>
                                     
                                        </tr>
                                        <tr>
                                            <td colspan="4">
                                                <textarea 
                                                    class="form-control" 
                                                    rows="3" 
                                                    formControlName="descripcion"
                                                    placeholder="Escribe detalles del movimiento ..."
                                                    >
                                                </textarea>
                                                <br>
                                                <p class="tiempoActual text-right">{{date | date: 'dd/MM/yyyy, h:mm a'}}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="4" class="text-center totalStock">
                                                Stock final: {{stockFinal}}
                                            </td>
                                        </tr>
                                        <!-- <tr>
                                            <td>Cantidad</td>
                                            <td>

                                            </td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>

                    </div>                    
                                     
                </div>
                
                <div class="modal-footer">

                        <button 
                            type="button"
                            (click)="cerrarModal()"
                            class="btn btn-inverse waves-effect text-left"
                            
                            >
                            Cancelar
                        </button>
                     
                        <button
                            *ngIf="!loading"
                            [disabled]="cambiarStockForm.invalid"
                            class="btn btn-info" 
                            type="submit" 
                            role="menuitem"
                        >
                            Guardar
                        </button>
                        <button
                            *ngIf="loading"
                            disabled
                            class="btn btn-info" 
                            type="button" 
                            role="menuitem"
                        >
                            <i class="fa fa-spin fa-refresh fa-1x"></i> Esperando ...
                        </button>
                
                </div>


                </form>

            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>    
    



</div>
 