<div class="fondo-modal-imagen animated fadeIn fast" [class.oculto]="usuariosService.ocultarModalAgregarUsuario" >
    
    <div id="modalAgregarUsuario" class="modal fade bs-example-modal-lg show" tabindex="-1" role="dialog" aria-labelledby="myModalAddUserLabel" style="display: block; padding-right: 16px;">
        
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 *ngIf="!modoEditar" class="modal-title" id="myModalAddUsuarioLabel">Agregar encargado</h4>
                    <h4 *ngIf="modoEditar" class="modal-title" id="myModalAddUsuarioLabel">Editar encargado</h4>
                </div>
                <div class="modal-body">
                    
                    <datos-usuario 
                        (idSocioEmit)="continua($event)"
                        (refreshSocios)="recargaDataSocios($event)"
                        (cancelarOperacion)="cancelarOperaciones($event)"
                        [idSocioRegreso]="idSocioDeRegreso || null"
                        [modoEditar]="modoEditar || false"
                        *ngIf="mystep1"
                        >
                    </datos-usuario>

                </div>                
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>    
    



</div>
 