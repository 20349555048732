import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
// import { MembreciasService } from 'src/app/services/membrecias.service';
import { SociosService } from 'src/app/services/socios.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'datos-usuario',
  templateUrl: './datos-usuario.component.html',
  styleUrls: ['./datos-usuario.component.css']
})
export class DatosUsuarioComponent implements OnInit, OnChanges {

  @Input() idSocioRegreso
  @Input() modoEditar = false
  @Output() idSocioEmit = new EventEmitter<number>();
  @Output() refreshSocios = new EventEmitter<string>();
  @Output() cancelarOperacion = new EventEmitter<string>();


  public creaSocioForm:any
  public idSocio:number=null
  public enabledChangeSocio:boolean = false;
  public formSubmitted:boolean = false;
  public imgPreview:any='';
  public imgPreviewBack:any='';
  public loading:boolean = false
  public fotoAsubir:any=[]
  public tipoUsuarioAdmin:boolean = false;
  public vistaRoles:boolean = false;
  public uploadPercentage:any=0
  public keyImg:string='' 
  public myUrlImg:string='' 
  public correoValido:boolean = true;
  public permisos:any = [
    {nombre:'CORTE', estatus: true},
    {nombre:'VENTAS', estatus: true},
    {nombre:'SOCIOS', estatus: true},
    {nombre:'PRODUCTOS', estatus: true},
    {nombre:'MEMBRESÍAS', estatus: true},
    {nombre:'USUARIOS', estatus: false}
  ]

  constructor(
    private formBuilder:FormBuilder,
    public sociosService:SociosService,
    private usuarioService:UsuarioService,
    private mensajesSwalService:MensajesSwalService

  ) {

    this.creaSocioForm =  this.formBuilder.group({
    
      email:['', [Validators.required, Validators.email]],
      nombres:['', Validators.required],
      apellidoPaterno:['', Validators.required],
      apellidoMaterno:'',
      fechaNacimiento:[''],
      sexo:['', Validators.required],
      telefono:[''],
      // telefono:['', [Validators.required, Validators.minLength(10),  Validators.maxLength(10)]],
      keyImg:'',
      urlImg:'',
      permisos: this.formBuilder.array([])
      
    });
   }
  ngOnChanges(): void {
    this.obtenerSocio();
  }

  ngOnInit(): void {
    
    this.obtenerSocio();

  }

  inicializarPermisos() {
    
    const formArray = this.creaSocioForm.get('permisos') as FormArray;
    this.permisos.forEach((seccion) => {
      formArray.push(
        this.formBuilder.group({
          nombre: seccion.nombre,
          estatus: seccion.estatus,
        })
      );
    });
  }
  

  tipoUsuarioCambio(e){

    if (e=='admin') {
      this.tipoUsuarioAdmin = true;
      const formArray = this.creaSocioForm.get('permisos') as FormArray;
      formArray.at(5).patchValue({ estatus: true });

    } else {
      this.tipoUsuarioAdmin = false;
      const formArray = this.creaSocioForm.get('permisos') as FormArray;
      formArray.at(5).patchValue({ estatus: false });
    }
  }

  goRoles(){
    this.vistaRoles = true;
    if (!this.modoEditar) {
      const formArray = this.creaSocioForm.get('permisos') as FormArray;
      if (formArray.length == 0) {
        this.inicializarPermisos();
      }
    }
  }
  goBack(){
    this.vistaRoles = false;
  }

  obtenerSocio(){

    if (this.idSocioRegreso) {
      
      
      this.idSocio = this.idSocioRegreso
      this.usuarioService.obtenerEncargado(this.idSocioRegreso).subscribe(({socio})=>{

        if (socio.urlImg!='' || socio.urlImg!=null) {
          this.imgPreview = socio.urlImg;
          this.imgPreviewBack = socio.urlImg;
        }
        this.creaSocioForm.patchValue({
          email:socio.email,
          nombres:socio.nombres,
          apellidoPaterno:socio.apellidoPaterno,
          apellidoMaterno:socio.apellidoMaterno,
          fechaNacimiento:socio.fechaNacimiento,
          sexo:socio.sexo,
          telefono:socio.telefono,
          urlImg:socio.urlImg,
          keyImg:socio.keyImg
        })
        const formArray = this.creaSocioForm.get('permisos') as FormArray;
        socio.permisos.forEach((seccion) => {
        formArray.push(
            this.formBuilder.group({
              nombre: seccion.nombre,
              estatus: seccion.estatus,
            })
          );
        });
        
      })
    }

  }

  cambiarImagen(event){
    this.loading=true;

    const file  = event.target.files[0]
    
    
    if (!file) {
      if (this.imgPreview) {
        this.loading=false;


        return this.imgPreview
      }else{
        this.loading=false;
        return this.imgPreview='';
      }

    }else{
      this.fotoAsubir.push(file);
      this.subirImagen(file);
    }

  }

  subirImagen(file){
    
    try {

      const formImagen = new FormData();
      
      this.fotoAsubir.forEach(miFoto => {
        formImagen.append('file', miFoto)
        
      });

      this.sociosService.fotoSocioUploadService(formImagen)
        .subscribe(({respuesta,cargaBytes,cargaTotal}) => {
          
          if (respuesta) {

            this.myUrlImg = respuesta.data
            
            this.creaSocioForm.patchValue(
                {
                  keyImg:this.myUrlImg,
                  urlImg:''

                }
                );

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend=()=>{
              this.imgPreview=reader.result;
            }

            this.loading=false;
            this.fotoAsubir = [];   
            
          }
    
          const carga = parseInt(cargaBytes)
          const total = parseInt(cargaTotal)

          this.uploadPercentage =  Math.round( ( carga / total ) * 100 )
          
      })

    } catch (error) {


      this.loading=false;
      this.fotoAsubir = [];
      this.imgPreview='';

    }
    
  }

  quitarImagen(){

    this.fotoAsubir = [];   
    this.creaSocioForm.patchValue({
      urlImg:'',
      keyImg:null,
    });
    
    if (this.imgPreviewBack!='' || this.imgPreviewBack!=null) {
      this.imgPreview = this.imgPreviewBack
    }else{
      this.imgPreview='';
    }

  }


  compruebaEmail(e){
      

    if (e) {
      const email = e.target.value
      
      this.usuarioService.obtenerUsuarioPorCorreo(email).subscribe((data)=>{
        
        if (data.data == true) {
          this.correoValido = true
          
        }else{
          this.correoValido = false
          
        }
      })
    }

    
    
  }

  crearSocio(){ 
    this.loading = true;
    // this.mystep1 = false;
    // this.mystep2 = true;
    this.formSubmitted=true;    

      if (this.idSocio==null) {

        
        
        this.usuarioService.crearUsuario(this.creaSocioForm.value).subscribe(res=>{
          this.loading = false;


          this.mensajesSwalService.agregadoConExito('Usuario','o');
          this.limpiaData();
          this.refreshSocios.emit();
      
        })    
    
      }else{
        
        this.usuarioService.modificarEncargado(this.idSocio, this.creaSocioForm.value).subscribe(res=>{
        this.actualizarPermisos();
        })    

      }

  }

  actualizarPermisos(){

    
    
    this.usuarioService.actualizarPermisosEncargados(this.idSocio, this.permis.value).subscribe(res=>{
     
      this.limpiaData()
      this.refreshSocios.emit('recargaSocios');
        
    }) 
  }

  cerrarModal(){ 
 
      
      Swal.fire({
        title: '¿Seguro que deseas cancelar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#E5B700',
        cancelButtonColor: '#717171',
        cancelButtonText:'No',
        confirmButtonText: 'Si'
      }).then((result) => {
          if (result.isConfirmed) {
              this.limpiaData();
          }        
       })

 }
  cerrarModalEditar(){ 
 
      
      Swal.fire({
        title: '¿Cancelar editar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#E5B700',
        cancelButtonColor: '#717171',
        cancelButtonText:'No',
        confirmButtonText: 'Si'
      }).then((result) => {
          if (result.isConfirmed) {
            this.cancelarOperacion.emit('recargaSocios');
            this.limpiaData()
          }        
       })

 }

 limpiaData(){

  this.usuarioService.cerrarModalAgregarUsuario()
  this.creaSocioForm.patchValue({
    nombres:'',
    apellidoPaterno:'',
    apellidoMaterno:'',
    fechaNacimiento:'',
    sexo:'',
    telefono:'',
    email:'',
    keyImg:null,
    urlImg:null,
    permisos:[]
  });


  const formArray = this.creaSocioForm.get('permisos') as FormArray;
  while (formArray.length !== 0) {
    formArray.removeAt(0);
  }

  this.imgPreview='';
  this.imgPreviewBack='';
  this.creaSocioForm.markAsPristine();
  this.creaSocioForm.markAsUntouched();
  this.correoValido = true;
  this.idSocio=null;
  this.modoEditar=false;
  this.idSocioRegreso=null
  this.formSubmitted=false;    
  this.vistaRoles = false;
  this.loading = false;

  }

limpiaSoloData(){
  this.creaSocioForm.patchValue({
    nombres:'',
    apellidoPaterno:'',
    apellidoMaterno:'',
    fechaNacimiento:'',
    sexo:'',
    telefono:'',
    email:'',
    keyImg:null,
    urlImg:null
  });

  this.imgPreview='';
  this.imgPreviewBack='';
  this.creaSocioForm.markAsPristine();
  this.creaSocioForm.markAsUntouched();
  this.correoValido = true;
  this.idSocio=null;
  this.modoEditar=false;
  this.idSocioRegreso=null
  this.formSubmitted=false;
}


get nombres() {return this.creaSocioForm.get('nombres')}
get apellidoPaterno() {return this.creaSocioForm.get('apellidoPaterno')}
get fechaNacimiento() {return this.creaSocioForm.get('fechaNacimiento')}
get sexo() {return this.creaSocioForm.get('sexo')}
get telefono() {return this.creaSocioForm.get('telefono')}
get email() {return this.creaSocioForm.get('email')}
get permis() {return this.creaSocioForm.get('permisos')}

}
