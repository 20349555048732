    <!-- ============================================================== -->
    <!-- Main wrapper - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <section id="wrapper" class="login-register login-sidebar" style="background-image:url(../assets/images/background/login-register2.jpg);">
        <div class="login-box card">
            <div class="card-body">
                <!-- <form class="form-horizontal form-material" id="loginform" (submit)="login()"> -->
                <form 
                    class="form-horizontal form-material" 
                    id="loginform"
                    [formGroup]="loginForm"
                    (ngSubmit)="login()"
                >
                    
                    <a href="javascript:void(0)" class="text-center db">
                        <img src="../assets/images/logo-icon.png" alt="Home" />
                        <br/>
                        <img src="../assets/images/logo-text.png" alt="Home" /></a>
                    
                    <div class="form-group m-t-40">
                        <div class="col-xs-12">
                            <input 
                                class="form-control"  
                                type="text" 
                                required="" 
                                placeholder="Usuario"
                                formControlName="username"
                                [readOnly]="loading"
                                >
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-xs-12">
                            <input class="form-control"  
                            type="password" 
                            required="" 
                            placeholder="Contraseña"
                            formControlName="password"
                            [readOnly]="loading"
                            >
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <div class="col-md-12">
                            <div class="checkbox checkbox-primary pull-left p-t-0">
                                <input 
                                    id="checkbox-signup" 
                                    type="checkbox" 
                                    class="filled-in chk-col-light-blue"
                                    formControlName="rememberme"
                                >
                                <label for="checkbox-signup"> Recordar credenciales </label>
                            </div>
                            <a *ngIf="newFeatures" href="javascript:void(0)" id="to-recover" class="text-dark pull-right"><i class="fa fa-lock m-r-5"></i> Forgot pwd?</a> </div>
                    </div>
                    <div class="form-group text-center m-t-20">
                        <div class="col-xs-12">
                            <button 
                                *ngIf="!loading" 
                                class="btn btn-info btn-lg btn-block text-uppercase btn-rounded" 
                                type="submit">Ingresar
                            </button>
                            <button 
                                *ngIf="loading" 
                                class="btn btn-info btn-lg btn-block text-uppercase btn-rounded" 
                                type="button"
                                disabled
                                >
                                <i class="fa fa-spin fa-refresh fa-1x"></i> 
                            </button>
                        </div>
                    </div>
                    <div class="form-group m-b-0">
                        <div class="col-sm-12 text-center">
                            ¿Aún no tienes cuenta? <a routerLink="/register" class="text-primary m-l-5"><b>Registrate</b></a>
                        </div>
                    </div>
                </form>
                <form class="form-horizontal" id="recoverform" action="index.html">
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <h3>Recover Password</h3>
                            <p class="text-muted">Enter your Email and instructions will be sent to you! </p>
                        </div>
                    </div>
                    <div class="form-group ">
                        <div class="col-xs-12">
                            <input class="form-control" type="text" required="" placeholder="Email">
                        </div>
                    </div>
                    <div class="form-group text-center m-t-20">
                        <div class="col-xs-12">
                            <button class="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
    <!-- ============================================================== -->
    <!-- End Wrapper -->
    <!-- ============================================================== -->