import { Component, OnInit } from '@angular/core';
import { MembreciasService } from 'src/app/services/membrecias.service';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-membresias',
  templateUrl: './membresias.component.html',
  styleUrls: ['./membresias.component.css']
})
export class MembresiasComponent implements OnInit {

  public cargando:boolean=false;
  public desde:number=0;
  public membresiasData:any[]
  public backUpmembresiasData:any[]
  public misServicios:any


  constructor(
    private membreciasService:MembreciasService,
    private mensajesSwalService:MensajesSwalService,
  ) { }

  ngOnInit(): void {

    this.cargarMembresias()
    
  }


  buscar(terminoTxt:string){
    
    this.cargando=true

    if (terminoTxt=='') {
      // this.cargarMembresias();
      this.membresiasData = this.backUpmembresiasData
      this.cargando = false;

    }else{
        this.membresiasData = this.backUpmembresiasData.filter(function (item) {
          return item.nombreMembresia.toLowerCase().includes(terminoTxt.toLowerCase());
        });
        this.cargando = false;
    }

    
  }


  cargarMembresias(){
    this.cargando = true;
    this.membreciasService.obtenerMembresias().subscribe(({membresias})=>{
      this.membresiasData = membresias;
      this.backUpmembresiasData = membresias;
      this.cargando = false;
    })
  }


  eliminarMembresia(id){

    Swal.fire({
      title: '¿Eliminar membresía?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#E5B700',
      cancelButtonColor: '#717171',
      cancelButtonText:'No',
      confirmButtonText: 'Si'
    }).then((result) => {

        if (result.isConfirmed) {
              this.membreciasService.eliminarMembresia(id).subscribe((res:any)=>{

                const isDeleted = res.data;
                if (isDeleted) {
                  this.mensajesSwalService.eliminadaConExito();
                  this.cargarMembresias();
                } else {
                  this.mensajesSwalService.membresiaAsignada();
                }
                
              })
        }        
     })

  }


  

}
