import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ModalImgComponent } from './modal-img/modal-img.component';




@NgModule({
  declarations: [
    ModalImgComponent,
 
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    ModalImgComponent

  ],
})
export class ComponentsModule { }
