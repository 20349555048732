import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { SiderbarComponent } from './siderbar/siderbar.component';
import { HeaderComponent } from './header/header.component';
import { FormsModule } from '@angular/forms';
import { PaginadorComponent } from './paginador/paginador.component';
import { PhotoComponent } from './photo/photo.component';

@NgModule({
  declarations: [
    BreadcrumbsComponent,
    SiderbarComponent,
    HeaderComponent,
    PaginadorComponent,
    PhotoComponent
  ],
  exports: [
    BreadcrumbsComponent,
    SiderbarComponent,
    HeaderComponent,
    PaginadorComponent,
    PhotoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule
  ]
})
export class SharedModule { }
