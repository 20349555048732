import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GastoService } from 'src/app/services/gastos.service';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import { MultimediaService } from 'src/app/services/multimedia.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'modal-gastos',
  templateUrl: './modal-gastos.component.html',
  styleUrls: ['./modal-gastos.component.css']
})
export class ModalGastosComponent implements OnInit, OnChanges {

  @Input() idGastoInput:any
  @Output() refreshData = new EventEmitter<string>();

  public creaProductoForm:any
  public modoEditar:boolean=false;
  public loading:boolean = false
  public cargandoCategorias:boolean = false
  public imgPreview:any='';
  public fotoAsubir:any=[]
  public imgPreviewBack:any='';
  public uploadPercentage:any=0
  public myUrlImg:string='';
  public idGasto:number=null;
  public categoriasData:any=null;



  constructor(
    public gastosService:GastoService,
    public multimediaService:MultimediaService,
    private formBuilder:FormBuilder,
    private mensajesSwalService:MensajesSwalService
  ) {
    this.creaProductoForm =  this.formBuilder.group({
      descripcion:['', Validators.required],
      monto:['', Validators.required],
      fecha:['', Validators.required],
      idCategoria:[null, Validators.required],
      evidencia:'',
      urlImg:null,
      urlImgThumbnail:null,
    });
   }


  ngOnInit(): void {
    this.obtenerCategorias();
  }

  ngOnChanges(): void {

    if (this.idGastoInput==null || this.idGastoInput=='' ) {
        this.modoEditar=false
        
    }else{
    
      this.modoEditar=true;
      this.idGasto = this.idGastoInput.gastoID
      this.obtenerProducto();


    }
  }

  obtenerCategorias(){
    
    this.cargandoCategorias=true;

  
      this.gastosService.obtenerCategorias(0, 100)
      .subscribe(({categorias})=>{
  
        this.categoriasData = categorias;
        this.cargandoCategorias=false;
  
      })

  }

  obtenerProducto(){

    if (this.idGasto!=null) {
      this.gastosService.obtenerGasto(this.idGasto).subscribe(({gasto})=>{
        

        if (gasto.evidencia!='' || gasto.evidencia!=null) {
          this.imgPreview = gasto.evidencia;
          this.imgPreviewBack = gasto.evidencia;
        }         
        this.creaProductoForm.patchValue({
          descripcion:gasto.descripcion,
          monto: gasto.monto,
          fecha: gasto.fecha,
          idCategoria: gasto.idCategoria,
          evidencia: gasto.evidencia,
          urlImg: null,
          urlImgThumbnail: null
        })
        
      })
    }

  }

  validarEvidencia(valor) {
      if (valor === null || valor === undefined || valor === '') {
          return true;
      }

      if (typeof valor === 'string' && valor.startsWith('https://')) {
          return true;
      }

      return false;
  }

  confirmarAccion(accion) {
    this.loading = true;
  
    const titulo = accion === 'crear' ? '¿Registrar gasto?' : '¿Editar gasto?';
    const exitoMensaje = accion === 'crear' ? 'agregadoConExito' : 'modificadoConExito';
  
    Swal.fire({
      title: titulo,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#E5B700',
      cancelButtonColor: '#717171',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
  
      if (result.isConfirmed) {
        const accionObservable = accion === 'crear'
          ? this.gastosService.crearGastos(this.creaProductoForm.value)
          : this.gastosService.modificarGastos(this.idGasto, this.creaProductoForm.value);
  
        accionObservable.subscribe((res: any) => {
          this.loading = false;
          this.mensajesSwalService[exitoMensaje]('Gasto', 'o');
          this.gastosService.cerrarModalGastos();
          this.limpiaData();
          this.refreshData.emit();
        });
  
      } else {
        this.loading = false;
      }
    });
  }

  
  crearGasto(){

    const evidencia = this.creaProductoForm.get('evidencia');

    if (this.validarEvidencia(evidencia.value)) {
      this.creaProductoForm.patchValue({
        evidencia:null,
      });
      
    }

    return !this.modoEditar ? this.confirmarAccion('crear') : this.confirmarAccion('editar');

  }



  limpiaData(){

    this.creaProductoForm.patchValue({
      descripcion: null,
      monto: null,
      fecha: null,
      idCategoria: null,
      evidencia: null,
      urlImg: null,
      urlImgThumbnail: null,
    });
    this.idGastoInput = null;
    this.modoEditar = false;
    this.creaProductoForm.markAsPristine();
    this.creaProductoForm.markAsUntouched();
    this.imgPreview='';
    this.imgPreviewBack='';

  }

  cambiarImagen(event){
    this.loading=true;

    const file  = event.target.files[0]
    
    
    if (!file) {
      if (this.imgPreview) {
        this.loading=false;


        return this.imgPreview
      }else{
        this.loading=false;
        return this.imgPreview='';
      }

    }else{
      this.fotoAsubir.push(file);
      this.subirImagen(file);
    }

  }

  subirImagen(file){
    
    try {

      const formImagen = new FormData();
      
      this.fotoAsubir.forEach(miFoto => {
        formImagen.append('file', miFoto)
        
      });

      this.multimediaService.subirFoto(formImagen)
        .subscribe(({respuesta,cargaBytes,cargaTotal}) => {
          
          if (respuesta) {

            this.myUrlImg = respuesta.data
            
            this.creaProductoForm.patchValue(
                {
                  evidencia:this.myUrlImg
                }
                );

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend=()=>{
              this.imgPreview=reader.result;
            }

            this.loading=false;
            this.fotoAsubir = [];   
            
          }
    
          const carga = parseInt(cargaBytes)
          const total = parseInt(cargaTotal)

          this.uploadPercentage =  Math.round( ( carga / total ) * 100 )
          
      })

    } catch (error) {


      this.loading=false;
      this.fotoAsubir = [];
      this.imgPreview='';

    }
    
  }

  quitarImagen(){

    this.fotoAsubir = [];   
    this.creaProductoForm.patchValue({
      evidencia:null,
    });
    
    if (this.imgPreviewBack!='' || this.imgPreviewBack!=null) {
      this.imgPreview = this.imgPreviewBack
    }else{
      this.imgPreview='';
    }

  }

  cerrarModal(){

    Swal.fire({
      title: '¿Cancelar?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#E5B700',
      cancelButtonColor: '#717171',
      cancelButtonText:'No',
      confirmButtonText: 'Si'
    }).then((result) => {

        if (result.isConfirmed) {
      
            this.limpiaData();
            this.gastosService.cerrarModalGastos();

        }        
     })
  }



  get monto() {return this.creaProductoForm.get('monto')}
  get fecha() {return this.creaProductoForm.get('fecha')}
  get descripcion() {return this.creaProductoForm.get('descripcion')}
  get idCategoria() {return this.creaProductoForm.get('idCategoria')}

}
