import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, SimpleChanges } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const base_url = environment.base_url;


@Injectable({
  providedIn: 'root'
})
export class GymService {
  public logo:any = './assets/images/logo-text.png';
  public logoLight:any = './assets/images/logo-light-text.png';
  private ocultarModalPay:boolean=true;
  private ocultarAlert:boolean=true;

  constructor(
    private http:HttpClient
  ) {

    // this.obtenerLogoGym().then((res) => {

    //   if (!res.urlLogotipo) {
    //     this.logo = './assets/images/logo-text.png'
    //   } else {
    //     this.logo = res.urlLogotipo;
    //   }
      
    // })
  }


  get logoGym():any{
    return this.logo;
  }


  get token():string{
    return localStorage.getItem('token') || '';
  }

  get headers(){
    return{
            headers:{
              "Authorization": `Bearer ${this.token}`
            }
         } 
   }

   get ocultarModalPayments(){
    return this.ocultarModalPay;
  }
   get ocultarAlertGetter(){
    return this.ocultarAlert;
  }

  abrirModalPayments(){
    this.ocultarModalPay = false;
  }
  cerrarModalPayments(){
    this.ocultarModalPay = true;
  }
  abrirAlert(){
    this.ocultarAlert = false;
  }
  cerrarAlert(){
    this.ocultarAlert = true;
  }


crearIntencionDePago(body){
    const url = `${base_url}/api_gimnasios/subscription/pay/intent`;
    return this.http.post(url, body, this.headers);
  }

compruebaIntencionDePago(body){
    const url = `${base_url}/api_gimnasios/subscription/pay/check`;
    return this.http.patch(url, body, this.headers);
  }

  obtenerInfoGym(){
    const url = `${base_url}/api_gimnasios`;
    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{
            return {
              res
            };
      })
    )

  }
  
    async obtenerInfoGymPromise(){
    const url = `${base_url}/api_gimnasios`;
    try {
      const res = await fetch(url, 
            {
              method:'GET',
              headers:{
                "Authorization": `Bearer ${this.token}`
              },
            });
          
          const data = await res.json();
          return data;

    } catch (error) {
      return false;
    }
  }
    async obtenerLogoGym(){
    const url = `${base_url}/api_gimnasios`;
    try {
      const res = await fetch(url, 
            {
              method:'GET',
              headers:{
                "Authorization": `Bearer ${this.token}`
              },
            });
          
          const data = await res.json();
          return data;

    } catch (error) {
      return false;
    }
  }

  actualizarInfoGym(formData) {
    const url = `${base_url}/api_gimnasios`;
    return this.http.patch(url, formData, this.headers);
  }

  manejarError(error:HttpErrorResponse){
    console.log(error);

    return throwError('Hubo un error en la peticion')
    
  }


  manejarError500(error:HttpErrorResponse){
    console.log(error);

    return throwError('Hubo un error, contacte al admin')
    
  }

  modificarSocio(id, formData){
    const url = `${base_url}/api_socios/${id}`;
    
    return this.http.put(url, formData, this.headers);

  }



  fotoSocioUploadService(params){

    const url = `${base_url}/api_multimedia/temporal/api/file/upload`;

        return this.http.post(url, params,{
            reportProgress: true,
            observe: 'events',
            headers: new HttpHeaders(
              { "Authorization": `Bearer ${this.token}` })
          }).pipe(
            map((res:any)=>{
              const respuesta = res.body
              const cargaBytes = res.loaded
              const cargaTotal = res.total
      
                  
                  return {
                    respuesta,
                    cargaBytes,
                    cargaTotal
                  };
              
            }))

  }




} 
