import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CargarUsuarios } from '../interfaces/cargar-usuarios.interface';
import { Login } from '../interfaces/login.interface';
import { RefreshDatosInterface } from '../interfaces/refreshDatos';
import { RegistroUsuario } from '../interfaces/usuario-register.interface';
import { Usuario } from '../models/usuario.model';
import { ProfileData } from '../interfaces/profile.interface';

const base_url = environment.base_url;
declare const gapi:any;

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  public auth2: any;
  public usuario: Usuario;
  public nombreImagen: string;
  private basicToken:string = environment.basic_token;
  private _ocultarModalAgregarAdmin:boolean=true;
  private _ocultarModalAgregarUsuario:boolean=true;
  private _ocultarModalPermisos:boolean=true;

  private profileDataSubject = new BehaviorSubject<ProfileData>({
    nombre: 'None',
    image: 'None',
    email: 'None',
  });
  profileData$: Observable<ProfileData> = this.profileDataSubject.asObservable();



  constructor(
    private http:HttpClient,
    
    ) {
     }

    updateProfileData(newProfileData: ProfileData) {
      this.profileDataSubject.next(newProfileData);
    }
     
     get token():string{
       return localStorage.getItem('token') || '';
     }
  
    get headers(){
      return{
              headers:{
                "Authorization": `Bearer ${this.token}`
              }
           } 
     }
    get headersBasicToken(){
      return{
              headers:{
                "Authorization": this.basicToken
              }
           } 
     }
     
     get ocultarModalAgregarAdmin(){
      return this._ocultarModalAgregarAdmin;
    }
     get ocultarModalAgregarUsuario(){
      return this._ocultarModalAgregarUsuario;
    }
     get ocultarModalPermisos(){
      return this._ocultarModalPermisos;
    }


     get uid():string{
       return this.usuario.uid || '';
     }
     
     get role():string{
      //  return this.usuario.role;
      return localStorage.getItem('role');

     }

     decodificarJWT() {
      const [header, payload, signature] = this.token.split('.');    
      const payloadDecodificado = JSON.parse(atob(payload));
      return payloadDecodificado;
    }

    guardarLocalStorage(token:string, menu:Array<Object>){

      localStorage.setItem('token', token)  
      localStorage.setItem('menu', JSON.stringify(menu))  

    }

    logout(){
        localStorage.removeItem('paymentIntent'); 
        localStorage.removeItem('token');    
        localStorage.removeItem('menu');
        window.location.href = '/login'
    } 


  crearUsuario(params:any){

    const url = `${base_url}/api_encargados/crear`;   

      return this.http.post(url, params, this.headers).pipe(
        map((res:any)=>{
              return {res};
        })
      )
}
  crearAdministrador(params:any){

    const url = `${base_url}/api_administradores/crear`;   

      return this.http.post(url, params, this.headers).pipe(
        map((res:any)=>{
              return {res};
        })
      )
}
  crearCliente(params:any){

    const url = `${base_url}/api_register/register/gym`;   

      return this.http.post(url, params, this.headersBasicToken).pipe(
        map((res:any)=>{
              return {res};
        })
      )
}


  activarCliente(token:string){
    const url = `${base_url}/api_register/register/confirm/account?token=${token}`;
    return this.http.patch(url, token, this.headersBasicToken).pipe(
      map((res:any)=>{
            return {
              res
            };
        
      }), catchError(this.errorDeActivacion)
    )
  }

    errorDeActivacion(error:HttpErrorResponse){
      
      if (error.status == 400) {
        return throwError('ERROR DE ACTIVACIÓN');
      } else if (error.status == 500) {
        return throwError('ERROR DE SISTEMA');
      }
      
    }


abrirModalAgregarUsuario(){
  this._ocultarModalAgregarUsuario = false;
}
cerrarModalAgregarUsuario(){
 this._ocultarModalAgregarUsuario = true;
}
abrirModalAgregarAdmin(){
  this._ocultarModalAgregarAdmin = false;
}
cerrarModalAgregarAdmin(){
 this._ocultarModalAgregarAdmin = true;
}
abrirModalPermisos(){
  this._ocultarModalPermisos = false;
}
cerrarModalPermisos(){
 this._ocultarModalPermisos = true;
}


  login(formData:Login){


    let params = new HttpParams({
      fromObject:{
        grant_type:'password',
        username:formData.username,
        password:formData.password
      }

    });
  //  /oauth/token
    return this.http.post(`${base_url}/oauth/token`, params, {
                        headers:{
                          "Authorization":this.basicToken,
                          // "Content-type": "application/x-www-form-urlencoded; charset=utf-8"
                        },                        
                    })
                    .pipe(
                      tap((res:any)=>{
                        
                        this.guardarLocalStorage(res.access_token, res.menu);
                     
                      }),
                      catchError(this.errorLogin)
                    )
  }


  errorLogin(error:HttpErrorResponse){
    if (error.error.error == "invalid_grant") {
      return throwError('El correo o contraseña son incorrectos');
    } else if (error.error.error == "unauthorized") {
      const errorMessage =   error.error.error_description
      return throwError(errorMessage);
    }
  }


  actualizarInfoUsuario(formData) {
    const url = `${base_url}/api_usuarios/info`;
    return this.http.patch(url, formData, this.headers);
  }


  actualizarPerfil(data:{email:string, nombre:string, role:string}){
    
    data = {
      ...data,
      role:this.usuario.role
    }

    return this.http.put(`${base_url}/users/${this.uid}`, data, {
        headers:{
          'x-token':this.token
        }
    })
  }

  obtenerEncargadoPorNombre(nombre){
    
    const url = `${base_url}/api_encargados/search?text=${nombre}`;
    
    return this.http.get(url, this.headers)
    .pipe(
      map((res:any)=>{
        const socio = res
            return socio;
        
      })
      
    )

  }

  obtenerUsuario(){
    return this.http.get<RefreshDatosInterface>(`${base_url}/api_usuarios/info`, {
        headers:{
          "Authorization": `Bearer ${this.token}`
        }
    })
  }

  validarToken():Observable<boolean> { 
    
    return this.http.get<RefreshDatosInterface>(`${base_url}/api_usuarios/info`, {
  
      headers:{
        "Authorization": `Bearer ${this.token}`
      }
  
    }).pipe(
      map((res:any)=>{

        return res.username != null || res.username != '' ? true : false;        
      
      }),
      catchError(()=>{
        this.logout();
        return of(false)
      })
    )
  }

  obtenerEncargado(id){
    
    const url = `${base_url}/api_encargados/${id}`;
    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{
        const socio = res.data
            
            return {
              socio
            };
        
      })
    )

  }

  obtenerAdministrador(id){
    
    const url = `${base_url}/api_administradores/${id}`;
    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{
        const socio = res.data
            
            return {
              socio
            };
        
      })
    )

  }

  modificarEncargado(id, formData){
    const url = `${base_url}/api_encargados/${id}`;
    
    return this.http.put(url, formData, this.headers);

  }
  modificarAdministrador(id, formData){
    const url = `${base_url}/api_administradores/${id}`;
    
    return this.http.put(url, formData, this.headers);

  }
  actualizarPermisosEncargados(id, formData){
    const url = `${base_url}/api_encargados/${id}`;
    
    return this.http.patch(url, {permisos:formData}, this.headers);

  }
  
  
  cambiarPassword(formData){
    const url = `${base_url}/api_usuarios/security/password/actualizar`;
      
    return this.http.patch(url, formData, this.headers);

  }
  
  obtenerUsuarioPorCorreo(email){
    const url = `${base_url}/api_usuarios/info/validar/correo?email=${email}`;
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{
        const data = res.data
                      
            return {
              data
            };
        
      })
    )
  }


  obtenerAdministradores(numeroDePagina:number ,itemsPorPagina:number){
    const url = `${base_url}/api_administradores/${numeroDePagina}/${itemsPorPagina}`;
    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const socios = res.data
        const infoPaginacion = res.pageInfo
            
            return {
              socios,
              infoPaginacion
            };
        
      })
    )

  }

  obtenerUsuarios(numeroDePagina:number ,itemsPorPagina:number){
    const url = `${base_url}/api_encargados/${numeroDePagina}/${itemsPorPagina}`;
    
    return this.http.get(url, this.headers).pipe(
      map((res:any)=>{

        const socios = res.data
        const infoPaginacion = res.pageInfo
            
            return {
              socios,
              infoPaginacion
            };
        
      })
    )

  }

  eliminarUsuario(uid:string){
    
    const url = `${base_url}/users/${uid}`;
    return this.http.delete(url, this.headers);
  }

  eliminarAdministrador(id:number){
    const url = `${base_url}/api_administradores/${id}`;
    return this.http.delete(url, this.headers).pipe(
      map((res:any)=>{
            return res;
      })
    )
  }

  eliminarEncargado(id:number){
    const url = `${base_url}/api_encargados/${id}`;
    return this.http.delete(url, this.headers).pipe(
      map((res:any)=>{
            return res;
      })
    )
  }

  guardarUsuario(usuario:Usuario){

    return this.http.put(`${base_url}/users/${usuario.uid}`, usuario, this.headers);

  }

}
