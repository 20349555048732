<div class="row animated fadeIn fast">
    <div *ngIf="!disabledFeature" class="col-12">
        <div class="card">
            <div class="card-body">
                <form 
                    #formBusqueda="ngForm"
                >

                    <div class="input-group">
                        <input 
                            type="text" 
                            [(ngModel)]="terminoBusqueda"
                            name="terminoBusqueda"
                            class="form-control" 
                            placeholder="Escribe descripcion para buscar..."
                            autocomplete="off"
                        >
                        <span class="input-group-btn">
                            <button 
                                class="btn btn-info" 
                                type="submit"
                                (click)="buscar(formBusqueda.value)"
                            >
                                Buscar
                            </button>
                        </span>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="cargando"
>
    <div class="col-12">
        

        <div class="alert alert-info text-center" role="alert">
            <h4>Cargando</h4>
            <i class="fa fa-spin fa-refresh fa-2x"></i>
            <p class="mb-0"></p>
        </div>
       
    </div>
</div>


<div class="row animated fadeIn fast" *ngIf="!cargando"
>
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-10">
                        
                        <h4 class="card-title">Corte de caja de {{labelProductos}}</h4>
                        <h6 class="card-subtitle">Tickets: {{totalItems}}</h6>
                    </div>

                    <div class="col-md-12 text-left">
                        desde
                        &nbsp;    
                        <input [(ngModel)]="rangoFechas.fechaInicio" (change)="dateStart($event.target.value)" class="input-pick-date" type="date">
                        &nbsp;
                        hasta
                        &nbsp;
                        <input [(ngModel)]="rangoFechas.fechaFin" (change)="dateEnd($event.target.value)" class="input-pick-date" type="date">
                        &nbsp;
                        &nbsp;
                        <button type="button" (click)="downloadPDF()" class="m-l-10 btn btn-outline-success"><i class="fa fa-download"></i> PDF</button>
                    </div>
                    

                </div>
                <div class="table-responsive top-10 m-t-30">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Folio</th>
                                <th>Membresía</th>
                                <th>Socio</th>
                                <th class="text-center">Periodo</th>
                                <th class="text-center">Inscripción</th>
                                <th class="text-center">Precio</th>
                                <th class="text-center">Cantidad</th>
                                <th class="text-center">Descuento</th>
                                <th class="text-center">Subtotal</th>

                                <th class="text-nowrap w100">Acciones</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="membresiasData.length>0">
                           <tr *ngFor="let membresia of membresiasData">

                            <td class="">{{membresia.folio}}</td>
                            <td class="">{{membresia.nombreMembrecia}}</td>
                            <td
                                routerLink="/dashboard/socio/{{membresia.idSocio}}"
                                class="cursorpointer table-info"
                            >
                              {{membresia.nombreSocio}}
                            </td>
                            <td class="text-center">{{membresia.periodo}}</td>
                            <td class="text-right">{{membresia.precioInscripcion | currency:'USD':'symbol':'1.2-2'}}</td>
                            <td class="text-right">{{membresia.precioUnitario | currency:'USD':'symbol':'1.2-2'}}</td>
                            <td class="text-center">{{membresia.cantidad}}</td>
                            <td class="text-right">{{membresia.descuento | currency:'USD':'symbol':'1.2-2'}}</td>
                            <td class="text-right">{{ membresia.total | currency:'USD':'symbol':'1.2-2'}}</td>
                            <td class="text-nowrap text-center">
                                <button (click)="reimprimirTicket(membresia)" type="button" class="btn btn-info btn-circle"><i class="fa fa-file-pdf-o"></i> </button>
                                <button (click)="eliminarMembresia(membresia)" type="button" class="btn btn-danger btn-circle m-l-10"><i class="fa fa-trash"></i> </button>
                            </td>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th class="table-info text-right"> {{totalPorPagina | currency:'USD':'symbol':'1.2-2'}}</th>
                            </tr>
                        </tbody>

                        <tbody *ngIf="membresiasData.length<=0">
                            <tr>
                                <td colspan="8">
                            <div class="p-20 text-center">
                                No hay resultados de la busqueda
                            </div>

                            </td>

                            </tr>
                        </tbody>

                    </table>
                </div>

                <div class="clearfix"></div>
                <div class="top-20 col-lg-12 col-md-12">                          
                                
                    <mi-paginador
                        *ngIf="!buscandoPorNombre" 
                        (cargaData)="seleccionaItemsPorPagina($event)"
                        (numeroDePaginaEmit)="seleccionaPagina($event)"
                        [totalPaginasArray]="totalPaginasArray || 0"    
                        [itemsPorPaginaInput]="itemsPorPagina"    
                        [numeroDePaginaInput]="numeroDePagina || 0"    
                        [desdeInput]="desde || 0"    
                        [hastaInput]="hasta || 5"    
                        >
                    </mi-paginador>
                
                </div>
                <div class="clearfix"></div>
                <div class="card">
                    <h4 class="card-title">Resumen de membresías vendidas</h4>
                    <div class="table-responsive">
                        <table class="table full-color-table full-success-table">
                            <thead>
                                <tr>
                                    <th>Periodo</th>
                                    <th>Cantidad</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="this.resumenData.dia">
                                    <td>DIA</td>
                                    <td>{{ resumenData.dia.cantidad }}</td>
                                    <td>{{ resumenData.dia.total | currency:'USD':'symbol':'1.2-2'  }}</td>
                                </tr>
                                <tr *ngIf="this.resumenData.semana">
                                    <td>SEMANA</td>
                                    <td>{{ resumenData.semana.cantidad }}</td>
                                    <td>{{ resumenData.semana.total | currency:'USD':'symbol':'1.2-2'  }}</td>
                                </tr>
                                <tr *ngIf="this.resumenData.quincena">
                                    <td>QUINCENA</td>
                                    <td>{{ resumenData.quincena.cantidad }}</td>
                                    <td>{{ resumenData.quincena.total | currency:'USD':'symbol':'1.2-2'  }}</td>
                                </tr>
                                <tr *ngIf="this.resumenData.mes">
                                    <td>MES</td>
                                    <td>{{ resumenData.mes.cantidad }}</td>
                                    <td>{{ resumenData.mes.total | currency:'USD':'symbol':'1.2-2'  }}</td>
                                </tr>
                                <tr *ngIf="this.resumenData.year">
                                    <td>AÑO</td>
                                    <td>{{ resumenData.year.cantidad }}</td>
                                    <td>{{ resumenData.year.total | currency:'USD':'symbol':'1.2-2'  }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-0 col-xs-0 top-30"></div>
                    <div class="col-lg-3 col-md-3 col-sm-0 col-xs-0 top-30"></div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 top-30">
                        <div class="card bg-info text-white">
                            <div class="card-body">
                                <div class="d-flex">

                                    <div class="stats text-right">
                                        <h1 class="text-white">Total: {{ totalGeneral | currency:'USD':'symbol':'1.2-2'}} </h1>
                                        <h6 class="text-white">Descuentos realizados: <strong> {{totalDescuento | currency:'USD':'symbol':'1.2-2'}}</strong></h6>
                                        <h6 class="text-white">Entre: <strong>{{rangoFechas.fechaInicio | date: 'dd/MM/yyyy'}}</strong> y <strong>{{rangoFechas.fechaFin | date: 'dd/MM/yyyy'}}</strong></h6>
                                    </div>
                                    <div class="stats-icon text-left ml-auto"><i class="fa fa-dollar display-5 op-3 text-dark"></i></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

