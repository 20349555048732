import { Component, OnInit } from '@angular/core';
import { Socio } from 'src/app/models/socio.model';
import { MembreciasService } from 'src/app/services/membrecias.service';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import { UtilsService } from 'src/app/services/utils.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-corte-membresias',
  templateUrl: './corte-membresias.component.html',
  styleUrls: ['./corte-membresias.component.css']
})
export class CorteMembresiasComponent implements OnInit {
  public membresiasData:any[]=[];
  public disabledFeature:boolean=true;
  public cargando:boolean=false;
  public buscandoPorNombre:boolean=false;
  public fechaInicio:Date = null;
  public fechaFin:Date = null;
  public totalGeneral:number=0;
  public totalDescuento:number=0;
  public totalPorPagina:number=0;
  public desde:number=0;
  public hasta:number=5;
  public infoPaginacion:any;
  public totalItems:number=0;
  public numeroDePagina:number=0;
  public itemsPorPagina:number=10;
  public totalPaginasInt:number=1;
  public totalPaginasArray:number[]=[];
  public idProductoInput:Object=null;
  public productoInput:Object=null;
  public terminoBusqueda:string=''
  public labelProductos:string= 'membresías'
  public socio:Socio=null;
  public filtroPorVencer:boolean=false;
  public filtroSinRenovar:boolean=false;
  public rangoFechas:any = {}
  public resumenData:any = {}
          

  constructor(
    private membresiasService:MembreciasService,
    private mensajesSwalService:MensajesSwalService,
    public utilsService:UtilsService
    
  ) { }

  ngOnInit(): void {
    const fechaActual = this.utilsService.obtenerFechaActual();
    this.rangoFechas.fechaInicio = fechaActual
    this.rangoFechas.fechaFin = fechaActual
    this.cargarMembresias()
  }



downloadPDF(){
  this.membresiasService.descargarCorteMembresias(this.rangoFechas)
    .subscribe((res)=>{
     this.abrirPDFOtraVentana(res);
    })
}

abrirPDFOtraVentana(res) {
  const blob = new Blob([res.body], { type: 'application/pdf' }),
    url = window.URL.createObjectURL(blob);
    window.open(url);
}

dateStart(e){
  this.rangoFechas.fechaInicio = e;
  this.cargarMembresias();
}
dateEnd(e){
  this.rangoFechas.fechaFin = e;
  this.cargarMembresias();
}

  buscar(busqueda):void{
        console.log(busqueda);
        // TODO::
  }






  cargarMembresias(){
    
    this.idProductoInput=null;
    this.buscandoPorNombre=false
    this.cargando=true;

  try {
    this.membresiasService.obtenerVentaMembresias(this.numeroDePagina, this.itemsPorPagina, this.rangoFechas)
    .subscribe(({ventaMembresias, infoPaginacion, res})=>{

      this.membresiasData = ventaMembresias;
      this.totalPorPagina = 0;

      this.totalGeneral = res.extraInfo.total;
      this.totalDescuento = res.extraInfo.totalDescuento;
      this.resumenData.dia = res.extraInfo.resumenVenta.DAY;
      this.resumenData.semana = res.extraInfo.resumenVenta.WEEK;
      this.resumenData.quincena = res.extraInfo.resumenVenta.FORTNIGHT;
      this.resumenData.mes = res.extraInfo.resumenVenta.MONTH;
      this.resumenData.year = res.extraInfo.resumenVenta.YEAR;
      

      for (const membresia of this.membresiasData) {
        const subtotal = membresia.precioInscripcion + (membresia.cantidad * membresia.precioUnitario) - membresia.descuento;
        this.totalPorPagina = this.totalPorPagina + subtotal;
      }
      
      this.infoPaginacion = infoPaginacion
      this.totalPaginasInt = infoPaginacion.totalPages
      this.totalPaginasArray=[]
      this.totalItems= infoPaginacion.totalElements

      
      for (let i = 0; i < this.totalPaginasInt; i++) {
          this.totalPaginasArray.push(i);
        }
      
      this.cargando=false;

    })
    
  } catch (error) {
    this.cargando=false;
  }


  }

  calcularTotal(){
    this.membresiasData.reduce(function(acumulador, objeto) {
      var resultado = (objeto.cantidad * objeto.precioUnitario) - objeto.descuento;
      return acumulador + resultado;
  }, 0);

  }

  reimprimirTicket(membresia){
    console.log(membresia);

    this.membresiasService.obtenerticket(membresia.folio).subscribe(res=>{
      this.abrirPDFOtraVentana(res);

    }, (error) => {
     console.log(error);
    })

  }


  eliminarMembresia(membresia){

    Swal.fire({
      title: '¿Eliminar venta de membresía?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#E5B700',
      cancelButtonColor: '#717171',
      cancelButtonText:'No',
      confirmButtonText: 'Si'
    }).then((result) => {

        if (result.isConfirmed) {

          

          this.membresiasService.eliminarVentaMembresia(membresia.id).subscribe(res=>{
      
            this.mensajesSwalService.eliminadoConExito();
            this.cargarMembresias();
          })

        }        
     })

  }

 
  seleccionaItemsPorPagina(e){

    this.itemsPorPagina = e;
    this.cargarMembresias();
    
  }

  seleccionaPagina(data){

    this.numeroDePagina =  data.paginaSeleccionada;
    this.desde =  data.desde;
    this.hasta =  data.hasta;
    this.cargarMembresias();
  }


  filtroSocios(e){

    switch (e) {
      case 'todos':
        this.filtroPorVencer=false;
        this.filtroSinRenovar=false;
        break;
      case 'vencidas':

        this.filtroPorVencer=false;
        this.filtroSinRenovar=false;
        
        break;
      case 'por-vencer':

        this.filtroPorVencer=true;
        this.filtroSinRenovar=false;
        
        break;
      case 'sin-renovar':
        
        this.filtroPorVencer=false;
        this.filtroSinRenovar=true;

        break;
    
      default:
        break;
    }

    
  }

  filtroCorte(e) {
    // TODO::
    console.log(e);
    
  }

}
