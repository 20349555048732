import {
  Component,
  OnInit
} from '@angular/core';
import { SettingsService } from '../../services/settings.service';
import { MensajesSwalService } from 'src/app/services/mensajes-swal.service';
import Swal from 'sweetalert2';
import { GymService } from 'src/app/services/gym.service';
import { ActivatedRoute } from '@angular/router';



  @Component({
    selector: 'app-account-settings',
    templateUrl: './account-settings.component.html',
    styles: [
    ]
})
export class AccountSettingsComponent implements OnInit {

  id!: string;
  orderData!: any;

  public links:NodeListOf<Element>;
  public diasRestantes:number = 0;
  public nombre: string = '';
  public telefono: string = '';
  public correo: string = '';
  public direccion: string = '';
  public frase: string = 'Quiero modificar los datos de mi centro deportivo';
  public isDisabledFormDatos: boolean = true;
  public editMode: boolean = false;
  public loadingDatos: boolean = false;
  public alertEmailEnabled: boolean = false;
  public alertInputRequiredEnabled: boolean = false;
  public keyImg:string = '';
  public urlImg:string = '';


  constructor(
    private settingService:SettingsService,
    public mensajesSwalService:MensajesSwalService,
    public gymService:GymService,
    private activatedRoute:ActivatedRoute,
  ) {
  }

  ngOnInit(): void {

    this.links=document.querySelectorAll('.selector');
    this.settingService.checkCurrentTheme(this.links);
    this.obtenerInfoGym();
    this.obtenerParametrosURL();
  }

  obtenerParametrosURL(){
    this.activatedRoute.queryParams.subscribe(params =>{
      const existPaymentIntent = localStorage.getItem('paymentIntent');
      const paymentIntent = params['payment_intent'];
      const redirectStatus = params['redirect_status'];

      if (redirectStatus == 'succeeded' && !existPaymentIntent) {
        const suscripcion = localStorage.getItem('suscripcion');
        const body = {
          type: suscripcion
        }
        this.compruebaIntentoDePago(body);
      }

    })
  }

  compruebaIntentoDePago(body){
    this.mensajesSwalService.loading();
    this.gymService.compruebaIntencionDePago(body).subscribe((res:any)=> {

      if (res.data) {
        this.gymService.cerrarAlert();
        this.mensajesSwalService.suscripcionCreadaExitosamente();
        localStorage.removeItem('suscripcion');
        localStorage.setItem('paymentIntent', 'true');
      } else {
        this.mensajesSwalService.validandoPago();
        this.gymService.cerrarAlert();
      }
      this.obtenerInfoGym();
    })
  }
  goPay(){
    this.gymService.abrirModalPayments();
  }

  recibirFoto(foto){
      this.keyImg = foto;
  }

  recibirStatusLoading(status){
    this.isDisabledFormDatos = status;

    if (!this.isDisabledFormDatos) {
      this.validaFormDatosGym();
    }
  }

  changeTheme(tema:string){
    this.settingService.changeTheme(tema, this.links);
  }

  async cambiarDatos(){
    try {

      await Swal.fire({
        title:'Escribe la siguiente frase para confirmar la acción:',
        html:`<p class="text-danger">${this.frase}<p>`,
        input: 'text',
        inputAutoTrim:true,
        inputValue: '',
        inputPlaceholder: 'Escribe la frase de arriba ...',
        inputValidator: (value) => {
          if (!value) {
            return `Es necesario que escribas la frase de arriba`
          }
          if (value != this.frase) {
            return 'La frase no coincide, comprueba mayusculas y minusculas o prueba copiando (Ctrl + c) y pegando (Ctrl + v) la frase de arriba'
          } else {
            this.confirmarCambioDeDatos();
          }
      }
      })
    } catch (error) {
      console.error(error);
    }
  }

  confirmarCambioDeDatos(){

    const dataForm = {
      nombre: this.nombre,
      telefono : this.telefono,
      correo : this.correo,
      direccion : this.direccion,
      urlLogotipo : this.keyImg
    }

    this.loadingDatos = true;
    this.gymService.actualizarInfoGym(dataForm).subscribe(() => {
      this.obtenerInfoGym();
      this.loadingDatos = false;
      this.editMode = false;
      this.mensajesSwalService.modificadosConExito('Datos', 'o');
    })

  }

  obtenerInfoGym(){
    this.gymService.obtenerInfoGym().subscribe(res => {
      this.nombre = res.res.nombre;
      this.telefono = res.res.telefono;
      this.correo = res.res.correo;
      this.direccion = res.res.direccion;
      this.urlImg = res.res.urlLogotipo;
      this.diasRestantes = res.res.diasRestantes;
      this.validaFormDatosGym();
    })
  }

  validaFormDatosGym(){
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!this.nombre || !this.telefono || !this.correo) {
      this.isDisabledFormDatos = true;
      this.alertInputRequiredEnabled = true;
    } else {
      this.alertInputRequiredEnabled = false;
      const esCorreoValido = regexEmail.test(this.correo);
      if (esCorreoValido) {
        this.alertEmailEnabled = false;
        this.isDisabledFormDatos = false;

      } else {
        this.alertEmailEnabled = true;
        this.isDisabledFormDatos = true;
      }
    }
  }

  cancelEditMode(){
    this.editMode = false;
    this.obtenerInfoGym();
  }

}
