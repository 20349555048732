import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';

@Component({
  selector: 'modal-ver-permisos',
  templateUrl: './modal-ver-permisos.component.html',
  styleUrls: ['./modal-ver-permisos.component.css']
})


export class ModalVerPermisosComponent implements OnInit, OnChanges {

  @Input() usuarioInput:any
  public permisos:any = [
    {nombre:'CORTE', estatus: true},
    {nombre:'VENTAS', estatus: true},
    {nombre:'SOCIOS', estatus: true},
    {nombre:'PRODUCTOS', estatus: true},
    {nombre:'MEMBRESÍAS', estatus: true},
    {nombre:'USUARIOS', estatus: false}
  ]
  public nombre:string = '';
 
  constructor(
    public usuariosService:UsuarioService,

  ) { }

  ngOnChanges(): void {

    if (!this.usuarioInput ) {
        return;
    }else {
      
      this.nombre = this.usuarioInput.nombres  +' '+ this.usuarioInput.apellidoPaterno +' '+this.usuarioInput.apellidoMaterno;
      this.obtenerUsuario();
    }
  }
  
  ngOnInit(): void {    
  }

  cerrarModal(){
    this.usuariosService.cerrarModalPermisos();
  }
  obtenerUsuario(){

    if (this.usuarioInput) {

        this.usuariosService.obtenerEncargado(this.usuarioInput.id).subscribe((res)=>{
        this.permisos = res.socio.permisos;
       
      })
    }

  }



}
